/* eslint-disable prefer-const */
import React, { useState, useContext, createRef, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import TimeAgo from 'react-time-ago';
import * as markerjs2 from 'markerjs2';

import Modal from 'components/Common/Modal/Modal';
import TextAreaInput from 'components/Dashboard/FormComponents/TextAreaInput';
import { FrameUIActionsTypes, useFrameUI } from 'providers/FrameUIProvider';
import { UserContext } from 'providers/UserProvider';
import useMessages from 'hooks/useMessages';

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import AtelierModel3DViewer from 'components/VirtualAtelier/Uploads/AtelierModelUploads/AtelierModel3DViewer';
import AtelierModel3DAnnotation from '../Uploads/AtelierModelUploads/AtelierModel3DAnnotation';
import 'components/VirtualAtelier/StyleSummary/ModelAnnotationModal.scss';
import PopUp from 'components/Common/PopUp/PopUp';

import './StyleSampleReviewHeader.scss';
import './AssetAnnotationModal.scss';
import CloseIcon from 'assets/icons/close.svg';
import CompareIcon from 'assets/icons/icon-compare.svg';
import PinIcon from 'assets/icons/pin.svg';
import MarkupIcon from 'assets/icons/annotation_icon.svg';
import PrevIcon from 'assets/icons/arrow-prev.svg';
import ZoomOutIcon from 'assets/icons/zoom-out.svg';
import ZoomInIcon from 'assets/icons/zoom-in.svg';
import HandIcon from 'assets/icons/hand-icon.svg';
import EllipsisHIcon from 'assets/icons/ellipsis-h-grey.svg';

export type AssetAnnotationModal = {
  heading: string;
  open: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  imgSrc: string;
  reference: any;
  onDropHandler?: any;
  currentPath?: any;
  slideIndex: number;
  slides: any;
  referenceMediaIndex?: any;
  referenceMedia?: any;
  setReferenceMediaIndex?: any;
  imageUrlZoomed?: { src: string; width: number; height: number };
  style?: any;
};

const AssetAnnotationModal: React.FC<AssetAnnotationModal> = props => {
  const {
    heading,
    open,
    setOpen,
    imgSrc,
    reference,
    onDropHandler,
    currentPath,
    slideIndex,
    slides,
    referenceMediaIndex,
    referenceMedia,
    setReferenceMediaIndex,
    imageUrlZoomed,
    style,
  } = props;

  const { user } = useContext(UserContext);
  const { idToken } = user;
  const { postMessage } = useMessages(idToken, {
    model: reference,
    open: false,
    messageFetchInterval: 600,
  });
  const [loading, setLoading] = useState(false);
  const [markerOpen, setMakerOpen] = useState(false);
  const [sideBySideOpen, setSideBySideOpen] = useState(false);
  const [markerFull, setMarkerFull] = useState(false);
  const [markerSideBySide, setMarkerSideBySide] = useState(false);
  const [imgSrcAnnotated, setImgSrcAnnotated] = useState(null);
  const imgRef = createRef<HTMLImageElement>();

  const [currentImage, setCurrentImage] = useState(referenceMediaIndex);
  const [currentSampleImage, setCurrentSampleImage] = useState(slideIndex);
  const lightboxRef = useRef<HTMLDivElement>();

  const [annots, setAnnots] = useState([]);
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [annotationText, setAnnotationText] = useState(null);

  const [commentsToggle, setCommentsToggle] = useState(false);

  const [markUpSummaryModalOpen,setMarkUpSummaryModalOpen] = useState(false)

  const popUpOptions = [
    {
      selectItem: 'Delete',
      onClick: key => {
        setAnnots(annots => annots.filter(annot => annot.key !== key));
      },
    },
  ];

  const { dispatch } = useFrameUI();
  const { handleSubmit, register, reset } = useForm({});

  const closeAnnotationEventAction = () => {
    setMarkUpSummaryModalOpen(false);
    setMarkerFull(false);
    setMarkerSideBySide(false);
    setMakerOpen(false);
  };

  const renderAnnotationEventAction = () => {
    setMarkUpSummaryModalOpen(true)
    setMarkerFull(false);
    setMarkerSideBySide(false);
    setMakerOpen(false);
  };

  const showMarkerArea = e => {
    setMarkerFull(true);
    // create a marker.js MarkerArea
    const markerArea = new markerjs2.MarkerArea(imgRef.current);
    markerArea.settings.displayMode = 'popup';
    markerArea.availableMarkerTypes = markerArea.BASIC_MARKER_TYPES;
    markerArea.renderAtNaturalSize = false;
    markerArea.renderHeight = imgRef.current.height;
    markerArea.renderWidth = imgRef.current.width;
    markerArea.addRenderEventListener(renderAnnotationEventAction)
    markerArea.addCloseEventListener(closeAnnotationEventAction);
    // attach an event handler to assign annotated image back to our image element
    markerArea.addRenderEventListener((dataUrl, state) => {
      markerArea.removeCloseEventListener(closeAnnotationEventAction);
      setImgSrcAnnotated(dataUrl);
      setMakerOpen(false);
      markerArea.close();
    });

    document.getElementById('close-icon').addEventListener('click', () => {
      setMarkerFull(false);
      markerArea.close();
    });

    document.getElementById('side-by-side-icon').addEventListener('click', () => {
      setMarkerFull(false);
      markerArea.close();
    });

    document.getElementById('full-marker-icon').addEventListener('click', () => {
      setMarkerFull(false);
      markerArea.close();
    });

    // launch marker.js
    markerjs2.Activator.addKey(process.env.REACT_APP_markerjs_license_key);
    markerArea.show();
  };

  function dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(','),
      // eslint-disable-next-line prefer-destructuring
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const onPostMessage = values => {
    console.log('values', values);
    console.log('imgSrc', imgSrc);
    console.log('reference', slides?.[currentSampleImage]);
    console.log('currentPath', currentPath);

    const cloneExtensionIndex = slides?.[currentSampleImage]?.name.lastIndexOf('.');
    const cloneName = slides?.[currentSampleImage]?.name.slice(0, cloneExtensionIndex);
    const cloneExtension = slides?.[currentSampleImage]?.name.slice(cloneExtensionIndex + 1);

    const d = new Date();
    const cloneDatetime =
      d.getUTCFullYear() +
      '-' +
      ('0' + (d.getUTCMonth() + 1)).slice(-2) +
      '-' +
      ('0' + d.getUTCDate()).slice(-2);

    const referenceAnnotationName = `${cloneName}-annotation-${cloneDatetime}.${cloneExtension}`;

    const fileArr = [];
    const file = dataURLtoFile(
      imgSrcAnnotated,
      referenceAnnotationName || 'AnnotatedImage'
    );
    if (loading) {
      return;
    }
    setLoading(true);
    values['file'] = file;
    values['fileMetadata'] = {
      type: 'image',
      contentType: 'image/png',
      name: referenceAnnotationName,
    };
    dispatch({
      type: FrameUIActionsTypes.CHATBAR_LOADING,
      payload: true,
    });
    fileArr.push(file);
    onDropHandler(fileArr, currentPath, slideIndex);
    reset();
    setOpen(false);
    setLoading(false);
  };

  return (
    <div className='asset-annotation-modal'>
      <CSSTransition classNames='lightbox' timeout={200} in={true} unmountOnExit>
        <div className='product-listing-dashboard__light-box'>
          <div className='product-listing-dashboard__lightbox-header'>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '50%'}}>
              <span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '80%'}}>
                {`${slides?.[currentSampleImage]?.name}`}
              </span>
              <div
                className='product-listing-dashboard__lightbox-arrows'
                style={{ justifyContent:'flex-end', visibility: !sideBySideOpen && 'hidden' }}
              >
                <img
                  className='product-listing-dashboard__main-prev'
                  src={PrevIcon}
                  alt='Previous'
                  onClick={() =>
                    setCurrentImage(
                      currentImage === 0 ? referenceMedia.length - 1 : currentImage - 1
                    )
                  }
                />
                <img
                  className='product-listing-dashboard__main-next'
                  src={PrevIcon}
                  alt='Next'
                  onClick={() =>
                    setCurrentImage(
                      currentImage === referenceMedia.length - 1 ? 0 : currentImage + 1
                    )
                  }
                />
              </div>
            </div>
            <div className='product-listing-dashboard__lightbox-buttons-wrap'>
              {/* <div className='button-dashboard  btn-fs-large '>
                <img
                  className='style-sample-review-header__img-icon'
                  src={HandIcon}
                  alt='Plus'
                />
                <div className='tooltip tooltip--black'>
                  <span className='tooltip__txt'>Hand Icon</span>
                </div>
              </div>
              <div className='button-dashboard  btn-fs-large '>
                <img
                  className='style-sample-review-header__img-icon'
                  src={ZoomInIcon}
                  alt='Plus'
                />
                <div className='tooltip tooltip--black'>
                  <span className='tooltip__txt'>Zoom In</span>
                </div>
              </div>
              <div className='button-dashboard  btn-fs-large '>
                <img
                  className='style-sample-review-header__img-icon'
                  src={ZoomOutIcon}
                  alt='Plus'
                />
                <div className='tooltip tooltip--black'>
                  <span className='tooltip__txt'>Zoom out</span>
                </div>
              </div> */}
              <div
                className='button-dashboard  btn-fs-large'
                id="full-marker-icon"
                onClick={e => {
                  setSideBySideOpen(false);
                }}
              >
                <img
                  className='style-sample-review-header__img-icon'
                  src={MarkupIcon}
                  alt='Plus'
                  onClick={(e) => {
                    if (!['gltf', 'glb', 'model'].includes(slides?.[currentSampleImage]?.type)) {
                      !markerFull && showMarkerArea(e);
                    }
                  }}
                />
                <div className='tooltip tooltip--black'>
                  <span className='tooltip__txt'>Markup</span>
                </div>
              </div>
              <div className='product-listing-dashboard__lightbox-arrows'>
                <div
                  className='button-dashboard btn-fs-large'
                  id="side-by-side-icon"
                  onClick={(e) => {
                    setSideBySideOpen(!sideBySideOpen);
                    setMakerOpen(false);
                  }}
                >
                  <img
                    className='style-sample-review-header__img-icon'
                    src={CompareIcon}
                  />
                  <div className='tooltip tooltip--black'>
                    <span className='tooltip__txt'>Side / Side </span>
                  </div>
                </div>
                <div
                  className='button-dashboard btn-fs-large'
                  onClick={(e) => {
                    setCommentsToggle(!commentsToggle);
                  }}
                >
                  <img
                    className='style-sample-review-header__img-icon'
                    src={PinIcon}
                  />
                  <div className='tooltip tooltip--black'>
                    <span className='tooltip__txt'>Annotate</span>
                  </div>
                </div>
                <div className='button-dashboard btn-fs-large '>
                  <img
                    className='style-sample-review-header__img-icon product-listing-dashboard__main-prev'
                    src={PrevIcon}
                    alt='Previous'
                    onClick={() => {
                      setCurrentSampleImage(
                        currentSampleImage === 0 ? slides.length - 1 : currentSampleImage - 1
                      )
                    }}
                  />
                  <img
                    className=' style-sample-review-header__img-icon product-listing-dashboard__main-next'
                    src={PrevIcon}
                    alt='Next'
                    onClick={() => {
                      setCurrentSampleImage(
                        currentSampleImage === slides.length - 1 ? 0 : currentSampleImage + 1
                      )
                    }}
                  />
                </div>
              </div>
              <img
                className='cursor-pointer'
                id='close-icon'
                src={CloseIcon}
                alt='Close'
                onClick={() => {
                  setOpen(false);
                }}
              />
            </div>
          </div>
          <div
            className='product-listing-dashboard__lightbox-inner annotation-3d-modal'
            style={{ overflow: 'hidden', width: '100%', display: 'flex', justifyContent: 'center' }}>

            {!markerOpen && (
              <TransitionGroup component={null}>
                <CSSTransition classNames='fade-in' timeout={200}>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', width: '100%'}}>
                    {sideBySideOpen && (<div style={{ width: '50%' }}>
                      {!['gltf', 'glb', 'model'].includes(referenceMedia?.[currentImage]?.type)
                        ? (<div className='product-listing-dashboard__lightbox-inner' ref={lightboxRef} style={{ overflowY: 'unset', padding: 0 }}>
                            <TransitionGroup component={null}>
                              <CSSTransition classNames='fade-in' timeout={200} key={style?.name}>
                                <TransformWrapper
                                  centerOnInit={true}
                                  minScale={0.2}
                                  limitToBounds={false}
                                  wheel={['gltf', 'glb', 'model'].includes(referenceMedia?.[currentImage]?.type) ? { disabled: true } : {
                                    step: 0.1,
                                  }}
                                  pinch={['gltf', 'glb', 'model'].includes(referenceMedia?.[currentImage]?.type) && { disabled: true}}
                                  panning={['gltf', 'glb', 'model'].includes(referenceMedia?.[currentImage]?.type) && { disabled: true}}
                                  doubleClick={{
                                    mode: 'reset',
                                  }}
                                  initialPositionX={0}
                                  initialPositionY={0}
                                >
                                  <TransformComponent>
                                    <img
                                      src={referenceMedia?.[currentImage]?.assetUrl || imageUrlZoomed?.src}
                                      alt={style.name}
                                      style={{ height: '600px' }}
                                    />
                                  </TransformComponent>
                                </TransformWrapper>
                              </CSSTransition>
                            </TransitionGroup>
                            </div>)
                        : (<div className='product-listing-dashboard__tile' style={{ position: 'inherit' }}>
                            <div className='atelier-model-3d-annotation-wrapper'>
                              <div className='tile__player' style={{ position: 'unset', height: '1000px', width: '1000px', }}>
                                <AtelierModel3DViewer url={referenceMedia?.[currentImage]?.assetUrl} name={referenceMedia?.[currentImage]?.name} />
                              </div>
                            </div>
                          </div>)}
                    </div>)}
                    <div style={{ width: sideBySideOpen ? '50%' : '100%' }}>
                      {!['gltf', 'glb', 'model'].includes(slides?.[currentSampleImage]?.type)
                        ? (<div className='product-listing-dashboard__lightbox-inner' ref={lightboxRef} style={{ overflowY: 'unset', padding: 0 }}>
                            <TransitionGroup component={null}>
                              <CSSTransition classNames='fade-in' timeout={200} key={style?.name}>
                                <TransformWrapper
                                  centerOnInit={true}
                                  minScale={0.2}
                                  limitToBounds={false}
                                  wheel={['gltf', 'glb', 'model'].includes(slides?.[currentSampleImage]?.type) ? { disabled: true } : {
                                    step: 0.1,
                                  }}
                                  pinch={['gltf', 'glb', 'model'].includes(slides?.[currentSampleImage]?.type) && { disabled: true}}
                                  panning={['gltf', 'glb', 'model'].includes(slides?.[currentSampleImage]?.type) && { disabled: true}}
                                  doubleClick={{
                                    mode: 'reset',
                                  }}
                                  initialPositionX={0}
                                  initialPositionY={0}
                                >
                                  <TransformComponent>
                                    <img
                                      crossOrigin='anonymous'
                                      ref={imgRef}
                                      src={slides?.[currentSampleImage]?.assetUrl}
                                      alt={style.name}
                                      style={{ width: 'auto', margin: '0 auto', height: '600px', visibility: markerSideBySide && 'hidden' }}
                                    />
                                  </TransformComponent>
                                </TransformWrapper>
                              </CSSTransition>
                            </TransitionGroup>
                            </div>)
                        : (<div className='product-listing-dashboard__tile'>
                            <div className='atelier-model-3d-annotation-sbs-wrapper'>
                              <AtelierModel3DAnnotation
                                url={slides?.[currentSampleImage]?.assetUrl}
                                annots={annots}
                                setAnnots={setAnnots}
                              />
                            </div>
                          </div>)}
                    </div>
                  </div>
                </CSSTransition>
              </TransitionGroup>
            )}

            {commentsToggle && (
              <div className='annotation-comment-section'>
                {annots?.length === 0 ? (
                  <div className='annotation-empty'>
                    <div>
                      <span>Click on the + icon to add annotations.</span>
                    </div>
                  </div>
                ) : (
                  <>
                    <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                      <span
                        style={{
                          display: 'block',
                          color: '#666665',
                          margin: '0 0 14px 16px',
                        }}
                      >
                        Annotations
                      </span>
                    </div>
                    {annots?.map((annot, index) => {
                      return (
                        <Annotation
                          key={annot.key}
                          annotation={annot}
                          selectedItem={selectedItem}
                          setSelectedItem={setSelectedItem}
                          setPopupOpen={setPopupOpen}
                          popupOpen={popupOpen}
                          popUpOptions={popUpOptions}
                          user={user}
                          handleSubmit={handleSubmit}
                          register={register}
                          reset={reset}
                          reference={slides?.[currentSampleImage]}
                          loading={loading}
                          annotationText={annotationText}
                          setAnnots={setAnnots}
                        />
                      );
                    })}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </CSSTransition>
      <div className='markup-summary-modal-wrapper'>
        <Modal
          modalHeading={'Markup Summary'}
          modalBtnClose={'cancel'}
          modalBtnActionLabel={'Save'}
          modalBtnActionHandler={handleSubmit(onPostMessage)}
          backgroundColor='#fff'
          modalOpen={markUpSummaryModalOpen}
          setModalOpen={setMarkUpSummaryModalOpen}
          closeOnClickAway={false}
        >
          <img
            src={imgSrcAnnotated || imgSrc}
            ref={imgRef}
            style={{ width: '100%' }}
          />
          {/* <div className='collection-access-modal__label'>
            <form onSubmit={handleSubmit(onPostMessage)}>
              <TextAreaInput
                name={'message'}
                required={false}
                register={register}
                placeholder='Optional, a summary of markup'
                update={true}
                fullWidth={true}
                onKeyDownHandler={handleSubmit(onPostMessage)}
                disabled={loading}
              />
            </form>
          </div> */}
        </Modal>
      </div>
    </div>
  );
};

const Annotation = props => {
  const {
    annotation,
    selectedItem,
    setSelectedItem,
    setPopupOpen,
    popupOpen,
    popUpOptions,
    user,
    handleSubmit,
    register,
    reset,
    reference,
    loading,
    annotationText,
    setAnnots,
  } = props;

  const [openAnnotationComment, setOpenAnnotationComment] = useState(false);
  const [viewReplies, setViewReplies] = useState(false);
  const [selectedMessageThread, setSelectedMessageThread] = useState(null)

  const onPostMessage = values => {
    if (values.message.split('').filter(m => m !== ' ').length > 0) {
      const newComment = {};
      newComment.message = values.message;
      newComment.createdByUser = user.account.name;
      newComment.createdByAvatar = user.account.assets.profile_picture.url;
      newComment.createdByTime = Date.now();
      newComment.id = Date.now();
      newComment.replies = [];
      annotation.thread.push(newComment);
      reset();
    }
  };

  const onPostReply = values => {
    if (values.message.split('').filter(m => m !== ' ').length > 0) {
      const newReply = {};
      newReply.message = values.message;
      newReply.createdByUser = user.account.name;
      newReply.createdByAvatar = user.account.assets.profile_picture.url;
      newReply.createdByTime = Date.now();
      newReply.id = Date.now();
      newReply.replies = [];
      annotation.thread.filter((annot) => annot.id === selectedMessageThread)[0].replies.push(newReply);
      reset();
    }
  };

  const commentPopUpOptions = [
    {
      selectItem: 'Delete',
      onClick: key => {
        setAnnots(prevAnnots => {
          return prevAnnots.map((annot) => {
            if (annot.key === selectedItem) {
              return {
                ...annot,
                thread: annot.thread.filter(thread => thread.id !== selectedMessageThread),
              }
            }
            return annot;
          })
        })
      },
    },
  ];

  return (
    <>
      <div className='annotation-list-item'>
        <span
          onClick={() => {
            setSelectedItem(annotation.key);
            setOpenAnnotationComment(!openAnnotationComment);
          }}
        >
          {`Annotation ${annotation.slot}`}
        </span>
        <div>
          <img
            className={`livechat__mr20`}
            src={EllipsisHIcon}
            alt={'Options'}
            onClick={() => {
              if (selectedItem === annotation.key && popupOpen === true) {
                setSelectedItem(annotation.key);
                setPopupOpen(false);
              } else {
                setSelectedItem(annotation.key);
                setPopupOpen(true);
              }
            }}
          />
          {
            <PopUp open={popupOpen && selectedItem === annotation.key}>
              {popUpOptions.map(({ selectItem, onClick }) => (
                <li
                  className='pop-up__li'
                  onClick={() => {
                    setPopupOpen(false);
                    if (onClick) {
                      onClick(annotation.key);
                    }
                  }}
                  key={selectItem}
                >
                  {selectItem}
                </li>
              ))}
            </PopUp>
          }
        </div>
      </div>

      {openAnnotationComment && (
        <>
          <div className='annotation-comment-wrapper'>
            <div className='annotation-comment-header'>
              <span>Annotate</span>
              <div className='annotation-comment-header__items-wrapper'>
                <img
                  src={CloseIcon}
                  alt='Close'
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setOpenAnnotationComment(!openAnnotationComment);
                    setViewReplies(false);
                  }}
                />
              </div>
            </div>

            {viewReplies && annotation.thread.filter((annot) => annot.id === selectedMessageThread).map((annot, index) => {
              return (
                <>
                  {/* <div style={{ position: 'relative' }}> */}
                    <div style={{ marginTop: '15px', marginLeft: '16px', display: 'flex', alignItems: 'center'}} className='annotation-reference-name'>
                      <span style={{width: '16px', height: '16px', borderRadius: '50%', backgroundColor: '#000', color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '8px'}}>
                        {annotation.slot}
                      </span>
                      <span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '100%'}}>
                        {reference.name}
                      </span>
                    </div>
                    <div key={`comment-${index}`} className='annotation-conversation'>
                      <div className='annotation-comment-name'>
                        <img className='profile' src={annot.createdByAvatar}></img>
                        <span>{annot.createdByUser}</span>
                        <div className='notifications__time'>
                          <TimeAgo date={annot.createdByTime} />
                        </div>
                      </div>
                      <div className='annotation-comment-text'>{annot.message}</div>
                    </div>
                    {/* <svg width="500" height="500" style={{ position: 'absolute' , top: '72px' }}>
                      <line x1="28" y1="0" x2="28" y2="35" stroke="#f4f4f4"/>
                    </svg> */}
                  {/* </div> */}
                </>
              )
            })}

            {viewReplies
              && annotation.thread.filter((annot) => annot.id === selectedMessageThread)[0].replies.length > 0 
              && annotation.thread.filter((annot) => annot.id === selectedMessageThread)[0].replies.map((annot, index) => {
              return (
                <>
                  <div key={`comment-${index}`} className='annotation-conversation'>
                    <div className='annotation-comment-name'>
                      <img className='profile' src={annot.createdByAvatar}></img>
                      <span>{annot.createdByUser}</span>
                      <div className='notifications__time'>
                        <TimeAgo date={annot.createdByTime} />
                      </div>
                    </div>
                    <div className='annotation-comment-text'>{annot.message}</div>
                  </div>
                </>
              )
            })}

            <div className='annotation-comment-textbox'>
              <div className='collection-access-modal__label'>
                <form id='annotation-comment-form' onSubmit={viewReplies ? handleSubmit(onPostReply) : handleSubmit(onPostMessage)}>
                  <div className='annotation-comment-name'>
                    <img
                      className='profile'
                      src={user.account.assets.profile_picture.url}
                    ></img>
                    <span>{user.account.name}</span>
                  </div>

                  <div style={{ marginTop: '15px', marginLeft: '16px', display: viewReplies ? 'none' : 'flex', alignItems: 'center'}} className='annotation-reference-name'>
                    <span style={{width: '16px', height: '16px', borderRadius: '50%', backgroundColor: '#000', color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '8px'}}>
                      {annotation.slot}
                    </span>
                    <span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '85%'}}>
                      {reference.name}
                    </span>
                  </div>

                  <div className='annotation-comment-form-wrapper'>
                    <TextAreaInput
                      name={'message'}
                      required={false}
                      register={register}
                      placeholder={viewReplies ? 'Enter your reply here.' : 'Enter your comment here.'}
                      update={true}
                      fullWidth={true}
                      onKeyDownHandler={handleSubmit(viewReplies ? handleSubmit(onPostReply) : handleSubmit(onPostMessage))}
                      disabled={loading}
                      value={annotationText}
                    />
                    <div style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'flex-end'
                    }}>
                      <button
                        className='button-small color-white-bd-bg'
                        form='annotation-comment-form'
                        type="submit"
                      >
                        {viewReplies ? 'REPLY' : 'POST'}
                      </button>
                      {viewReplies && (
                        <div
                          className='button-small color-white-bd-bg'
                          onClick={() => {
                            setViewReplies(!viewReplies)
                            reset();
                          }}
                        >
                          CANCEL
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>

            {!viewReplies && annotation.thread.length > 0 && annotation.thread.map((annot, index) => {
              return (
                <div key={`comment-wrapper-${index}`} className='comment-wrapper'>
                  <div
                    style={{ marginTop: '15px', marginLeft: '16px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', position: 'relative'}}
                    className='annotation-reference-name'
                  >
                    {/* <div style={{ display: 'flex', alignItems: 'center'}}>
                      <span style={{width: '16px', height: '16px', borderRadius: '50%', backgroundColor: '#000', color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '8px'}}>
                        {annotation.slot}
                      </span>
                      <span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '30%'}}>
                        {reference.name}
                      </span>
                    </div> */}
                    <div>
                      <img
                        className={`livechat__mr20`}
                        src={EllipsisHIcon}
                        alt={'Options'}
                        onClick={() => {
                          if (selectedMessageThread === annot.id && popupOpen === true) {
                            setSelectedMessageThread(annot.id);
                            setPopupOpen(false);
                          } else {
                            setSelectedMessageThread(annot.id);
                            setPopupOpen(true);
                          }
                        }}
                      />
                      {
                        <PopUp open={popupOpen && selectedMessageThread === annot.id}>
                          {commentPopUpOptions.map(({ selectItem, onClick }) => (
                            <li
                              className='pop-up__li'
                              onClick={() => {
                                setPopupOpen(false);
                                if (onClick) {
                                  setSelectedMessageThread(annot.id);
                                  onClick(annot.id);
                                }
                              }}
                              key={selectItem}
                            >
                              {selectItem}
                            </li>
                          ))}
                        </PopUp>
                      }
                    </div>
                  </div>
                  <div key={`comment-${index}`} className='annotation-conversation'>
                    <div className='annotation-comment-name'>
                      <img className='profile' src={annot.createdByAvatar}></img>
                      <span>{annot.createdByUser}</span>
                      <div className='notifications__time'>
                        <TimeAgo date={annot.createdByTime} />
                      </div>
                    </div>
                    <div className='annotation-comment-text'>{annot.message}</div>
                    <div
                      className='annotation-comment-reply'
                      onClick={() => {
                        setSelectedMessageThread(annot.id);
                        setViewReplies(!viewReplies);
                      }
                    }>
                      {annot.replies.length > 0 
                        ? <div
                            style={{
                              color: '#080707'
                            }}
                            onClick={() => reset()}
                          >
                            {`${annot.replies.length} REPLIES`}
                          </div>
                        : <div onClick={() => reset()}>
                            {'REPLY'}
                          </div>}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </>
      )}
    </>
  );
};

export default AssetAnnotationModal;
