import React, { createContext, useContext, useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import useSWR, { mutate } from 'swr';
import { ISteps } from 'models/Dashboard/ISteps';
import SideNavigation from 'components/Dashboard/SideNavigation/SideNavigation';
import AtelierPattern from 'components/VirtualAtelier/Uploads/AtelierPattern/AtelierPattern';
import AtelierMaterials from 'components/VirtualAtelier/Uploads/AtelierMaterials';
import AtelierAdditions from 'components/VirtualAtelier/Uploads/AtelierModelUploads/AtelierModelUploads';
import AtelierHeader from 'components/VirtualAtelier/StyleSummary/AtelierHeader';
import { mmAPI, putStyleModel, getNotificationsByScope } from 'services/Api';
import { UserContext } from 'providers/UserProvider';
import 'components/VirtualAtelier/StyleSummary/StyleSummary.scss';
import { HistoryModal } from 'components/Common/Modal/HistoryModal';
import { FrameUIActionsTypes, useFrameUI } from 'providers/FrameUIProvider';
import useSidebar from 'hooks/useSidebar';
import { classNamesFromStatus, WORKFLOW_STATUS } from '../StyleSummary/util';

export interface IImages {
  original: string;
  thumbnail?: string;
}

export interface IAdditionalReference {
  steps: ISteps[];
  brand_model_id: string;
  style_model_id: string;
  upload_model_name: string;
  view_type: string;
  view_step: string;
}

const AtelierUploadContainer: React.FC<IAdditionalReference> = props => {
  const { brand_model_id, style_model_id, upload_model_name } = props;
  const [steps, setSteps] = useState([
    {
      slug: 'techpack',
      title: 'Tech Pack & Specs',
      url: `/brand/${brand_model_id}/style/${style_model_id}/techpack`,
      completed: false,
      inProgress: false,
    },
    {
      slug: 'patterns',
      title: 'Patterns',
      url: `/brand/${brand_model_id}/style/${style_model_id}/patterns`,
      completed: false,
      inProgress: false,
      stepsButtonVisibility: true,
    },
    {
      slug: 'materials',
      title: 'Materials',
      url: `/brand/${brand_model_id}/style/${style_model_id}/materials`,
      completed: false,
      inProgress: false,
    },
    {
      slug: 'graphics',
      title: 'Graphics & prints',
      url: `/brand/${brand_model_id}/style/${style_model_id}/graphics`,
      completed: false,
      inProgress: false,
    },
    {
      slug: 'references',
      title: 'Additional References',
      url: `/brand/${brand_model_id}/style/${style_model_id}/references`,
      completed: false,
      inProgress: false,
    },
    {
      slug: 'final',
      title: 'Final Files',
      url: `/brand/${brand_model_id}/style/${style_model_id}/final`,
      completed: false,
      inProgress: false,
    },
  ]);

  const [step, setStep] = useState(null);
  useEffect(() => {
    const locationEffect = uploadModelName => {
      setSteps(prevSteps => {
        prevSteps.forEach(s =>
          s.url.includes(uploadModelName)
            ? (s.inProgress = true) && setStep(s)
            : (s.inProgress = false)
        );
        return [...prevSteps];
      });
    };
    locationEffect(upload_model_name);
  }, [upload_model_name]);

  const { dispatch } = useFrameUI();

  useEffect(() => {
    const filterBarEffect = () => {
      dispatch({
        type: FrameUIActionsTypes.LOCATIONBAR_TITLE,
        payload: 'Virtual Atelier',
      });
      dispatch({
        type: FrameUIActionsTypes.LOCATIONBAR_BACK_ONCLICK,
        payload: `/brand/${brand_model_id}/atelier`,
      });
    };
    filterBarEffect();

    return () => {
      dispatch({
        type: FrameUIActionsTypes.LOCATIONBAR_TITLE,
        payload: null,
      });
      dispatch({
        type: FrameUIActionsTypes.LOCATIONBAR_BACK_ONCLICK,
        payload: null,
      });
    };
  }, [dispatch]);

  const { user } = useContext(UserContext);
  const { idToken } = user;
  const onSetStepComplete = async completed => {
    console.log('completed', completed);
    const response = await putStyleModel(idToken, style_model_id, {
      ...completed,
    });
    if (response?.data?.data) {
      mutate([`/api/style/model/${style_model_id}`, idToken]);
    }
  };

  return (
    <AtelierUploadSteps
      steps={steps}
      setSteps={setSteps}
      currentStep={step}
      onSetStepComplete={onSetStepComplete}
      {...props}
    />
  );
};
export default AtelierUploadContainer;

export const ActivityContext = createContext({
  assetType: null,
  styleId: null,
});

export const AtelierUploadSteps: React.FC<{
  steps: ISteps[];
  setSteps: (arg) => void;
  onSetStepComplete: (arg) => void;
  currentStep: ISteps;
  brand_model_id: string;
  style_model_id: string;
  view_type: string;
  view_step: string;
}> = ({ steps, ...props }) => {
  const { style_model_id, brand_model_id, currentStep, setSteps } = props;

  const activityContext = useContext(ActivityContext);
  activityContext.assetType = currentStep?.slug;
  activityContext.styleId = style_model_id;

  const { user } = useContext(UserContext);
  const { idToken } = user;
  const [openHistory, setOpenHistory] = useState(false);
  const [history, setHistory] = useState([]);

  const { data: style } = useSWR(
    [`/api/style/model/${style_model_id}`, idToken],
    (url, idToken) => {
      return mmAPI(url, idToken);
    },
    {
      suspense: true,
    }
  );

  const getProductScopeHistory = async () => {
    const { data: productLevelHistory } = await getNotificationsByScope(
      idToken,
      style_model_id
    );
    setHistory(productLevelHistory.data);
  };
  useEffect(() => {
    getProductScopeHistory();
  }, []);

  const getProductLevelHistory = () => {
    getProductScopeHistory();
    setOpenHistory(true);
  };

  useEffect(() => {
    const styleEffect = styleVal => {
      setSteps(prevSteps =>
        prevSteps.map(s => ({
          ...s,
          completed: ['true', 'inprogress', true, 'completed'].includes(
            styleVal[`status_${s.slug}`]
          ),
        }))
      );
    };
    styleEffect(style);
  }, [style]);

  const { onSidebarOpen } = useSidebar(idToken, { brand_model_id });
  const buttonActionHandler = () => {
    onSidebarOpen(style);
  };

  const { status = WORKFLOW_STATUS.TO_DO.value } = style?.listing ?? style?.status;

  const skipStepHandler = () => {
    const inProgressIndex = steps.findIndex(s => s.inProgress);
    if (inProgressIndex < steps.length - 1) {
      const styleSummaryRoute = `/brand/${brand_model_id}/style/${style_model_id}/${
        steps[inProgressIndex + 1].slug
      }`;
      navigate(styleSummaryRoute);
    } else {
      const styleSummaryRoute = `/brand/${brand_model_id}/style/${style_model_id}`;
      navigate(styleSummaryRoute);
    }
  };

  return (
    <>
      <HistoryModal
        history={history}
        openHistory={openHistory}
        setOpenHistory={setOpenHistory}
        withLocation={true}
      />
      <AtelierHeader
        heading={{
          label: `${style?.name ?? style?.listing?.name ?? ''} #${style?.number}`,
          link: `/brand/${brand_model_id}/style/${style_model_id}`,
        }}
        buttonActionHandler={buttonActionHandler}
        {...props}
        getProductLevelHistory={getProductLevelHistory}
      />

      <div className='atelier__wrap'>
        <div className='row style-summary__block'>
          <div className='col-md-3'>
            {status && (
              <button className={classNamesFromStatus(status)}>
                {status ?? style?.listing?.status}
              </button>
            )}

            <SideNavigation
              steps={steps}
              currentStep={currentStep}
              buttonPrimaryLabel='Continue'
              buttonPrimaryHandler={skipStepHandler}
              buttonSecondaryHandler={skipStepHandler}
            />
          </div>
          <div className='col-md-9'>
            {currentStep?.slug === 'techpack' && (
              <AtelierAdditions
                atelierUploadConfig={{
                  endpoint: '/api/techpack/query/style',
                  heading: 'Tech Pack & Specs',
                  model: 'Techpack',
                  action: 72,
                }}
                {...props}
              />
            )}
            {currentStep?.slug === 'patterns' && (
              <AtelierPattern
                atelierUploadConfig={{
                  endpoint: '/api/pattern/query/style',
                  heading: 'Patterns',
                  model: 'Patterns',
                  action: 70,
                }}
                {...props}
              />
            )}
            {currentStep?.slug === 'materials' && (
              <AtelierMaterials
                atelierUploadConfig={{
                  endpoint: 'api/material/query/style',
                  heading: 'Materials',
                  model: 'Materials',
                  action: 71,
                }}
                {...props}
              />
            )}
            {currentStep?.slug === 'graphics' && (
              <AtelierAdditions
                atelierUploadConfig={{
                  endpoint: '/api/graphics/query/style',
                  heading: 'Graphic & Prints',
                  model: 'Graphics',
                  action: 75,
                }}
                {...props}
              />
            )}
            {currentStep?.slug === 'references' && (
              <AtelierAdditions
                atelierUploadConfig={{
                  endpoint: '/api/reference/query/style',
                  heading: 'Additional References',
                  model: 'References',
                  action: 74,
                }}
                {...props}
              />
            )}
            {currentStep?.slug === 'final' && (
              <AtelierAdditions
                atelierUploadConfig={{
                  endpoint: '/api/reference/query/style',
                  heading: 'Final Files',
                  model: 'Final',
                  action: 73,
                }}
                {...props}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
