import React from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import MentionTextFieldStyles from './MentionTextFieldStyles';
import merge from 'lodash/merge';

const MentionTextField = (props: any) => {
  const { placeholder } = props;
  function onAdd(id) {
    props.setMentionedPersons(prev => {
      if (prev.includes(id)) {
        return prev;
      }
      return [...prev, id];
    });
  }

  function handleCommentChange(event, newValue, newPlainTextValue, mentions) {
    props.setComment(event.target.value);
  }

  function renderSuggestion(entry) {
    return (
      <>
        <span>{entry.display}</span>
      </>
    );
  }
  const customStyle = merge({}, MentionTextFieldStyles, {
    input: {
      height: '110px',
      overflow: 'auto',
    },
    highlighter: {
      height: 80,
      overflow: 'hidden',
      boxSizing: 'border-box',
    },
  });

  return (
    <>
      <MentionsInput
        style={customStyle}
        value={props.comment}
        onChange={handleCommentChange}
        placeholder={placeholder}
        required={true}
      >
        <Mention
          trigger='@'
          displayTransform={(id, display) => {
            return `@${display}`;
          }}
          data={props?.data}
          renderSuggestion={renderSuggestion}
          onAdd={onAdd}
          appendSpaceOnAdd={true}
        />
      </MentionsInput>
    </>
  );
};

export default MentionTextField;
