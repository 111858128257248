import React, { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import { useLocation, navigate } from '@reach/router';

import './AssetLibraryPage/AssetLibraryPage.scss';
import Breadcrumbs from 'components/Common/Breadcrumbs/Breadcrumbs';
import { CreateModelModal } from 'components/Common/Modal';
import Loader from 'components/Loader/Loader';
import AssetLibraryNav from './AssetLibraryNav/AssetLibraryNav';
import FilePreview from './FilePreview/FilePreview';
import FolderPreview from './FolderPreview/FolderPreview';
import AssetListView from './AssetListView/AssetListView';
import AssetLibraryFilesUpload from './AssetLibraryUpload/AssetLibraryFilesUpload';
import AssetLibraryFolderUpload from './AssetLibraryUpload/AssetLibraryFolderUpload';
import AssetLibraryMoveToModal from './AssetLibraryMoveToModal/AssetLibraryMoveToModal';
import { UserContext, ViewModeContext, NotificationsContext } from 'providers/contexts';
import { UploadContext } from 'providers/UploadProvider';
import { useFrameUI, FrameUIActionsTypes } from 'providers/FrameUIProvider';
import { ModalsContext } from 'providers/ModalsProvider';
import { VIEWMODE } from 'providers/ViewModeProvider';
import {
  getBucketAssetFolder,
  createBucketAssetFolder,
  deleteBucketAssetFolder,
  deleteBucketAssetFile,
  updateBucketAssetFolder,
  updateBucketAssetFile,
  archiveBucketAssetFolder,
  archiveBucketAssetFile,
  unarchiveBucketAssetFolder,
  unarchiveBucketAssetFile,
} from 'services/Api';
import { ASSET_TYPES } from 'constants/assets';
import {
  parsePathFromArray,
  parseFolderBlobs,
  FOLDER,
  FILE,
  ASSETS_LIBRARY,
} from './util';
import PlaceholderImg from 'assets/images/placeholder-image.png';
import IconFolder from 'assets/icons/Icon-folder.svg';

export default function AssetLibraryContainer(props) {
  const { user } = useContext(UserContext);
  const { idToken } = user;
  const { brand_model_id, page, bucket } = props;
  const [loading, setLoading] = useState(true);
  const { setDisplayToast } = useContext(NotificationsContext);

  const location = useLocation();
  const base_url = `/brand/${brand_model_id}/${page}`;
  const folder_url = location.pathname.substring(base_url.length);
  const folder_path_array = folder_url
    .split('/')
    .filter(p => p)
    .map(p => decodeURIComponent(p));
  const folder_path = parsePathFromArray(folder_path_array);

  const [assetLibraryFolders, setAssetLibraryFolders] = useState(
    ASSETS_LIBRARY.assets_library.folders.map(f => ({ name: f }))
  );
  const [productAssetFolders, setProductAssetFolders] = useState(
    ASSETS_LIBRARY.product_asset.folders.map(f => ({ name: f }))
  );

  const [activeFolders, setActiveFolders] = useState([]);
  const [activeFiles, setActiveFiles] = useState([]);

  const [selectedFolders, setSelectedFolders] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const { firebaseStorageUrl } = useContext(UploadContext);
  const { setAppModal, setOpenAppModal, openAppModal } = useContext(ModalsContext);
  const { dispatch } = useFrameUI();

  const { viewMode, setViewMode, setViewsList, setShowLabel } = useContext(
    ViewModeContext
  );

  useEffect(() => {
    setViewsList([VIEWMODE.TABLE, VIEWMODE.LARGE]);
    setShowLabel(false); // to show labels, set `true`
    setViewMode(VIEWMODE.LARGE.key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentFolderUrl = useMemo(
    () => ASSETS_LIBRARY[page].assets_storage_path + bucket.key + folder_path,
    [bucket.key, page, folder_path]
  );

  const fetchBucketHandler = useCallback(async () => {
    let response = await getBucketAssetFolder(
      idToken,
      bucket.key,
      ASSETS_LIBRARY.assets_library.apis.get_folder,
      { path: '/' }
    );
    setAssetLibraryFolders(
      parseFolderBlobs(
        ASSETS_LIBRARY.assets_library.folders.map(f => ({ name: f })),
        response.data.data
      )
    );

    response = await getBucketAssetFolder(
      idToken,
      bucket.key,
      ASSETS_LIBRARY.product_asset.apis.get_folder,
      { path: '/' }
    );
    setProductAssetFolders(
      parseFolderBlobs(
        ASSETS_LIBRARY.product_asset.folders.map(f => ({ name: f })),
        response.data.data
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idToken, bucket.key]);

  useEffect(() => {
    fetchBucketHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idToken, bucket.key]);

  const fetchFolderHandler = useCallback(async () => {
    setLoading(true);
    const response = await getBucketAssetFolder(
      idToken,
      bucket.key,
      ASSETS_LIBRARY[page].apis.get_folder,
      { path: folder_path }
    );
    const { data: blobs } = response.data;

    const folders = [];
    const files = [];
    await Promise.all(
      blobs.map(async blob => {
        const subPathName = blob.path.substring(folder_path.length + 1);
        if (subPathName === '') {
          return;
        }
        const subPath = subPathName.split('/');

        // folder
        const [name] = subPath;
        if (blob.metadata === null || (blob.metadata !== null && subPath.length > 1)) {
          // check to add child folder if missing
          if (folders.findIndex(f => name.localeCompare(f.name) === 0) === -1) {
            folders.push({
              name,
              itemData: {
                key: `folder-${name}`,
                name,
                type: FOLDER,
                icon: IconFolder,
                created_at: blob.time_created,
                metadata: blob.metadata,
              },
            });
          }
        }

        // file
        if (blob.metadata !== null) {
          let ext = null;
          if (subPath.length !== 1) {
            // skip nested files
            return;
          }
          if (blob.metadata?.resizedImage === 'true') {
            // filter out resized images
            return;
          }
          if (blob.metadata?.ext) {
            ext = blob.metadata?.ext;
          }
          const blobUrl = `/${ASSETS_LIBRARY[page].assets_storage_path}${bucket.key}${blob.path}`;
          const content_type = blob.content_type.split('/').pop();
          let firebaseUrl = PlaceholderImg;
          if (ASSET_TYPES.image.extensions.includes(content_type)) {
            try {
              firebaseUrl = await firebaseStorageUrl(blobUrl);
            } catch (err) {
              console.log(err);
            }
          }
          const [name] = subPath;
          files.push({
            imageUrl: firebaseUrl,
            name,
            itemData: {
              key: `file-${name}`,
              name,
              type: FILE,
              imageUrl: firebaseUrl,
              created_at: blob.time_created,
              ext: ext || content_type,
              metadata: blob.metadata,
            },
          });
        }
      })
    );

    setActiveFolders(folders);
    setActiveFiles(files);
    setSelectedFolders([]);
    setSelectedFiles([]);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idToken, bucket.key, page, folder_path]);

  useEffect(() => {
    fetchFolderHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idToken, bucket.key, page, folder_path]);

  const createFolderActionHandler = useCallback(
    async values => {
      const response = await createBucketAssetFolder(
        idToken,
        bucket.key,
        ASSETS_LIBRARY[page].apis.create_folder,
        {
          path: folder_path,
          name: values.name,
        }
      );
      fetchBucketHandler();
      fetchFolderHandler();
    },
    [idToken, bucket.key, page, folder_path, fetchBucketHandler, fetchFolderHandler]
  );

  const onMoveItems = useCallback(() => {
    setAppModal(
      <AssetLibraryMoveToModal
        headingText='Move items to ...'
        bucket={bucket}
        page={page}
        open={openAppModal}
        setOpen={setOpenAppModal}
        onSelect={async new_folder_path => {
          await Promise.all(
            selectedFolders.map(name =>
              updateBucketAssetFolder(
                idToken,
                bucket.key,
                ASSETS_LIBRARY[page].apis.update_folder,
                {
                  path: folder_path,
                  name: name,
                  new_path: new_folder_path,
                  new_name: name,
                  activity: 'move',
                  brand: brand_model_id,
                }
              )
            )
          );
          await Promise.all(
            selectedFiles.map(name =>
              updateBucketAssetFile(
                idToken,
                bucket.key,
                ASSETS_LIBRARY[page].apis.update_file,
                {
                  path: folder_path,
                  name: name,
                  new_path: new_folder_path,
                  new_name: name,
                  activity: 'move',
                  brand: brand_model_id,
                }
              )
            )
          );
          fetchBucketHandler();
          fetchFolderHandler();
        }}
      />
    );
    setOpenAppModal(true);
  }, [
    idToken,
    bucket.key,
    page,
    folder_path,
    selectedFolders,
    selectedFiles,
    fetchBucketHandler,
    fetchFolderHandler,
  ]);

  const onMoveItem = useCallback(
    item => {
      setAppModal(
        <AssetLibraryMoveToModal
          headingText='Move items to ...'
          bucket={bucket}
          page={page}
          open={openAppModal}
          setOpen={setOpenAppModal}
          onSelect={async new_folder_path => {
            const { name, type } = item;
            if (type === FOLDER) {
              const response = await updateBucketAssetFolder(
                idToken,
                bucket.key,
                ASSETS_LIBRARY[page].apis.update_folder,
                {
                  path: folder_path,
                  name: name,
                  new_path: new_folder_path,
                  new_name: name,
                  activity: 'move',
                  brand: brand_model_id,
                }
              );
              fetchBucketHandler();
              fetchFolderHandler();
            } else if (type === FILE) {
              const response = await updateBucketAssetFile(
                idToken,
                bucket.key,
                ASSETS_LIBRARY[page].apis.update_file,
                {
                  path: folder_path,
                  name: name,
                  new_path: new_folder_path,
                  new_name: name,
                  activity: 'move',
                  brand: brand_model_id,
                }
              );
              fetchFolderHandler();
            }
          }}
        />
      );
      setOpenAppModal(true);
    },
    [idToken, bucket.key, page, folder_path, fetchBucketHandler, fetchFolderHandler]
  );

  const onRenameFolder = useCallback(
    ({ name }) => {
      setAppModal(
        <CreateModelModal
          formId='Rename'
          modalHeading='Rename'
          modelName=''
          btnCloseLabel='CANCEL'
          btnActionLabel='RENAME'
          open={openAppModal}
          setOpen={setOpenAppModal}
          modelInputLabel='NEW NAME'
          btnActionHandler={async values => {
            const response = await updateBucketAssetFolder(
              idToken,
              bucket.key,
              ASSETS_LIBRARY[page].apis.update_folder,
              {
                path: folder_path,
                name: name,
                new_path: folder_path,
                new_name: values?.name,
                activity: 'rename',
                brand: brand_model_id,
              }
            );
            fetchBucketHandler();
            fetchFolderHandler();
          }}
          closeOnSubmit={true}
        />
      );
      setOpenAppModal(true);
    },
    [
      idToken,
      bucket.key,
      page,
      folder_path,
      openAppModal,
      setOpenAppModal,
      fetchFolderHandler,
    ]
  );

  const onRenameFile = useCallback(
    ({ name }) => {
      setAppModal(
        <CreateModelModal
          formId='Rename'
          modalHeading='Rename'
          modelName=''
          btnCloseLabel='CANCEL'
          btnActionLabel='RENAME'
          open={openAppModal}
          setOpen={setOpenAppModal}
          modelInputLabel='NEW NAME'
          btnActionHandler={async values => {
            const response = await updateBucketAssetFile(
              idToken,
              bucket.key,
              ASSETS_LIBRARY[page].apis.update_file,
              {
                path: folder_path,
                name: name,
                new_path: folder_path,
                new_name: values?.name,
                activity: 'rename',
                brand: brand_model_id,
              }
            );
            fetchFolderHandler();
          }}
          closeOnSubmit={true}
        />
      );
      setOpenAppModal(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      idToken,
      bucket.key,
      page,
      folder_path,
      openAppModal,
      setOpenAppModal,
      fetchFolderHandler,
    ]
  );

  const onRenameItem = useCallback(
    item => {
      const { type } = item;
      if (type === FOLDER) {
        onRenameFolder(item);
      }
      if (type === FILE) {
        onRenameFile(item);
      }
    },
    [onRenameFolder, onRenameFile]
  );

  const onArchiveFolder = useCallback(
    async ({ name }) => {
      const response = await archiveBucketAssetFolder(
        idToken,
        bucket.key,
        ASSETS_LIBRARY[page].apis.archive_folder,
        {
          path: folder_path,
          name: name,
        }
      );
      fetchBucketHandler();
      fetchFolderHandler();
    },
    [idToken, bucket.key, page, folder_path, fetchBucketHandler, fetchFolderHandler]
  );

  const onArchiveFile = useCallback(
    async ({ name }) => {
      const response = await archiveBucketAssetFile(
        idToken,
        bucket.key,
        ASSETS_LIBRARY[page].apis.archive_file,
        {
          path: folder_path,
          name: name,
        }
      );
      fetchFolderHandler();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [idToken, bucket.key, page, folder_path]
  );

  const onArchiveItem = useCallback(
    item => {
      const { type } = item;
      if (type === FOLDER) {
        onArchiveFolder(item);
      }
      if (type === FILE) {
        onArchiveFile(item);
      }
    },
    [onArchiveFolder, onArchiveFile]
  );

  const onUnarchiveFolder = useCallback(
    async ({ name }) => {
      const response = await unarchiveBucketAssetFolder(idToken, bucket.key, {
        path: folder_path,
        name: name,
      });
      fetchBucketHandler();
      fetchFolderHandler();
    },
    [idToken, bucket.key, folder_path, fetchBucketHandler, fetchFolderHandler]
  );

  const onUnarchiveFile = useCallback(
    async ({ name }) => {
      const response = await unarchiveBucketAssetFile(idToken, bucket.key, {
        path: folder_path,
        name: name,
      });
      fetchFolderHandler();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [idToken, bucket.key, folder_path]
  );

  const onUnarchiveItem = useCallback(
    item => {
      const { type, metadata } = item;
      if (metadata?.old_path) {
        if (type === FOLDER) {
          onUnarchiveFolder(item);
        }
        if (type === FILE) {
          onUnarchiveFile(item);
        }
      }
    },
    [onUnarchiveFolder, onUnarchiveFile]
  );

  const onDeleteFolder = useCallback(
    async ({ name }) => {
      const response = await deleteBucketAssetFolder(
        idToken,
        bucket.key,
        ASSETS_LIBRARY[page].apis.delete_folder,
        {
          path: folder_path,
          name: name,
          brand: brand_model_id,
        }
      );
      fetchBucketHandler();
      fetchFolderHandler();
    },
    [idToken, bucket.key, page, folder_path, fetchBucketHandler, fetchFolderHandler]
  );

  const onDeleteFile = useCallback(
    async ({ name }) => {
      const response = await deleteBucketAssetFile(
        idToken,
        bucket.key,
        ASSETS_LIBRARY[page].apis.delete_file,
        {
          path: folder_path,
          name: name,
          brand: brand_model_id,
        }
      );
      fetchFolderHandler();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [idToken, bucket.key, page, folder_path]
  );

  const onDeleteItem = useCallback(
    item => {
      const { type } = item;
      if (type === FOLDER) {
        onDeleteFolder(item);
      }
      if (type === FILE) {
        onDeleteFile(item);
      }
    },
    [onDeleteFolder, onDeleteFile]
  );

  const popUpAssetLibrary = useMemo(() => {
    return [
      {
        selectItem: 'Create folder',
        ModalComponent: (
          <CreateModelModal
            formId='createFolder'
            modelName='Folder'
            modelInputLabel='Name'
            modalHeading='Create'
            btnActionLabel='Create'
            btnActionHandler={createFolderActionHandler}
            closeOnSubmit
          />
        ),
      },
      ...(page !== 'archive_library'
        ? [
            {
              selectItem: 'Upload files',
              ModalComponent: (
                <AssetLibraryFilesUpload
                  path={currentFolderUrl}
                  onUploadHandler={fetchFolderHandler}
                  onClose={fetchFolderHandler}
                />
              ),
            },
            {
              selectItem: 'Upload folder',
              ModalComponent: (
                <AssetLibraryFolderUpload
                  path={currentFolderUrl}
                  onUploadHandler={fetchFolderHandler}
                  onClose={fetchBucketHandler}
                />
              ),
            },
          ]
        : []),
      {
        selectItem: 'Move selected items',
        onClick: onMoveItems,
      },
    ];
  }, [
    currentFolderUrl,
    createFolderActionHandler,
    fetchFolderHandler,
    fetchBucketHandler,
    onMoveItems,
    page,
  ]);

  const popUpOptions = useMemo(() => {
    if (page === 'assets_library' || page === 'product_asset') {
      return [
        {
          selectItem: 'Rename',
          onClick: onRenameItem,
        },
        {
          selectItem: 'Move',
          onClick: onMoveItem,
        },
        {
          selectItem: 'Archive',
          onClick: onArchiveItem,
        },
        {
          selectItem: 'Delete',
          onClick: onDeleteItem,
        },
        {
          selectItem: 'Copy Link',
          disabled: false,
          onClick: item => {
            item.type === 'folder'
              ? navigator.clipboard.writeText(
                  process.env.REACT_APP_PUBLIC_URL + location.pathname + '/' + item.name
                )
              : navigator.clipboard.writeText(
                  process.env.REACT_APP_PUBLIC_URL +
                    location.pathname +
                    '?name=' +
                    item.name
                );
            setDisplayToast({
              persist: false,
              type: 'success',
              message: `Copied to clipboard.`,
            });
          },
        },
      ];
    }
    if (page === 'archive_library') {
      return [
        {
          selectItem: 'Unarchive',
          onClick: onUnarchiveItem,
        },
        {
          selectItem: 'Move',
          onClick: onMoveItem,
        },
        {
          selectItem: 'Delete',
          onClick: onDeleteItem,
        },
      ];
    }
  }, [onRenameItem, onMoveItem, onDeleteItem]);

  useEffect(() => {
    const filterBarEffect = () => {
      // dispatch({ type: FrameUIActionsTypes.FILTER_BAR_VISIBLE, payload: true });
      dispatch({
        type: FrameUIActionsTypes.FILTER_BAR_OPTIONS,
        payload: popUpAssetLibrary,
      });
      dispatch({
        type: FrameUIActionsTypes.FILTER_BAR_VIEWMODE_ICON_VISIBLE,
        payload: true,
      });

      return () => {
        dispatch({ type: FrameUIActionsTypes.FILTER_BAR_RESET });
      };
    };
    return filterBarEffect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popUpAssetLibrary]);

  const onClickFolderPreview = name => {
    navigate(base_url + folder_path + '/' + encodeURIComponent(name));
  };

  const onClickFolderItem = item => onClickFolderPreview(item?.name);

  const onSelectFolderItem = itemData => {
    if (selectedFolders.includes(itemData.name)) {
      setSelectedFolders(prev => prev.filter(f => itemData.name != f));
    } else {
      setSelectedFolders(prev => [...prev, itemData.name]);
    }
  };

  const onSelectFileItem = itemData => {
    if (selectedFiles.includes(itemData.name)) {
      setSelectedFiles(prev => prev.filter(f => itemData.name != f));
    } else {
      setSelectedFiles(prev => [...prev, itemData.name]);
    }
  };

  const pages = useMemo(() => {
    const pagesArray = [];
    let link = base_url;
    for (let i = 0; i < folder_path_array.length; i += 1) {
      const p = folder_path_array[i];
      link += `/${p}`;
      const page = {
        title: p,
      };
      if (i < folder_path_array.length - 1) {
        page.link = link;
      }
      pagesArray.push(page);
    }
    if (folder_path_array.length > 0) {
      pagesArray.push({
        title: '<',
        link: '/',
        onClick: () =>
          navigate(
            base_url +
              parsePathFromArray(folder_path_array.slice(0, folder_path_array.length - 1))
          ),
      });
    }
    return pagesArray;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folder_path_array]);

  if (loading) {
    return <Loader active />;
  }

  return (
    <div style={{ backgroundColor: '#f9f9f9', marginTop: '50px' }}>
      <div className='asset-library-page'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-3'>
              <div
                className='asset-library-page__heading'
                onClick={() =>
                  navigate(
                    `/brand/${brand_model_id}/${ASSETS_LIBRARY.assets_library.base_url}`
                  )
                }
              >
                Asset Library
              </div>
              <AssetLibraryNav
                navFolders={assetLibraryFolders}
                activeFolderPath={
                  page === ASSETS_LIBRARY.assets_library.base_url ? folder_path_array : []
                }
                onClickFolder={path =>
                  navigate(
                    `/brand/${brand_model_id}/${ASSETS_LIBRARY.assets_library.base_url}` +
                      parsePathFromArray(path, true)
                  )
                }
              />
              <div
                className='asset-library-page__heading'
                onClick={() =>
                  navigate(
                    `/brand/${brand_model_id}/${ASSETS_LIBRARY.product_asset.base_url}`
                  )
                }
              >
                Product Asset
              </div>
              <AssetLibraryNav
                navFolders={productAssetFolders}
                activeFolderPath={
                  page === ASSETS_LIBRARY.product_asset.base_url ? folder_path_array : []
                }
                onClickFolder={path =>
                  navigate(
                    `/brand/${brand_model_id}/${ASSETS_LIBRARY.product_asset.base_url}` +
                      parsePathFromArray(path, true)
                  )
                }
              />
              <hr className='asset-library-page__horizontal-line' />
              <div
                className='asset-library-page__heading inactive'
                onClick={() =>
                  navigate(
                    `/brand/${brand_model_id}/${ASSETS_LIBRARY.archive_library.base_url}`
                  )
                }
              >
                Archive
              </div>
            </div>
            <div className='col-md-9'>
              <Breadcrumbs pages={pages} />
              {activeFolders.length + activeFiles.length > 0 ? (
                viewMode === VIEWMODE.LARGE.key ? (
                  <>
                    <div className='asset-library-page__flex'>
                      {activeFolders.map(({ name, itemData }, idx) => (
                        <div className='asset-library-page__flex-item' key={idx}>
                          <FolderPreview
                            name={name}
                            itemData={itemData}
                            popUpOptions={popUpOptions}
                            checked={selectedFolders.includes(itemData.name)}
                            onClickFolderPreview={onClickFolderPreview}
                            onSelectFolder={onSelectFolderItem}
                          />
                        </div>
                      ))}
                    </div>
                    <div className='asset-library-page__flex'>
                      {activeFiles.map(({ imageUrl, name, itemData }, idx) => (
                        <div className='asset-library-page__flex-item' key={idx}>
                          <FilePreview
                            imageUrl={imageUrl}
                            name={name}
                            itemData={itemData}
                            popUpOptions={popUpOptions}
                            checked={selectedFiles.includes(itemData.name)}
                            onSelectFile={onSelectFileItem}
                          />
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <AssetListView
                    assets={[...activeFolders, ...activeFiles]}
                    popUpOptions={popUpOptions}
                    onClickRow={onClickFolderItem}
                  />
                )
              ) : (
                <div className='asset-library-page__empty'>
                  <div className='asset-library-page__empty-h'>THIS FOLDER IS EMPTY</div>
                  <div className='asset-library-page__empty-p'>
                    Upload file with + button
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
