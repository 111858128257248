import React, { useContext } from 'react';
import useMessages from 'hooks/useMessages';
import { ReadStatus } from './ReadStatus';
import { UserContext } from 'providers/UserProvider';

export interface IGroupChatContact {
  name?: string;
  key?: number;
  checked: boolean;
  profilePicture?: string;
}

export const ChatGroups = props => {
  const { groupChats, readStatus = false, getSelectedGroupMembers } = props;
  const { user } = useContext(UserContext);
  const { idToken } = user;
  const { setMessageModelAssoc } = useMessages(idToken, {
    model: null,
    open: false,
  });

  const onClickChat = group => {
    if (group) {
      setMessageModelAssoc(group);
      getSelectedGroupMembers(group.teamId);
    }
  };

  return (
    <>
      <div className='livechat__heading'>Group Chat</div>

      {groupChats &&
        groupChats?.map((group, index) => (
          <div
            className='livechat__item'
            key={group.teamId}
            onClick={() => {
              onClickChat(group);
            }}
          >
            <div
              style={{
                position: 'relative',
                width: '10%',
              }}
            >
              {group.members.slice(0, 3).map((member, idx) => {
                return (
                  <div
                    key={idx}
                    className='livechat__avatar'
                    style={{
                      position: 'absolute',
                      marginTop: `${idx * 7}px`,
                      marginLeft: `${idx * 5}px`,
                    }}
                  >
                    {!member?.profile_picture.urls.download ? (
                      <div className='livechat__avatar'>{member?.name?.slice(0, 2)}</div>
                    ) : (
                      <div className='livechat__avatar'>
                        <img
                          src={member?.profile_picture.urls.download}
                          alt='Profile'
                          style={{
                            borderRadius: '50%',
                          }}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className='livechat__content'>
              <div className='livechat__name'>
                <strong>{group.name}</strong> <ReadStatus read={readStatus} />
              </div>
              {group.members.length > 0 && (
                <div className='d-flex'>
                  {group.members.length > 3
                    ? group.members.slice(0, 3).map(({ name }, idx) => {
                        return (
                          <div key={idx} className='livechat__msg'>
                            {name.substring(0, 10) + ','} &nbsp;
                          </div>
                        );
                      })
                    : group.members.map(({ name }, idx) => {
                        return (
                          <div key={idx} className='livechat__msg'>
                            {name + ', '} &nbsp;
                          </div>
                        );
                      })}
                </div>
              )}
            </div>
          </div>
        ))}
    </>
  );
};
