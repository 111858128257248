/* eslint-disable no-debugger */
import React, { Suspense, useContext, useEffect, useState } from 'react';
import { useLocation, Router, navigate } from '@reach/router';
import useSWR, { SWRConfig } from 'swr';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocationIsSettings, useLocationSearchParam } from 'hooks/useLocation';
import {
  mmAPI,
  postNotificationMethodMarkRead,
  postNotificationMethodMarkReadAll,
  postNotificationMethodMarkUnreadAll,
} from 'services/Api';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { UserContext, NotificationsContext, ModalsContext } from 'providers/contexts';
import { firebaseStorageUrl } from 'providers/UploadProvider';
import Team from 'components/Brands/Team';
import Listing from 'components/Products/Listing';
import Collections from 'components/Collections/Collections';
import Contacts from 'components/Brands/Contacts';
import LogRocket from 'logrocket';
import {
  LoadingAlert,
  ErrorFallback,
  SignIn,
  SignUp,
  PasswordReset,
  ResetPassword,
  Frame,
  Brand,
  BrandProductUpload,
  Brands,
  Product,
  ProductsFilterList,
  HeaderAlert,
  BrandsCreateForm,
  Toast,
  Lines,
  SettingsContainer,
  CompanyProfileContainer,
  BrandCustomization,
  ConfirmDialog,
  AtelierSummaryContainer,
  AtelierUploadContainer,
  NotificationList,
  AssetLibraryPage,
} from 'components';
import { useFrameUI, FrameUIActionsTypes } from 'providers/FrameUIProvider';
import 'styles/index.scss';
import { isSuperset } from './utils/isSuperset';
import UserManagement from 'components/UserManagement';
import VerifyEmail from 'components/VerifyEmail';
import unionBy from 'lodash.unionby';
import firebase from 'firebase/app';

TimeAgo.addDefaultLocale(en);

function App() {
  const { user, setUser } = useContext(UserContext);
  const { idToken, authError } = user;
  const { setOpenDialog, setDialogProps, openDialog, dialogProps } = useContext(
    ModalsContext
  );

  return (
    <>
      {user.alert && <HeaderAlert />}
      <Suspense fallback={<LoadingAlert />}>
        <ConfirmDialog open={openDialog} setOpen={setOpenDialog} {...dialogProps} />
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={ErrorResetHandler}
          onError={ErrorHandler}
        >
          <SWRConfig
            value={{
              onErrorRetry: error => {
                // Never retry on 401
                if (error.status === 401) return;
              },
              onError: error => {
                const { response, message, request } = error;
                console.trace(
                  'SWRConfig onerror error',
                  error,
                  response,
                  message,
                  request
                );
                const { data, status, headers } = response;
                console.trace('SWRConfig onerror response', data, status, headers);
                if (status === 401 && !authError) {
                  setUser(prevUser => ({
                    ...prevUser,
                    authError: status,
                  }));
                  setDialogProps({
                    dialogBody: 'Your session has expired. Refresh session?',
                    closeAction: false,
                    btnActionHandler: e => {
                      // try {
                      window?.location.reload();
                      //   const response = await mmAPI(
                      //     '/api/account/method/idTokenFromRefreshToken',
                      //     null,
                      //     { refresh_token: user.refreshToken },
                      //     'POST',
                      //     { refresh_token: user.refreshToken }
                      //   );
                      //   const {
                      //     refreshToken,
                      //     idToken: idTokenRefreshed,
                      //     account,
                      //     custom_token,
                      //   } = response;
                      //   setUser(prevUser => ({
                      //     ...prevUser,
                      //     account,
                      //     custom_token,
                      //     refreshToken,
                      //     idToken: idTokenRefreshed,
                      //     authError: null,
                      //   }));
                      //   // API endpoint should refresh server side session using the idToken from google response
                      //   // client should update user.account in context
                      //   setAuthToken(idTokenRefreshed);
                      //   // given the API is signed in the custom_token can be used to refresh the client session.
                      // } catch (error) {
                      //   console.error('idTokenFromRefreshToken error refreshing token');
                      //   console.error(error);
                      // }
                    },
                  });
                  setOpenDialog(true);
                } else {
                  console.trace('NOT 401 error', error.status, error.reponse);
                }
              },
            }}
          >
            <Router>
              <SignInRoute path='/' />
              <SignUpRoute path='/register' />
              <SignUpRoute path='/invite' />
              <PasswordReset path='/password-reset' />
              <ResetPassword path='/reset-password' />
              <VerifyEmail path='/verify-email' />
              <UserManagement path='/user-management' />
              <AccountRoute
                as={Brands}
                path='/brands'
                accessControl={['owner', 'editor', 'read']}
                filterBarVisible={true}
                dashboardNavVisible={false}
              />
              <AccountRoute
                as={Brand}
                fullwidth={true}
                path='/brand/:brand_model_id'
                accessControl={['owner', 'editor', 'read']}
                filterBarVisible={false}
                dashboardNavVisible={false}
                noPadding={true}
              />
              <AccountRoute as={Brand} path='/brand/:brand_model_id/assets' />
              <AccountRoute
                as={Team}
                path='/brand/:brand_model_id/team'
                fullwidth={false}
                accessControl={['owner']}
                filterBarVisible={true}
                dashboardNavVisible={true}
              />
              <AccountRoute
                as={Contacts}
                path='/brand/:brand_model_id/contacts'
                fullwidth={false}
                accessControl={['owner', 'editor', 'read']}
                filterBarVisible={true}
                dashboardNavVisible={false}
              />
              <AccountRoute as={Brand} path='/brand/:brand_model_id/marketing' />
              <AccountRoute
                as={ProductsFilterList}
                path='/brand/:brand_model_id/products/'
                fullwidth={false}
                accessControl={['owner', 'editor', 'read']}
                filterBarVisible={true}
              />
              <AccountRoute
                as={ProductsFilterList}
                path='/brand/:brand_model_id/atelier/'
                fullwidth={false}
                accessControl={['owner', 'editor', 'read']}
                filterBarVisible={true}
              />
              <AccountRoute
                as={AssetLibraryPage}
                path='/brand/:brand_model_id/assets_library/*'
                fullwidth={false}
                accessControl={['owner', 'editor', 'read']}
                filterBarVisible={true}
                page='assets_library'
              />
              <AccountRoute
                as={AssetLibraryPage}
                path='/brand/:brand_model_id/product_asset/*'
                fullwidth={false}
                accessControl={['owner', 'editor', 'read']}
                filterBarVisible={true}
                page='product_asset'
              />
              <AccountRoute
                as={AssetLibraryPage}
                path='/brand/:brand_model_id/archive_library/*'
                fullwidth={false}
                accessControl={['owner', 'editor', 'read']}
                filterBarVisible={true}
                page='archive_library'
              />
              <AccountRoute
                as={NotificationList}
                path='/brand/:brand_model_id/notifications/'
                fullwidth={false}
                accessControl={['owner', 'editor', 'read']}
                filterBarVisible={true}
              />
              <AccountRoute
                as={BrandProductUpload}
                path='/brand/:brand_model_id/products/upload'
                accessControl={['owner', 'editor']}
              />
              <AccountRoute
                as={BrandProductUpload}
                path='/brand/:brand_model_id/collection/:collection_model_id/products/upload'
                accessControl={['owner', 'editor', 'read']}
              />
              <AccountRoute
                as={BrandProductUpload}
                path='/brand/:brand_model_id/line/:line_model_id/products/upload'
                accessControl={['owner', 'editor', 'read']}
              />
              <AccountRoute
                as={BrandProductUpload}
                path='/brand/:brand_model_id/products/media/upload'
                accessControl={['owner', 'editor', 'read']}
              />
              <AccountRoute
                as={BrandProductUpload}
                path='/brand/:brand_model_id/products/media/manage'
                accessControl={['owner', 'editor', 'read']}
              />
              <AccountRoute
                as={BrandProductUpload}
                path='/brand/:brand_model_id/collection/:collection_model_id/products/media/upload'
                accessControl={['owner', 'editor', 'read']}
              />
              <AccountRoute
                as={BrandCustomization}
                path='/brand/:brand_model_id/customization'
                fullwidth={true}
                accessControl={['owner', 'editor']}
                dashboardNavVisible={true}
              />
              <AccountRoute
                as={CompanyProfileContainer}
                path='/brand/:brand_model_id/edit'
                accessControl={['owner', 'editor']}
                dashboardNavVisible={true}
              />
              <AccountRoute
                as={BrandsCreateForm}
                path='/brands/create'
                accessControl={['owner', 'editor']}
              />
              <AccountRoute
                as={BrandsCreateForm}
                path='/brand/:brand_model_id/lines/create'
                accessControl={['owner']}
              />
              <AccountRoute
                as={BrandsCreateForm}
                path='/brand/:brand_model_id/collections/create'
                accessControl={['owner']}
              />
              <AccountRoute
                as={Product}
                path='brand/:brand_model_id/products/:product_model_id'
                fullwidth={false}
                accessControl={['owner', 'editor', 'read']}
              />
              <AccountRoute
                as={Listing}
                path='/brand/:brand_model_id/products/listing/:listing_model_id'
                fullwidth={true}
                accessControl={['owner', 'editor', 'read']}
                filterBarVisible={false}
              />
              <AccountRoute
                as={AtelierSummaryContainer}
                path='brand/:brand_model_id/style/:style_model_id'
                fullwidth={true}
                noPadding={true}
                accessControl={['owner', 'editor', 'read']}
              />
              <AccountRoute
                as={AtelierSummaryContainer}
                path='brand/:brand_model_id/style/:style_model_id/samples'
                fullwidth={true}
                noPadding={true}
                accessControl={['owner', 'editor', 'read']}
              />
              <AccountRoute
                as={AtelierSummaryContainer}
                path='brand/:brand_model_id/style/:style_model_id/samples/archive'
                fullwidth={true}
                noPadding={true}
                accessControl={['owner', 'editor', 'read']}
              />
              <AccountRoute
                as={AtelierSummaryContainer}
                path='brand/:brand_model_id/style/:style_model_id/samples/:reference_model_id'
                fullwidth={true}
                noPadding={true}
                accessControl={['owner', 'editor', 'read']}
              />
              <AccountRoute
                as={AtelierUploadContainer}
                path='brand/:brand_model_id/style/:style_model_id/:upload_model_name'
                fullwidth={true}
                noPadding={true}
                accessControl={['owner', 'editor', 'read']}
              />
              <AccountRoute
                as={AtelierUploadContainer}
                path='brand/:brand_model_id/style/:style_model_id/:upload_model_name/:view_type/:view_step'
                fullwidth={true}
                noPadding={true}
                accessControl={['owner', 'editor', 'read']}
              />
              <AccountRoute as={SettingsContainer} path='/settings' noPadding={true} />
              <AccountRoute as={SettingsContainer} path='/settings/:section' />
              <AccountRoute
                as={SettingsContainer}
                path='/brand/:brand_model_id/settings'
                accessControl={['owner', 'editor', 'read']}
              />
              <AccountRoute
                as={SettingsContainer}
                path='/settings/profile'
                dashboardNavVisible={true}
              />
              <AccountRoute
                as={SettingsContainer}
                path='/settings/security'
                dashboardNavVisible={true}
              />
              <AccountRoute
                as={Lines}
                path='/brand/:brand_model_id/lines'
                accessControl={['owner', 'editor', 'read']}
              />
              <AccountRoute
                as={Collections}
                path='/brand/:brand_model_id/line/:line_model_id/collections'
                accessControl={['owner', 'editor', 'read']}
              />
              <AccountRoute
                as={Collections}
                path='/brand/:brand_model_id/collections'
                accessControl={['owner', 'editor', 'read']}
              />
            </Router>
          </SWRConfig>
        </ErrorBoundary>
      </Suspense>
    </>
  );
}

const ErrorResetHandler = () => {
  // eslint-disable-next-line no-debugger
  debugger;
};

const ErrorHandler = () => {
  // eslint-disable-next-line no-debugger
  debugger;
};

const SignInRoute = props => {
  const { user } = useContext(UserContext);
  const { displayToast, setDisplayToast } = useContext(NotificationsContext);
  const inviteTokenParam = useLocationSearchParam('token');

  React.useEffect(() => {
    const userAccountEffect = userAccount => {
      console.trace(
        'App redirect based on userAccount or invite',
        user,
        userAccount,
        user?.invite
      );
      if (userAccount) {
        if (displayToast && !['success'].includes(displayToast.type)) {
          setDisplayToast(false);
        }
        console.trace('userAccount.status onboarding', userAccount.status);
        if (userAccount.status.indexOf('onboarding') === 0) {
          navigate('/settings');
        } else {
          const inviteBrandModel = user?.invite?.data.find(p => p.KIND === 'Brand');
          if (inviteBrandModel) {
            console.trace('redirect based on user brand invite', inviteBrandModel);
            console.log('user?.invite?', user?.invite, user?.invite?.status);
            if (user?.invite?.status === 'accepted') {
              navigate(`/brand/${inviteBrandModel?.key}`);
            } else if (!user?.inviteFlow) {
              navigate(`/`);
            }
          } else if (userAccount?.preferences?.brand) {
            console.trace(
              'redirect based on userAccount preferences',
              userAccount?.preferences.brand
            );
            navigate(`/brand/${userAccount?.preferences?.brand}`);
          } else {
            console.trace('redirect default');
            navigate('/brands');
          }
        }
      }
    };
    userAccountEffect(user?.account);
  }, [displayToast, setDisplayToast, user?.account, inviteTokenParam]);

  return (
    <>
      <Toast />
      {(!user.account || inviteTokenParam) && <SignIn {...props} />}
    </>
  );
};

export const SignUpRoute = props => {
  const { setDisplayToast } = useContext(NotificationsContext);
  const SignUpErrorResetHandler = () => {
    // eslint-disable-next-line no-debugger
    debugger;
  };
  const SignUpErrorHandler = error => {
    if (error.type === 'InviteNotFound') {
      setDisplayToast({
        persist: true,
        type: 'error',
        message: 'Invite Not Found. Please ask a team Admin to send you a new invite.',
      });
    }
  };
  return (
    <>
      <Toast />
      <ErrorBoundary
        FallbackComponent={SignInRoute}
        onReset={SignUpErrorResetHandler}
        onError={SignUpErrorHandler}
      >
        <SignUp {...props} />
      </ErrorBoundary>
    </>
  );
};

const AccountRoute = ({ as: Component, fullwidth, noPadding, ...props }) => {
  const { user, setUser } = useContext(UserContext);
  const { idToken } = user;
  const location = useLocation();
  const {
    brand_model_id = null,
    line_model_id,
    collection_model_id,
    style_model_id,
    accessControl,
    filterBarVisible = false,
    dashboardNavVisible = false,
  } = props;
  const [accessGranted, setAccessGranted] = React.useState([]);
  const {
    openDialog,
    setOpenDialog,
    dialogProps,
    AppModal,
    openAppModal,
    appModalProps,
  } = useContext(ModalsContext);
  const { setDisplayToast, notifications, setNotifications } = useContext(
    NotificationsContext
  );

  const { data: permissions } = useSWR(
    user?.account && idToken ? ['/api/permission/query/grantee', idToken] : null,
    (url, idToken) => {
      return mmAPI(url, idToken);
    },
    { suspense: true }
  );

  const { data: brand } = useSWR(
    user?.account && !!brand_model_id
      ? [`/api/brand/model/${brand_model_id}`, idToken]
      : null,
    (url, idToken) => {
      return mmAPI(url, idToken);
    },
    {
      suspense: true,
    }
  );

  const { data: notificationsData, mutate: mutateNotifications } = useSWR(
    user?.account
      ? [`/api/notification/method/notifications_flat`, idToken, user.account.key]
      : null,
    (url, idToken, account) => {
      return mmAPI(url, idToken, { account });
    },
    {
      suspense: false,
    }
  );
  useEffect(() => {
    const notificationsTransformed = notificationsData?.map(n => {
      return {
        firstName: n.firstName,
        lastName: n.lastName,
        segments: n.segments,
        when: n.when,
        key: n.targetId,
        whenRead: n.whenRead,
      };
    });
    setNotifications(notificationsTransformed);
  }, [notificationsData]);

  const { data: line } = useSWR(
    user?.account && line_model_id ? [`/api/line/model/${line_model_id}`, idToken] : null,
    (url, idToken) => {
      return mmAPI(url, idToken);
    },
    {
      suspense: true,
    }
  );

  const { data: collection } = useSWR(
    user?.account && collection_model_id
      ? [`/api/collection/model/${collection_model_id}`, idToken]
      : null,
    (url, idToken) => {
      return mmAPI(url, idToken);
    },
    {
      suspense: true,
    }
  );

  const { data: style } = useSWR(
    user?.account && style_model_id
      ? [`/api/style/model/${style_model_id}`, idToken]
      : null,
    (url, idToken) => {
      return mmAPI(url, idToken);
    },
    {
      suspense: true,
    }
  );

  const brandEffect = React.useCallback(
    async userBrand => {
      if (!userBrand) return;
      if (user?.account && userBrand?.assets?.logo && !userBrand.assets.logo.url) {
        try {
          userBrand.assets.logo.url = await firebaseStorageUrl(
            userBrand.assets.logo.path
          );
        } catch (err) {
          // console.trace(err);
        }
      }
      setUser(prevUser => ({
        ...prevUser,
        brand: userBrand,
      }));
    },
    [user?.account]
  );

  React.useEffect(() => {
    brandEffect(brand);
  }, [brand, brandEffect]);

  React.useEffect(() => {
    brandEffect(user.brand);
  }, [user?.brand?.assets?.logo?.url, brandEffect]);

  React.useEffect(() => {
    setUser(prevUser => ({
      ...prevUser,
      line,
    }));
  }, [line]);

  React.useEffect(() => {
    setUser(prevUser => ({
      ...prevUser,
      collection,
    }));
  }, [collection]);

  React.useEffect(() => {
    setUser(prevUser => ({
      ...prevUser,
      style,
    }));
  }, [style]);

  React.useEffect(() => {
    const userBrandPermissionsEffect = (userAccount, userPermissions) => {
      if (!userPermissions) return;
      // console.trace('userPermissions', userPermissions);
      // console.trace(JSON.stringify(userPermissions, null, 2));
      const brandPermission = userPermissions.find(p => p.model === 'Brand');
      if (!user.brand) {
        // setUser(user => ({
        //   ...user,
        //   brandKey: brandPermission?.model_id,
        // }));
      }
      if (
        userAccount?.status.indexOf('onboarding') < 0 &&
        userPermissions &&
        brand_model_id
      ) {
        const matchingPermissions = userPermissions.filter(p => {
          return (
            p.model === 'Brand' &&
            p.model_id === parseInt(brand_model_id) &&
            accessControl &&
            accessControl.includes(p.type)
          );
        });
        // console.log('userPermissions', userPermissions);
        // console.log('accessControl', accessControl);
        // console.log(location.pathname, ' matchingPermissions ', matchingPermissions);
        if (matchingPermissions.length === 0) {
          // #TODO logrocket permissions needed message
          setDisplayToast({
            type: 'info',
            message:
              'Additional permissions required to access this view. You have been redirected to the brand dashboard.',
          });
          navigate('/brands');
        }
        // Here the user has matching permissions but we want to
        // check whether they have access to the collection or
        // product but in order to do so we need to have user references
        // populated to provide values to check against
        //
        // else if (){
        // if user does not have access to collection or product
        // redirect to /brand/${brand_model_id}
        // }
        else {
          const matchingPermissionTypes = matchingPermissions.map(p => p.type);
          // console.trace('setAccessGranted matching', matchingPermissionTypes);
          setAccessGranted(prevAccessGranted =>
            prevAccessGranted
              ? prevAccessGranted.concat(matchingPermissionTypes)
              : matchingPermissionTypes
          );
        }
      } else if (userPermissions) {
        // console.trace(
        //   'setAccessGranted userPer brandPer',
        //   userPermissions,
        //   brandPermission
        // );
        setAccessGranted(prevAccessGranted => [
          ...prevAccessGranted,
          brandPermission?.type,
        ]);
      }
    };
    userBrandPermissionsEffect(user?.account, user?.permissions);
  }, [
    user?.account,
    user?.permissions,
    user?.brand,
    location.pathname,
    brand_model_id,
    accessControl,
  ]);

  React.useEffect(() => {
    setUser(prevUser => {
      // console.log('compare prev permissions', prevUser.permissions);
      // console.log('compare effect permissions', permissions);
      return {
        ...prevUser,
        permissions: !prevUser?.permissions
          ? permissions
          : unionBy(prevUser?.permissions, permissions, 'key'),
      };
    });
  }, [permissions]);

  const [dashboardNavItems, setDashboardNavItems] = React.useState([
    {
      title: 'Company Profile',
      to: `/brand/${user.brand?.key}/edit`,
      activeBtn: false,
      visible: false,
    },
    {
      title: 'Personal Profile',
      to: `/settings/profile`,
      activeBtn: false,
    },
    {
      title: 'Manage Team',
      to: `/brand/${user.brand?.key}/team`,
      activeBtn: true,
      visible: false,
    },
    {
      title: 'features preferences',
      activeBtn: false,
      visible: false,
    },
    {
      title: 'security & privacy',
      to: `/settings/security`,
      activeBtn: false,
      visible: false,
    },
    {
      title: 'branding',
      to: `/brand/${user.brand?.key}/customization`,
      activeBtn: true,
      visible: false,
    },
  ]);

  React.useEffect(() => {
    const accessGrantedEffect = accessGrantedVal => {
      if (!accessGrantedVal) return;
      setDashboardNavItems([
        {
          title: 'Company Profile',
          to: `/brand/${user.brand?.key}/edit`,
          activeBtn: location.pathname.indexOf('/edit') > 0,
          visible: isSuperset(new Set(['owner', 'editor']), new Set(accessGrantedVal)),
        },
        {
          title: 'Personal Profile',
          to: `/settings/profile`,
          activeBtn: location.pathname.indexOf('/settings/profile') === 0,
          visible: isSuperset(
            new Set(['owner', 'editor', 'read']),
            new Set(accessGrantedVal)
          ),
        },
        {
          title: 'Manage Team',
          to: `/brand/${user.brand?.key}/team`,
          activeBtn: location.pathname.indexOf('/team') > 0,
          visible: isSuperset(new Set(accessGrantedVal), new Set(['owner'])),
        },
        {
          title: 'security & privacy',
          to: `/settings/security`,
          activeBtn: location.pathname.indexOf('/settings/security') === 0,
          visible: isSuperset(
            new Set(['owner', 'editor', 'read']),
            new Set(accessGrantedVal)
          ),
        },
        {
          title: 'branding',
          to: `/brand/${user.brand?.key}/customization`,
          activeBtn: location.pathname.indexOf('/customization') > 0,
          visible: isSuperset(
            new Set(['owner', 'editor', 'read']),
            new Set(accessGrantedVal)
          ),
        },
      ]);
    };
    accessGrantedEffect(accessGranted);
  }, [accessGranted, location.pathname, user?.brand]);

  const [hasNotifications, setHasNotifications] = useState(false);
  const [allUnreadStatus, setAllUnreadStatus] = React.useState(false);

  useEffect(() => {
    setHasNotifications(user?.account?.key && notifications?.length > 0);
  }, [notifications, user?.account?.key]);

  const [frameUISettings, setFrameUISettings] = React.useState({
    headerOnlyVisible: user?.account?.status.indexOf('onboarding') === 0,
    dashboardNavVisible: user?.account?.status.indexOf('onboarding') < 0,
  });

  React.useEffect(() => {
    setFrameUISettings({
      headerOnlyVisible: user?.account?.status.indexOf('onboarding') === 0,
      dashboardNavVisible:
        accessGranted &&
        (accessGranted.includes('owner') || accessGranted.includes('editor')) &&
        user?.account?.status.indexOf('onboarding') < 0,
    });
  }, [user.account, accessGranted, user?.account?.status]);

  const { dispatch, state } = useFrameUI();

  React.useEffect(() => {
    const filterBarEffect = () => {
      dispatch({
        type: FrameUIActionsTypes.FILTER_BAR_VISIBLE,
        payload: filterBarVisible,
      });
      dispatch({
        type: FrameUIActionsTypes.DASHBOARD_NAV_VISIBLE,
        payload: dashboardNavVisible,
      });
      dispatch({
        type: FrameUIActionsTypes.CHATBAR_RESET,
      });
      return () => {
        dispatch({
          type: FrameUIActionsTypes.FILTER_BAR_VISIBLE,
          payload: false,
        });
        dispatch({
          type: FrameUIActionsTypes.DASHBOARD_NAV_VISIBLE,
          payload: false,
        });
      };
    };
    return filterBarEffect();
  }, [dashboardNavVisible, filterBarVisible]);

  React.useEffect(() => {
    const userLoadingEffect = () => {
      if (!user.loading && !user.idToken && !user.userAuth && !user.account) {
        if (location.pathname !== '/') {
          navigate(`/${location.search}`);
        }
      }
    };
    userLoadingEffect();
  }, [user.loading, location.pathname]);

  const isSettings = useLocationIsSettings();
  return (
    <>
      {user.account && ((brand_model_id && user.brand) || !brand_model_id) && (
        <Frame
          {...props}
          backgroundColor='#f9f9f9'
          fullwidth={fullwidth}
          noPadding={noPadding}
          profile_picture={user?.account?.assets?.profile_picture}
          brand_logo={
            (brand_model_id || isSettings) && user?.account && user?.brand?.assets?.logo
          }
          accessGranted={accessGranted}
          pageTitle={state.locationBar.title}
          backLinkTo={state.locationBar.backLinkTo}
          headerOnlyVisible={frameUISettings.headerOnlyVisible}
          dashboardNavVisible={frameUISettings.dashboardNavVisible}
          dashboardNavItems={dashboardNavItems}
          hasNotifications={hasNotifications}
          notifications={notifications}
          clearNotifications={async () => {
            await postNotificationMethodMarkReadAll(idToken);
            mutateNotifications();
          }}
          clearNotification={async clearTarget => {
            await postNotificationMethodMarkRead(idToken, {
              targetId: clearTarget.key,
            });
            mutateNotifications();
          }}
          restoreNotifications={async () => {
            await postNotificationMethodMarkUnreadAll(idToken);
            mutateNotifications();
          }}
          allUnreadStatus={allUnreadStatus}
          setAllUnreadStatus={setAllUnreadStatus}
        >
          <ConfirmDialog open={openDialog} setOpen={setOpenDialog} {...dialogProps} />
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={ErrorResetHandler}
            onError={ErrorHandler}
          >
            {AppModal &&
              openAppModal &&
              React.cloneElement(AppModal, {
                ...appModalProps,
                open: openAppModal,
              })}
            <main className=''>
              {!accessGranted && <LoadingAlert />}
              {accessGranted && user.account?.status.indexOf('onboarding') === 0 && (
                <SettingsContainer {...props} accessGranted={accessGranted} />
              )}
              {accessGranted && user.account?.status.indexOf('onboarding') !== 0 && (
                <Component {...props} accessGranted={accessGranted} />
              )}
            </main>
          </ErrorBoundary>
        </Frame>
      )}
    </>
  );
};

export default App;
