import React, { useEffect, useState, useRef } from 'react';
import { Link } from '@reach/router';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import classNames from 'classnames';
import './StyleSampleReviewHeader.scss';
import { FrameUIActionsTypes, useFrameUI } from 'providers/FrameUIProvider';
import ConversationIcon from 'assets/icons/icon-conversation.svg';
import BackIcon from 'assets/icons/exit.svg';
import CompareIcon from 'assets/icons/icon-compare.svg';
import MarkupIcon from 'assets/icons/annotation_icon.svg';
import ExpandIcon from 'assets/icons/icon-expand.svg';
import CloseIcon from 'assets/icons/close.svg';
import PrevIcon from 'assets/icons/arrow-prev.svg';
import 'components/VirtualAtelier/Uploads/AtelierModelUploads/AtelierModel3DAnnotation.scss';
import AtelierModel3DViewer from 'components/VirtualAtelier/Uploads/AtelierModelUploads/AtelierModel3DViewer';

export interface StyleSampleReviewHeader {
  brand_model_id: string;
  style_model_id: string;
  asset: any;
  imageUrlZoomed: { src: string; width: number; height: number };
  style: any;
  reference: any;
  heading: {
    label: string;
    link?: string;
  };
  buttonActionHandler?: (arg?) => void;
  referenceImagesToggle: boolean;
  setReferenceImagesToggle: (arg?) => void;
  openAnnotationModal: () => void;
  onMessagesOpen: (arg?) => void;
  slideIndex: any;
  slides: any[];
}

const StyleSampleReviewHeader: React.FC<StyleSampleReviewHeader> = props => {
  const {
    brand_model_id,
    style_model_id,
    imageUrlZoomed,
    style,
    reference,
    referenceImagesToggle,
    setReferenceImagesToggle,
    openAnnotationModal,
    onMessagesOpen,
    heading: { label, link },
    slideIndex,
    slides,
  } = props;

  const { state, dispatch } = useFrameUI();
  const [currentImage, setCurrentImage] = useState(slideIndex);

  useEffect(() => {
    if (state.chatbar?.props?.model?.key !== reference?.key) {
      // dispatch({ type: FrameUIActionsTypes.CHATBAR_RESET });
    } else {
      console.log(
        'match  frameUI',
        state.chatbar?.props?.model?.key,
        'reference',
        reference?.key
      );
      dispatch({ type: FrameUIActionsTypes.CHATBAR_LOADING, payload: false });
    }
  }, [reference?.key, state.chatbar?.props?.model?.key]);

  const [lightboxOpen, setLightboxOpen] = useState(false);
  const lightboxRef = useRef<HTMLDivElement>();

  return (
    <div>
      <div className='style-sample-review-header'>
        <div className='style-sample-review-header__wrap'>
          <div className='style-sample-review-header__left-aligned'>
            <Link to={`/brand/${brand_model_id}/style/${style_model_id}`}>
              <img
                className='style-sample-review-header__back'
                src={BackIcon}
                alt='Back'
              />
            </Link>
            <div className='style-sample-review-header__heading'>
              <span className='style-sample-review-header__title uppercase'>
                Virtual Sample
              </span>{' '}
              {link ? <Link to={link}>{label}</Link> : label}
            </div>
          </div>
          <div className='style-sample-review-header__right-aligned'>
            {/* <div
              className={classNames('button-dashboard  btn-fs-large', {
                'color-gray-bg': referenceImagesToggle,
              })}
              onClick={() => setReferenceImagesToggle(prev => !prev)}
            >
              <img className='style-sample-review-header__img-icon' src={CompareIcon} />
              <div className='tooltip tooltip--black'>
                <span className='tooltip__txt'>Reference Image</span>
              </div>
            </div> */}

            <div
              className='button-dashboard  btn-fs-large '
              onClick={() => {
                setCurrentImage(slideIndex);
                setLightboxOpen(!lightboxOpen);
              }}
            >
              <img
                className='style-sample-review-header__img-icon'
                src={ExpandIcon}
                alt='Plus'
              />
              <div className='tooltip tooltip--black'>
                <span className='tooltip__txt'>Expand</span>
              </div>
            </div>
            <div
              className='button-dashboard  btn-fs-large '
              onClick={() => openAnnotationModal()}
              >
              <img
                className='style-sample-review-header__img-icon'
                src={MarkupIcon}
                alt='Plus'
              />
              <div className='tooltip tooltip--black'>
                <span className='tooltip__txt'>Markup</span>
              </div>
            </div>
            <div
              className='button-dashboard btn-fs-large '
              onClick={() => onMessagesOpen(style)}
            >
              <img
                className='style-sample-review-header__img-icon'
                src={ConversationIcon}
              />
              <div className='tooltip tooltip--black' style={{ left: '-3rem' }}>
                <span className='tooltip__txt'>Comment</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CSSTransition classNames='lightbox' timeout={200} in={lightboxOpen} unmountOnExit>
        <div className='product-listing-dashboard__light-box'>
          <div className='product-listing-dashboard__lightbox-header'>
            {`${style?.name} Sample: ${style?.number}`}
            <div className='product-listing-dashboard__lightbox-buttons-wrap'>
              <div className='product-listing-dashboard__lightbox-arrows'>
                <img
                  className='product-listing-dashboard__main-prev'
                  src={PrevIcon}
                  alt='Previous'
                  onClick={() =>
                    setCurrentImage(
                      currentImage === 0 ? slides.length - 1 : currentImage - 1
                    )
                  }
                />
                <img
                  className='product-listing-dashboard__main-next'
                  src={PrevIcon}
                  alt='Next'
                  onClick={() =>
                    setCurrentImage(
                      currentImage === slides.length - 1 ? 0 : currentImage + 1
                    )
                  }
                />
              </div>
              <img
                className='cursor-pointer'
                src={CloseIcon}
                alt='Close'
                onClick={() => {
                  setCurrentImage(slideIndex);
                  setLightboxOpen(!lightboxOpen);
                }}
              />
            </div>
          </div>
          <div className='product-listing-dashboard__lightbox-inner' ref={lightboxRef}>
            <TransitionGroup component={null}>
              <CSSTransition classNames='fade-in' timeout={200} key={style?.name}>
                <TransformWrapper
                  initialScale={0.9}
                  centerOnInit={true}
                  minScale={0.2}
                  limitToBounds={false}
                  wheel={['gltf', 'glb', 'model'].includes(slides?.[currentImage]?.type) ? { disabled: true } : {
                    step: 0.1,
                  }}
                  pinch={['gltf', 'glb', 'model'].includes(slides?.[currentImage]?.type) && { disabled: true}}
                  panning={['gltf', 'glb', 'model'].includes(slides?.[currentImage]?.type) && { disabled: true}}
                  doubleClick={{
                    mode: 'reset',
                  }}
                  initialPositionX={
                    lightboxRef?.current?.offsetWidth / 2 - imageUrlZoomed?.width / 2
                  }
                  initialPositionY={0}
                >
                  <TransformComponent>
                  {!['gltf', 'glb', 'model'].includes(slides?.[currentImage]?.type) ? (
                    <img
                      src={slides?.[currentImage]?.assetUrl || imageUrlZoomed?.src}
                      alt={style.name}
                    />
                  ) : (
                    <div className='tile__player' style={{ position: 'unset', height: '1000px', width: '1000px' }}>
                      <AtelierModel3DViewer url={slides?.[currentImage]?.assetUrl} name={slides?.[currentImage]?.name} />
                    </div>
                  )}
                  </TransformComponent>
                </TransformWrapper>
              </CSSTransition>
            </TransitionGroup>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default StyleSampleReviewHeader;
