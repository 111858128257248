import React, { useEffect, useState } from 'react';
import { useLocationSearchParam } from 'hooks/useLocation';
import { navigate } from '@reach/router';

const UserManagement = () => {
  const mode = useLocationSearchParam('mode');
  const oobCode = useLocationSearchParam('oobCode');
  const continueUrl = useLocationSearchParam('continueUrl');
  const [invalidLink, setInvalidLink] = useState(false);

  const getProjectType = () => {
    if (!continueUrl) return '';
    const url = new URL(continueUrl);
    const search = new URLSearchParams(url.search);
    return '&project=' + search.get('project');
  };

  const getIntent = () => {
    if (!continueUrl) return '';
    const url = new URL(continueUrl);
    const search = new URLSearchParams(url.search);
    return '&intent=' + search.get('intent');
  };

  useEffect(() => {
    const pageEffect = () => {
      if (mode === 'resetPassword') {
        navigate(`/reset-password/?code=${oobCode}${getProjectType()}`);
      } else if (mode === 'verifyEmail' || mode == 'verifyAndChangeEmail') {
        navigate(`/verify-email/?code=${oobCode}${getProjectType()}${getIntent()}`);
      } else {
        setInvalidLink(true);
      }
    };
    pageEffect();
  }, [mode, oobCode]);

  return (
    <>
      {invalidLink && (
        <div className='change-password'>
          <div className='container'>
            <div className='change-password__success-wrap'>
              <h3 className='change-password__success-txt pb-2'>Invalid Link!</h3>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserManagement;
