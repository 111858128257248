import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useLocationSearchParam } from 'hooks/useLocation';
import { navigate } from '@reach/router';
import { NotificationsContext } from 'providers/contexts';
import firebase from 'firebase/app';
import AuthNavigation from 'components/Authentication/AuthNavigation/AuthNavigation';

const VerifyEmail = () => {
  const oobCode = useLocationSearchParam('code');
  const project = useLocationSearchParam('project');
  const intent = useLocationSearchParam('intent');
  const { setDisplayToast } = useContext(NotificationsContext);
  const [invalidLink, setInvalidLink] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);

  const getLoginPathFromIntent = () => {
    if (intent === 'emailUpdateForMfa') {
      return '/profile';
    }
    return '/';
  };

  const getLoginLink = useCallback(() => {
    if (project === 'mm-client-dash') {
      return process.env.REACT_APP_PUBLIC_URL_clientDash + getLoginPathFromIntent();
    }
    return '/';
  }, [project]);

  useEffect(() => {
    if (oobCode) {
      const verifyEmail = () => {
        const auth = firebase.auth();
        auth
          .applyActionCode(oobCode)
          .then(verified => {
            console.log('verified', verified);
            setEmailVerified(true);
            setDisplayToast({ type: 'success', message: 'Email Verified' });
            if (project !== 'mm-client-dash') navigate('/');
          })
          .catch(error => {
            setDisplayToast({ type: 'error', message: error.message });
            setEmailVerified(error.message);
          });
      };
      verifyEmail();
    } else {
      setInvalidLink(true);
    }
  }, [oobCode]);

  return (
    <>
      {invalidLink && (
        <div className='change-password'>
          <div className='container'>
            <div className='change-password__success-wrap'>
              <h3 className='change-password__success-txt pb-2'>Invalid Link!</h3>
            </div>
          </div>
        </div>
      )}
      {project === 'mm-client-dash' && <AuthNavigation loginLink={getLoginLink()} />}

      {project === 'mm-client-dash' && (
        <div className='change-password'>
          <div className='container'>
            <div className='change-password__success-wrap'>
              <h3 className='change-password__success-txt pb-2'>
                {emailVerified === 'success' && (
                  <>
                    <span>Email verification successful. </span>

                    {intent === 'emailUpdateForMfa' && (
                      <div>
                        <a href={getLoginLink()} className='login-form__link'>
                          Go back to Client Dashboard Profile
                        </a>
                      </div>
                    )}
                  </>
                )}
                {emailVerified && emailVerified !== 'success' && (
                  <span>Email verification error: {emailVerified}</span>
                )}
              </h3>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VerifyEmail;
