import React, { useEffect, useContext, useCallback, useMemo } from 'react';
import useSWR from 'swr';
import { mmAPI } from 'services/Api';
import { UserContext } from 'providers/contexts';
import { FrameUIActionsTypes, useFrameUI } from 'providers/FrameUIProvider';
import useTeam from 'hooks/useTeam';
import { ModalsContext } from 'providers/ModalsProvider';
import ModelAccessModal from 'components/Dashboard/Collections/CollectionAccessModal/ModelAccessModal';
import { ModelTeamContainer } from './ModelTeamContainer';
import { TeamContainer } from './TeamContainer';
import Breadcrumbs from 'components/Common/Breadcrumbs/Breadcrumbs';

const Team = ({ brand_model_id }) => {
  const { user } = useContext(UserContext);
  const { idToken } = user;
  const { dispatch } = useFrameUI();

  const { data: lines } = useSWR(
    ['/api/line/query/brand/', idToken, brand_model_id],
    (url, idToken, brand) => {
      return mmAPI(url, idToken, { brand, cursor: 0 });
    },
    {
      suspense: true,
    }
  );

  const { data: collections } = useSWR(
    ['/api/collection/query/brand/', idToken, brand_model_id],
    (url, idToken, brand) => {
      return mmAPI(url, idToken, { brand, cursor: 0 });
    },
    {
      suspense: true,
    }
  );

  const { setAppModal, setAppModalProps, setOpenAppModal, openAppModal } = useContext(
    ModalsContext
  );

  const {
    team,
    teamAccountsState,
    teamInvitesState,
    teamModelAssoc,
    setTeamModelAssoc,
  } = useTeam(idToken, {
    brand_model_id,
    matchMultiTeams: true,
  });

  const modal = (
    <ModelAccessModal
      heading={`${user?.brand?.name} Access`}
      open={openAppModal}
      setOpen={setOpenAppModal}
      allMembers={teamAccountsState}
      allInvites={teamInvitesState}
      model={user?.brand}
    />
  );
  const triggerModelAccessModal = useCallback(
    (model, jobType) => {
      console.log('triggerModelAccessModal', model, jobType);
      console.log('teamAccountsState', teamAccountsState);
      console.log('teamInvitesState', teamInvitesState);
      setTeamModelAssoc({ model, jobType });
      setAppModalProps(prev => ({
        ...prev,
        allMembers: teamAccountsState,
        allInvites: teamInvitesState,
        model,
        jobType,
      }));
      setAppModal(modal);
      setOpenAppModal(true);
    },
    [teamAccountsState, teamInvitesState]
  );

  useEffect(() => {
    const appModalPropsEffect = () => {
      if (teamModelAssoc?.model && teamModelAssoc?.jobType)
        setAppModalProps(prev => ({
          ...prev,
          heading: `${teamModelAssoc?.model?.name} ${teamModelAssoc?.jobType} Access`,
          allMembers: teamAccountsState,
          allInvites: teamInvitesState,
          model: teamModelAssoc?.model,
        }));
    };
    appModalPropsEffect();
  }, [team, teamAccountsState, teamInvitesState, user?.brand, teamModelAssoc]);

  useEffect(() => {
    const filterBarEffect = () => {
      dispatch({ type: FrameUIActionsTypes.FILTER_BAR_VISIBLE, payload: false });
      return () => {
        dispatch({ type: FrameUIActionsTypes.FILTER_BAR_RESET });
      };
    };
    return filterBarEffect();
  }, []);

  const pages = [
    { title: 'Settings', link: `/settings` },
    { title: 'Manage Team', link: `/team` },
  ];

  return (
    <>
      <div className='TeamView'>
        <Breadcrumbs pages={pages} />
        <div className='TeamTables'>
          <TeamContainer
            viewMode={'MANAGE'}
            key={brand_model_id}
            lines={lines}
            collections={collections}
            brand_model_id={brand_model_id}
          />
        </div>

        <div className='TeamView'>
          <div className='TeamTables'>
            <div className='collections-list'>
              <div className='collections-list__heading-item heading'>
                <div className='manage-team__header'>
                  <h2 className='manage-team__headline'>Brand Members</h2>
                </div>
              </div>
              {user?.brand && (
                <ModelTeamContainer
                  key={user?.brand?.key}
                  model={user?.brand}
                  triggerModelAccessModal={triggerModelAccessModal}
                />
              )}
              <div className='collections-list__heading-item heading'>
                <div className='manage-team__header'>
                  <h2 className='manage-team__headline'>Members by Line</h2>
                </div>
              </div>

              {lines &&
                lines.map(line => (
                  <ModelTeamContainer
                    key={line.key}
                    model={line}
                    triggerModelAccessModal={triggerModelAccessModal}
                  />
                ))}

              <div className='collections-list__heading-item heading'>
                <div className='manage-team__header'>
                  <h2 className='manage-team__headline'>Members by Collection</h2>
                </div>
              </div>

              {collections &&
                collections.map(collection => (
                  <ModelTeamContainer
                    key={collection.key}
                    model={collection}
                    triggerModelAccessModal={triggerModelAccessModal}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Team;
