import React from 'react';
import { Link, navigate } from '@reach/router';
import { Tween } from 'react-gsap';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import './Frame.scss';
import FilterBar from 'components/Dashboard/FilterBar/FilterBar';
import DashboardNav, {
  IDashboardNavItems,
} from 'components/Dashboard/DashboardNav/DashboardNav';
import { IProfileSettings } from 'models/Dashboard/IProfileSettings';
import useClickAway from 'hooks/useClickAway';
import NotificationPopUp from 'components/Dashboard/NotificationPopUp/NotificationPopUp';
import Profile from 'components/Dashboard/Profile/ProfileAvatar';
import { INotification } from 'models/Dashboard/INotification';
import { IPopUpOptions } from 'models/Dashboard/IPopUpOptions';
import LiveChat, { IContacts } from 'components/Common/LiveChat/LiveChat';
import { Toast } from 'components';
import LogoLocal from 'assets/images/mm_logo_white.png';
import { UserContext } from 'providers/UserProvider';
import { FrameUIActionsTypes, useFrameUI } from 'providers/FrameUIProvider';
import SearchIcon from './SearchIcon';
import NotificationIcon from './NotificationIcon';
import MenuIcon from './MenuIcon';
import SidebarInfo from 'components/Dashboard/SidebarInfo/SidebarInfo';
import BackIcon from './BackIcon';
import { auth } from '../../../firebase.js';
import { ModalsContext } from 'providers/ModalsProvider';
import useSWR from 'swr';
import { mmAPI, setAuthToken, APIWithAuthToken } from 'services/Api';
import useMessages from 'hooks/useMessages';
import useAccounts from 'hooks/useAccounts';
import { firebaseStorageUrl } from 'providers/UploadProvider';
import { useLocationIsSettings, useIsBrandCustomSettings } from 'hooks/useLocation';
import LogRocket from 'logrocket';
import ProductInfoSideBar from 'components/Dashboard/ProductsList/ProductInfoSideBar';

export interface ISidebarHeader {
  item: string;
  to?: string;
  disabled?: boolean;
  key: string;
}

export interface ISidebarFooter {
  item: string;
  to?: string;
}

export interface IFrame {
  backgroundColor?: string;
  fullwidth?: boolean;
  filterBarVisible?: boolean;
  popUpOptions?: IPopUpOptions[];
  profileSettings?: IProfileSettings[];
  dashboardNavVisible?: boolean;
  dashboardNavItems?: IDashboardNavItems[];
  headerOnlyVisible?: boolean;
  noPadding?: boolean;
  hasNotifications?: boolean;
  notifications?: INotification[];
  clearNotifications?: () => void;
  clearNotification?: (notification: INotification) => void;
  restoreNotifications?: () => void;
  allUnreadStatus: boolean;
  setAllUnreadStatus: (status: boolean) => void;
  accessGranted?: any;
  brand_model_id?: number;
  footer?: React.ReactNode;
  contacts?: IContacts[];
  sidebarFooter?: ISidebarFooter[];
  profile_picture?: any;
  brand_logo?: any;
  pageTitle?: string;
  backLinkTo?: string | (() => void);
  numberOfMessages?: number;
}
const Frame: React.FC<IFrame> = props => {
  const {
    fullwidth,
    backgroundColor,
    filterBarVisible,
    popUpOptions,
    dashboardNavVisible,
    dashboardNavItems,
    headerOnlyVisible,
    noPadding,
    hasNotifications,
    notifications,
    clearNotifications,
    clearNotification,
    restoreNotifications,
    allUnreadStatus,
    setAllUnreadStatus,
    footer,
    brand_logo,
    profile_picture,
    pageTitle,
    backLinkTo,
    accessGranted,
    brand_model_id,
    numberOfMessages,
  } = props;

  const { user } = React.useContext(UserContext);
  const { idToken } = user;
  const { state, dispatch } = useFrameUI();

  const nodeNotifications = React.useRef();
  const nodeSidebar = React.useRef();
  const [memberJoined, setMemberJoined] = React.useState(false);
  const [openNotifications, setOpenNotifications] = React.useState(false);
  const [openSidebar, setOpenSidebar] = React.useState(false);
  const triggerNotifications = React.useRef();
  useClickAway(nodeNotifications, setOpenNotifications, triggerNotifications);
  const triggerSidebar = React.useRef();
  useClickAway(nodeSidebar, setOpenSidebar, triggerSidebar);

  const { setOpenDialog, setDialogProps } = React.useContext(ModalsContext);

  const sidebarHeaderDefaults = brand => ({
    PRODUCT: {
      disabled: !brand,
      key: 'product',
      item: (
        <Link to={`/brand/${brand_model_id || user?.brand?.key}/products`}>Product</Link>
      ),
    },
    DATA: {
      disabled: !brand,
      key: 'data',
      item: (
        <Link to={`/brand/${brand_model_id || user?.brand?.key}/products/upload`}>
          Product Data
        </Link>
      ),
    },
    ASSET_LIBRARY: {
      key: 'asset-library',
      disabled: !brand,
      item: (
        <Link to={`/brand/${brand_model_id || user?.brand?.key}/assets_library`}>
          Asset Library
        </Link>
      ),
    },
    ATELIER: {
      disabled: !brand,
      key: 'atelier',
      item: (
        <Link to={`/brand/${brand_model_id || user?.brand?.key}/atelier`}>
          Virtual Atelier
        </Link>
      ),
    },
    NOTIFICATIONS: {
      disabled: !brand,
      key: 'notifications',
      item: (
        <Link to={`/brand/${brand_model_id || user?.brand?.key}/notifications`}>
          Notifications
        </Link>
      ),
    },
    CONTACT: {
      disabled: !brand,
      key: 'contact',
      item: (
        <Link to={`/brand/${brand_model_id || user?.brand?.key}/contacts`}>Contact</Link>
      ),
    },
  });

  const [sidebarHeader, setSidebarHeader] = React.useState([]);
  React.useEffect(() => {
    const userAccountEffect = (userJob, userBrand, accessGrantedVal) => {
      const sidebarHeaderVals = sidebarHeaderDefaults(userBrand);
      if (userJob === 'admin' || accessGrantedVal?.includes('owner')) {
        setSidebarHeader(Object.values(sidebarHeaderVals));
      }
      if (['guest'].includes(userJob)) {
        setSidebarHeader([
          sidebarHeaderVals.PRODUCT,
          sidebarHeaderVals.ATELIER,
          sidebarHeaderVals.ASSET_LIBRARY,
          sidebarHeaderVals.NOTIFICATIONS,
          sidebarHeaderVals.CONTACT,
        ]);
      }
      if (['editor', 'designer'].includes(userJob)) {
        setSidebarHeader([
          sidebarHeaderVals.PRODUCT,
          sidebarHeaderVals.DATA,
          sidebarHeaderVals.ATELIER,
          sidebarHeaderVals.ASSET_LIBRARY,
          sidebarHeaderVals.NOTIFICATIONS,
          sidebarHeaderVals.CONTACT,
        ]);
      }
      if (['marketing'].includes(userJob)) {
        setSidebarHeader([
          sidebarHeaderVals.PRODUCT,
          sidebarHeaderVals.ATELIER,
          sidebarHeaderVals.ASSET_LIBRARY,
          sidebarHeaderVals.NOTIFICATIONS,
          sidebarHeaderVals.CONTACT,
        ]);
      }
      if (userJob === 'vsm') {
        setSidebarHeader([
          sidebarHeaderVals.DATA,
          sidebarHeaderVals.ATELIER,
          sidebarHeaderVals.ASSET_LIBRARY,
          sidebarHeaderVals.NOTIFICATIONS,
          sidebarHeaderVals.CONTACT,
        ]);
      }
    };
    userAccountEffect(user?.account?.job_type, user?.brand, accessGranted);
  }, [user?.account?.job_type, accessGranted, user?.brand]);

  // const fontColor = brand_model_id && user?.brand?.template?.landing?.fontColor;
  const fontColor = (brand_logo && user?.brand?.template?.landing?.fontColor) || 'white';

  const isSettings = useLocationIsSettings();
  const accountsBrand = brand_model_id || (isSettings && user?.brand?.key);

  const { messages } = useMessages(idToken, {
    model: null,
    open: false,
    brand_model_id,
  });

  const { data: contactTeamAccounts, mutate: mutateContactTeamAccounts } = useSWR(
    user?.account?.key ? ['/api/team/query/accounts', idToken, user?.account?.key] : null,
    (url, idToken, account) => {
      return mmAPI(url, idToken, { account });
    },
    {
      suspense: true,
    }
  );
  const { data: contactTeams, mutate: mutateContactTeams } = useSWR(
    user?.account?.key ? ['/api/team/query/model', idToken, user?.account?.key] : null,
    (url, idToken, model_id) => {
      return mmAPI(url, idToken, { model: 'Account', model_id });
    },
    {
      suspense: true,
    }
  );

  const { accounts, getContactMatch, getContactMetadata } = useAccounts(idToken, {
    brand: accountsBrand,
  });

  const [contactChats, setContactChats] = React.useState(null);

  const brandCustom = useIsBrandCustomSettings();

  React.useEffect(() => {
    const contactTeamsEffect = async () => {
      const contactTeamChats = await Promise.all(
        contactTeamAccounts?.map(async t => {
          const contactMatch = getContactMatch(t);
          let profilePictureUrl = '';
          const accountCacheMatch = t.cache?.account?.find(
            a => a.key === contactMatch?.model_id
          );
          if (accountCacheMatch) {
            if (accountCacheMatch.assets?.profile_picture?.progress > 0)
              try {
                profilePictureUrl = await firebaseStorageUrl(
                  accountCacheMatch?.assets?.profile_picture?.path
                );
              } catch (err) {
                console.log(
                  'accountCacheMatch?.assets?.profile_picture',
                  accountCacheMatch?.email,
                  accountCacheMatch,
                  accountCacheMatch?.assets?.profile_picture
                );
                console.log(err);
              }
          }
          return {
            key: contactMatch?.model_id,
            team: t,
            firstName: getContactMetadata(contactMatch?.model_id)?.first,
            lastMessage: t?.cache?.message?.text ?? '...',
            profilePictureUrl,
            onlineUser: false,
          };
        })
      );
      const contactModelChats = await Promise.all(
        [...contactTeams]
          .filter(
            t =>
              t.grants.find(g => g.model_id === user?.account?.key) &&
              !contactTeamAccounts?.find(c => {
                return c.grants.find(g => g.model_id === t.model_id);
              })
          )
          .map(async contactTeam => {
            const teamContactModel = accounts?.find(a =>
              contactTeam.grants
                .filter(g => g.model === 'Account')
                .map(g => g.model_id)
                .includes(a.key)
            );
            let profilePictureUrl = '';
            if (teamContactModel) {
              try {
                profilePictureUrl = await firebaseStorageUrl(
                  teamContactModel?.assets?.profile_picture?.path
                );
              } catch (err) {
                console.log(
                  'teamContactModel?.assets?.profile_picture',
                  teamContactModel?.assets?.profile_picture
                );
                console.log(err);
              }
            }
            return {
              key: teamContactModel?.key,
              firstName: teamContactModel?.name,
              name: teamContactModel?.name,
              lastMessage: contactTeam.cache?.message?.text ?? '...',
              profilePictureUrl,
            };
          })
      );
      setContactChats([...contactTeamChats, ...contactModelChats]);
    };
    contactTeamsEffect();
  }, [contactTeamAccounts, contactTeams]);

  React.useEffect(() => {
    const messagesEffect = () => {
      mutateContactTeamAccounts();
      mutateContactTeams();
    };
    messagesEffect();
  }, [messages]);

  return (
    <div
      className='frame'
      style={{
        background:
          (brand_logo && user?.brand?.template?.landing?.backgroundColor) || 'black',
      }}
    >
      <div>
        <>
          <div onClick={() => setOpenSidebar(!openSidebar)} ref={triggerSidebar}>
            <MenuIcon fontColor={fontColor} openSidebar={openSidebar} />
          </div>

          {brand_logo && (
            <h6
              style={{ color: fontColor }}
              onClick={() => {
                if (state.chatbar?.props?.type !== 'direct') {
                  dispatch({ type: FrameUIActionsTypes.CHATBAR_RESET });
                }
                dispatch({ type: FrameUIActionsTypes.CHATBAR_OPEN, payload: true });
              }}
              className={'mm--h6 frame__chat'}
            >
              CHAT
              {numberOfMessages && (
                <div className='frame__chat-notifications'>
                  <span className='frame__chat-msg-num'>{numberOfMessages}</span>
                </div>
              )}
            </h6>
          )}
        </>

        <div
          className={classNames('frame__container', {
            frame__container__headerOnly: headerOnlyVisible,
          })}
        >
          <Toast />
          <div
            className='frame__nav frame__nav__headerOnly'
            style={{
              backgroundColor:
                (brand_logo && user?.brand?.template?.landing?.backgroundColor) ||
                'black',
            }}
          >
            <div className='frame__logo-wrap'>
              <Link to={`/brand/${user?.brand?.key}`}>
                <img
                  className='frame__img'
                  src={
                    (brand_logo && brand_logo.progress > 0 && brand_logo?.url) ||
                    LogoLocal
                  }
                  alt={user?.brand?.name}
                />
              </Link>
              {backLinkTo && typeof backLinkTo === 'string' && (
                <Link to={backLinkTo} className='frame__back-btn'>
                  <div className='frame__back-svg'>
                    <BackIcon fontColor={fontColor} />
                  </div>
                </Link>
              )}
              {backLinkTo && typeof backLinkTo === 'function' && (
                <div onClick={backLinkTo} className='frame__back-btn'>
                  <div className='frame__back-svg'>
                    <BackIcon fontColor={fontColor} />
                  </div>
                </div>
              )}
            </div>
            {pageTitle ? (
              <div className='frame__page-title' style={{ color: fontColor }}>
                {pageTitle}
              </div>
            ) : null}
            <div className='frame__logged-in'>
              {/* Hidden while not implemented */}
              <div style={{ visibility: 'hidden' }}><SearchIcon fontColor='black' /></div>
              <div className='frame__bell-wrap'>
                <span
                  className={
                    hasNotifications && !allUnreadStatus
                      ? 'frame__bell-dot-active'
                      : 'frame__bell-dot'
                  }
                ></span>
                <div
                  onClick={() => setOpenNotifications(!openNotifications)}
                  ref={triggerNotifications}
                >
                  <NotificationIcon fontColor={fontColor} />
                </div>
              </div>

              <div className='frame__notification' ref={nodeNotifications}>
                <CSSTransition
                  in={openNotifications}
                  classNames='appear'
                  timeout={200}
                  unmountOnExit
                >
                  <NotificationPopUp
                    notifications={notifications}
                    clearNotifications={clearNotifications}
                    clearNotification={clearNotification}
                    restoreNotifications={restoreNotifications}
                    allUnreadStatus={allUnreadStatus}
                    setAllUnreadStatus={setAllUnreadStatus}
                  />
                </CSSTransition>
              </div>
              <Profile profile_picture={profile_picture} />
            </div>
          </div>
          <div className='frame__body'>
            <CSSTransition
              in={openSidebar}
              classNames='appear'
              timeout={200}
              unmountOnExit
            >
              <div className='frame__sidebar' ref={nodeSidebar}>
                <div className='frame__sb-header'>
                  {sidebarHeader ? (
                    <Tween
                      from={{ y: '50px', autoAlpha: 0 }}
                      to={{ y: '0px', autoAlpha: 1 }}
                      stagger={0.4}
                      ease='cubic-bezier(0.5, 0, 0.75, 0)'
                      duration={0.5}
                    >
                      {sidebarHeader.map(({ item, key, disabled }) => (
                        <div
                          className={classNames('frame__sb-item', {
                            disabled: disabled,
                          })}
                          key={key}
                        >
                          {item}
                        </div>
                      ))}
                    </Tween>
                  ) : null}
                </div>
                <div className='frame__sb-footer'>
                  <Tween
                    from={{ y: '50px', autoAlpha: 0 }}
                    to={{ y: '0px', autoAlpha: 1 }}
                    stagger={0.4}
                    ease='cubic-bezier(0.12, 0, 0.39, 0)'
                    delay={0.6}
                    duration={0.5}
                  >
                    {user?.account && (
                      <div
                        className='frame__sb-item'
                        onClick={() => {
                          navigate('/settings/profile');
                        }}
                      >
                        Settings
                      </div>
                    )}
                    <div className='frame__sb-item'>
                      <a
                        target={'_blank'}
                        rel='noreferrer'
                        href={process.env.REACT_APP_KNOWLEDGE_BASEURL}
                      >
                        Resources
                      </a>
                    </div>
                    <div className='frame__sb-item'>
                      <div
                        onClick={() => {
                          setDialogProps({
                            dialogBody: 'Are you sure you want to logout?',
                            btnActionHandler: () => {
                              navigate('/');
                              auth.signOut();
                              setAuthToken(null);
                            },
                          });
                          setOpenDialog(true);
                        }}
                      >
                        Logout
                      </div>
                    </div>
                  </Tween>
                </div>
              </div>
            </CSSTransition>
            <div
              className={noPadding ? 'frame__inner-v2' : 'frame__inner'}
              style={{
                backgroundColor: backgroundColor,
                paddingBottom: brandCustom && 0,
              }}
            >
              {dashboardNavVisible && state.dashboardNav?.visible && (
                <DashboardNav darkMode={true} dashboardNavItems={dashboardNavItems} />
              )}
              {(filterBarVisible || state.filterBar?.visible) && (
                <FilterBar popUpOptions={popUpOptions} />
              )}
              <div className='frame__lchat'>
                {state.infoBar.visible && (
                  <ProductInfoSideBar
                    brand_model_id={brand_model_id || user?.brand?.key}
                  />
                )}
                <CSSTransition
                  in={state.chatbar?.open}
                  classNames='chat-animation'
                  timeout={200}
                  unmountOnExit
                  onExited={() => {
                    dispatch({
                      type: FrameUIActionsTypes.CHATBAR_RESET,
                    });
                  }}
                >
                  {(brand_model_id || user?.brand?.key) && (
                    <LiveChat
                      brand_model_id={brand_model_id || user?.brand?.key}
                      contacts={contactChats}
                      memberJoined={memberJoined}
                      setMemberJoined={setMemberJoined}
                      accounts={accounts}
                      onClose={() => {
                        dispatch({
                          type: FrameUIActionsTypes.CHATBAR_RESET,
                        });
                      }}
                    />
                  )}
                </CSSTransition>
                <CSSTransition
                  in={state.sidebar?.open}
                  classNames='slide-right-to-left'
                  timeout={300}
                  unmountOnExit
                  onExited={() => {
                    dispatch({ type: FrameUIActionsTypes.SIDEBAR_OPEN, payload: false });
                    state.sidebar?.props?.onClose?.();
                  }}
                >
                  <SidebarInfo
                    {...state.sidebar?.props}
                    loading={state.sidebar?.loading}
                    onClose={() => {
                      dispatch({
                        type: FrameUIActionsTypes.SIDEBAR_OPEN,
                        payload: false,
                      });
                    }}
                  />
                </CSSTransition>
              </div>
              {!fullwidth ? (
                <div className='container'>{props.children}</div>
              ) : (
                <>{props.children}</>
              )}
            </div>
          </div>
        </div>
        {footer}
      </div>
    </div>
  );
};

export default Frame;
