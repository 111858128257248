import React, { useEffect, useState } from 'react';
import { Link, navigate } from '@reach/router';
import classNames from 'classnames';

import '../../../Dashboard/InventoryTable/InventoryTable.scss';

import PopUp from 'components/Common/PopUp/PopUp';
import { IPopUpOptions } from 'models/Dashboard/IPopUpOptions';
import { ASSET_TYPES } from 'constants/assets';
import ManageIconGray from 'assets/icons/manage-icon-gray.svg';

export interface ICellData {
  col: IData[];
  key: any;
  data?: any;
}

export interface IData {
  cell?: string;
  imageUrl?: string;
  icon?: any;
  button?: string;
  color?: string;
  linkTo?: string;
}

export interface IHeaderGroups {
  header: string;
}

export interface IAssetList {
  headerGroups: IHeaderGroups[];
  showHeader?: boolean;
  cellData: ICellData[];
  popUpOptions: IPopUpOptions[];
  placeholder?: string;
  onClickRow?: (item: any) => void;
}

export interface IAssetListRow {
  headerGroups: IHeaderGroups[];
  rowData: ICellData;
  popUpOptions: IPopUpOptions[];
  onPopUpModal: (itemKey: any, ModalComponent: React.ReactElement) => void;
  onClickRow?: (item: any) => void;
}

const AssetList: React.FC<IAssetList> = props => {
  const {
    headerGroups,
    showHeader = true,
    cellData,
    popUpOptions,
    placeholder = 'No assets are available',
    onClickRow,
  } = props;
  const [selectedItem, setSelectedItem] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [ActiveModalComponent, setActiveModalComponent] = useState(null);

  const handlePopUpModal = (itemKey, ModalComponent) => {
    setSelectedItem(itemKey);
    setActiveModalComponent(ModalComponent);
    setModalOpen(true);
  };

  const [rows, setRows] = useState(cellData);

  useEffect(() => {
    setRows(cellData);
  }, [cellData]);

  return (
    <div>
      <table className='table'>
        {showHeader && (
          <thead>
            <tr>
              {headerGroups?.map(({ header }) => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
        )}

        <tbody>
          {cellData.length === 0 ? (
            <tr>
              <td>{placeholder}</td>
            </tr>
          ) : (
            rows.map(rowData => (
              <AssetListRow
                key={`row-${rowData.key}`}
                headerGroups={headerGroups}
                rowData={rowData}
                popUpOptions={popUpOptions}
                onPopUpModal={handlePopUpModal}
                onClickRow={onClickRow}
              />
            ))
          )}
        </tbody>
      </table>
      {ActiveModalComponent &&
        modalOpen &&
        React.cloneElement(ActiveModalComponent, {
          open: modalOpen,
          setOpen: setModalOpen,
          data: cellData.find(item => item.key === selectedItem)?.data,
        })}
    </div>
  );
};

const AssetListRow: React.FC<IAssetListRow> = props => {
  const { headerGroups, rowData, popUpOptions, onPopUpModal, onClickRow } = props;
  const [open, setOpen] = useState(false);
  const [iconAsset, setIcon] = useState('');
  const [loadIcons, setLoadIcons] = useState(true);

  useEffect(() => {
    const extension = rowData.data.ext;
    if (loadIcons && !ASSET_TYPES.image.extensions.includes(extension)) {
      loadIcon(extension);
    }
    return () => setLoadIcons(false);
  }, [loadIcons, rowData.data.ext]);

  const loadIcon = async (iconName: string) => {
    try {
      const importedIcon = await import(
        `assets/icons/${iconName.toLocaleUpperCase()}.svg`
      );
      setIcon(importedIcon.default);
    } catch (error) {
      const importedIcon = await import(`assets/icons/Icon-picture.svg`);
      setIcon(importedIcon.default);
    }
  };

  return (
    <tr id={rowData.key}>
      {rowData.col?.map(({ linkTo, cell, imageUrl, icon, button, color }, index) => (
        <td
          data-th={headerGroups[index].header}
          key={headerGroups[index].header}
          className={classNames({ link: !!linkTo })}
          onClick={() => linkTo && navigate(linkTo)}
        >
          {imageUrl && (
            <img
              style={{ height: '32px', width: '32px' }}
              className='table-img'
              src={iconAsset || imageUrl}
              alt='Asset'
              onClick={() => rowData.data.type === 'folder' && onClickRow?.(rowData.data)}
            />
          )}
          {icon && (
            <img
              style={{ height: '32px', width: '32px' }}
              className='table-img icon-folder'
              src={icon}
              alt='Asset'
              onClick={() => rowData.data.type === 'folder' && onClickRow?.(rowData.data)}
            />
          )}
          {button && (
            <button className={`button-badge color-${color}-bg`}>{button}</button>
          )}
          {cell}
        </td>
      ))}

      <td>
        <img
          className='manage-team__dots'
          onClick={() => setOpen(!open)}
          src={ManageIconGray}
          alt='Manage Icon'
        />

        <PopUp open={open} setOpen={setOpen}>
          {popUpOptions?.map(
            ({ selectItem, to, ModalComponent, onClick, download, disabled = false }) =>
              download ? (
                <li
                  style={disabled ? { pointerEvents: 'none', opacity: '0.5' } : {}}
                  className='pop-up__li'
                  key={selectItem}
                >
                  <a
                    href={rowData.data?.assetUrl || rowData.data?.original}
                    download={rowData.data?.assetUrl || rowData.data?.original}
                    target='_blank'
                    rel='noreferrer'
                    onClick={() => {
                      setOpen(false);
                      onClick?.(rowData.data);
                    }}
                  >
                    {selectItem}
                  </a>
                </li>
              ) : (
                <li
                  style={disabled ? { pointerEvents: 'none', opacity: '0.5' } : {}}
                  className='pop-up__li'
                  onClick={() => {
                    setOpen(false);
                    if (ModalComponent) {
                      onPopUpModal?.(rowData.key, ModalComponent);
                    } else if (onClick) {
                      onClick(rowData.data);
                    }
                  }}
                  key={selectItem}
                >
                  {to ? <Link to={to}>{selectItem}</Link> : selectItem}
                </li>
              )
          )}
        </PopUp>
      </td>
    </tr>
  );
};

export default AssetList;
