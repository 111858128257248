import axios from 'axios';
import { ERRORS } from '../constants';

const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL,
  headers: {
    common: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
    },
    // `transformResponse` allows changes to the response data to be made before
    // it is passed to then/catch
    // transformResponse: [function (data) {
    //   // Do whatever you want to transform the data
    //   console.log('transformResponse', data)
    //   return data;
    // }],
  },
});

// Add a response interceptor
AxiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    if (response.data.error) Promise.reject(response.data.error);
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    return Promise.reject(error);
  }
);
let authToken;

export const setAuthToken = token => {
  authToken = token;
};

const API = async options => {
  // message: 'AuthInvalidSession';
  // type: 'AuthInvalidSession';
  const response = await AxiosInstance(options);
  if (response.data.error?.type === ERRORS.AUTH_MM_API_INVALIDSESSION) {
    console.log('response.data.error', response.data.error);
    throw response.data.error;
  }
  return response;
};

export default API;

export const APIWithAuthToken = async options => {
  const { headers = {} } = options;
  options = { ...options, headers: { ...headers, Authorization: authToken } };
  return await AxiosInstance(options);
};

export const mmAPI = async (url, idToken, params, method = 'GET', body = {}) => {
  if (!authToken) return;
  const options = {
    url,
    method,
    params: {
      ...params,
    },
    body,
    headers: { Authorization: authToken },
  };

  const response = await API(options);
  if (response.data.error) throw response.data.error;
  return response.data.data;
};

export const inviteMethodParse = async token => {
  const options = {
    url: '/api/invite/method/parse',
    method: 'GET',
    params: {
      token,
    },
  };

  const response = await API(options);
  if (response.data.error) throw response.data.error;
  return response.data.data;
};

export const accountMethodSignup = async ({
  email,
  password,
  first,
  last,
  invite,
} = {}) => {
  const options = {
    url: `/api/account/method/signup/`,
    method: 'POST',
    data: {
      email,
      password,
      first,
      last,
      invite,
    },
  };

  const response = await API(options);
  return response;
};

export const setTeam = async (idToken, brandkey, teamName, accounts) => {
  const options = {
    method: 'POST',
    url: `/api/team/method/set_team_type_group?brand=${brandkey}&name=${teamName}&accounts=${accounts}`,
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const getBrandContacts = async brandKey => {
  const options = {
    url: `/api/team/method/brand_contacts?brand=${brandKey}`,
    method: 'GET',
  };

  const response = await APIWithAuthToken(options);
  return response;
};

export const editMessageModel = async (idToken, messageKey, params) => {
  const options = {
    url: `/api/message/model/${messageKey}`,
    method: 'PUT',
    params: {
      text: params,
    },
  };

  const response = await APIWithAuthToken(options);
  return response;
};

export const putAccountModel = async (idToken, accountKey, params) => {
  const options = {
    url: `/api/account/model/${accountKey}`,
    method: 'PUT',
    params,
  };

  const response = await APIWithAuthToken(options);
  return response;
};

export const addMemberToGroupChat = async (accountKey, teamKey) => {
  const options = {
    url: `/api/team/method/add_member`,
    method: 'GET',
    params: {
      team: teamKey,
      account: accountKey,
    },
  };

  const response = await APIWithAuthToken(options);
  return response;
};

export const removeMemberFromGroupChat = async (accountKey, teamKey) => {
  const options = {
    url: `/api/team/method/remove_member`,
    method: 'GET',
    params: {
      team: teamKey,
      account: accountKey,
    },
  };

  const response = await APIWithAuthToken(options);
  return response;
};

export const getAccountModel = async (idToken, accountKey) => {
  const options = {
    url: `/api/account/model/${accountKey}`,
    method: 'GET',
  };

  const response = await APIWithAuthToken(options);
  return response;
};

export const getPythonVersion = async () => {
  const options = {
    url: `/api/app/method/check_version`,
    method: 'GET',
  };

  const response = await APIWithAuthToken(options);
  return response;
};

export const getAccountQueryBrand = async (idToken, brand) => {
  const options = {
    url: `/api/account/query/brand/`,
    params: {
      brand,
    },
    method: 'GET',
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const apiSignup = async (
  idToken,
  { email, password, mfaPhoneNumber, fname, last_name } = {}
) => {
  const options = {
    url: `/api/account/method/signup/`,
    method: 'POST',
    params: {
      first: fname,
      last: last_name,
      email: email,
      password: password,
    },
  };

  const response = await APIWithAuthToken(options);
  return response;
};

export const accountMethodLogin = async ({ email, password, idToken } = {}) => {
  const options = {
    url: `/api/account/method/login`,
    method: 'POST',
    data: {
      email,
      password,
    },
    headers: {
      Authorization: idToken,
    },
  };

  const response = await API(options);
  return response;
};

export const postModel = async (idToken, model, params) => {
  const options = {
    url: `/api/${model}/model/`,
    method: 'POST',
    params,
  };

  const response = await APIWithAuthToken(options);
  return response;
};

export const putModel = async (idToken, model, model_id, params) => {
  const options = {
    url: `/api/${model}/model/${model_id}`,
    method: 'PUT',
    params,
  };

  const response = await APIWithAuthToken(options);
  return response;
};

export const deleteModel = async (idToken, model, modelKey, params) => {
  const options = {
    url: `/api/${model}/model/${modelKey}`,
    params,
    method: 'DELETE',
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const postMaterialModel = async (idToken, params) => {
  /**
   * &name=Material A
   * &brand={{brand}}
   * &styles={{style}}
   * One or more integers (its a list)
   * &colorway=blue
   * (the name of a Color that is listed in the Style's colors array (used for grouping Materials by top level Colors that the Style is available in))
   * &notes
   * &type
   * swatch, graphic, trim or hardware (MaterialType constant)
   * &colors
   * array of Color objects (with hex_codes etc)
   */
  const options = {
    url: `api/material/model/`,
    method: 'POST',
    params,
  };

  const response = await APIWithAuthToken(options);
  return response;
};

export const putStyleModel = async (idToken, styleKey, params) => {
  const options = {
    url: `/api/style/model/${styleKey}`,
    method: 'PUT',
    params,
  };

  const response = await APIWithAuthToken(options);
  return response;
};

export const postInviteModel = async (idToken, params) => {
  const options = {
    url: `/api/invite/model/`,
    method: 'POST',
    params,
  };

  const response = await APIWithAuthToken(options);
  return response;
};

export const postInviteMethodEmail = async (idToken, params) => {
  const options = {
    url: `/api/invite/method/email`,
    method: 'POST',
    params: {
      ...params,
      email_override:
        process.env.NODE_ENV === 'production'
          ? null
          : process.env.REACT_APP_INVITE_EMAIL_OVERRIDE,
    },
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const inviteQuerySender = async idToken => {
  const options = {
    url: `/api/invite/query/sender`,
    method: 'GET',
  };

  const response = await APIWithAuthToken(options);
  return response;
};

export const inviteQueryReceiver = async idToken => {
  const options = {
    url: `/api/invite/query/receiver`,
    method: 'GET',
  };

  const response = await APIWithAuthToken(options);
  return response;
};

export const appMethodParseInvite = async inviteToken => {
  const options = {
    url: `/api/app/method/parse_invite`,
    method: 'GET',
    params: {
      token: inviteToken,
    },
  };

  const response = await API(options);
  return response;
};

export const appMethodAcceptInvite = async inviteToken => {
  const options = {
    url: `/api/app/method/accept_invite`,
    method: 'GET',
    params: {
      token: inviteToken,
    },
  };

  const response = await APIWithAuthToken(options);
  return response;
};

export const apiBrandMethodCreateTestBrand = async (idToken, { name }) => {
  const options = {
    url: `/api/brand/method/create_test_brand`,
    params: {
      name,
    },
    method: 'POST',
  };

  const response = await APIWithAuthToken(options);
  return response;
};

export const postBrandModel = async (idToken, { name }) => {
  const options = {
    url: `/api/brand/model/`,
    params: {
      name,
    },
    method: 'POST',
  };

  const response = await APIWithAuthToken(options);
  return response;
};

export const putBrandModel = async (idToken, brand_model_id, validatedKeyVals) => {
  const options = {
    url: `/api/brand/model/${brand_model_id}`,
    method: 'PUT',
    params: {
      ...validatedKeyVals,
    },
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const getBrandModel = async (idToken, { key }) => {
  const options = {
    url: `/api/brand/model/${key}`,
    method: 'GET',
  };

  const response = await APIWithAuthToken(options);
  return response;
};

export const brandQueryAll = async idToken => {
  const options = {
    url: `/api/brand/query/all`,
    method: 'GET',
  };

  const response = await APIWithAuthToken(options);
  return response;
};

export const queryBrandsByUser = async idToken => {
  const options = {
    url: `/api/brand/query/user`,
    method: 'GET',
  };

  const response = await APIWithAuthToken(options);
  return response;
};

export const queryProductsByBrand = async (idToken, brand) => {
  const options = {
    url: `/api/product/query/brand`,
    params: {
      brand,
    },
    method: 'GET',
  };

  const response = await APIWithAuthToken(options);
  return response;
};

export const apiBrandModel = async (idToken, { name }) => {
  const options = {
    url: `/api/brand/model/`,
    method: 'POST',
    params: {
      name,
    },
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const apiMediaMethodBatch = async (idToken, { names, brand, preflight }) => {
  const options = {
    url: `/api/media/method/batch`,
    method: 'POST',
    params: {
      names,
      brand,
      preflight,
    },
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const putMediaModel = async (idToken, mediaKey, params) => {
  const options = {
    url: `/api/media/model/${mediaKey}`,
    method: 'PUT',
    params,
  };

  const response = await APIWithAuthToken(options);
  return response;
};

export const putMediaPositions = async (idToken, mediaKeys, mediaPositions) => {
  const options = {
    url: `/api/media/method/positions/?keys=${mediaKeys}&positions=${mediaPositions}`,
    method: 'POST',
  };

  const response = await APIWithAuthToken(options);
  return response;
};

export const deleteMediaModel = async (idToken, mediaKey, params) => {
  const options = {
    url: `/api/media/model/${mediaKey}`,
    method: 'DELETE',
    params,
  };

  const response = await APIWithAuthToken(options);
  return response;
};

export const deleteMessageModel = async (idToken, messageKey, params) => {
  const options = {
    url: `/api/message/model/${messageKey}`,
    method: 'DELETE',
    params,
  };

  const response = await APIWithAuthToken(options);
  return response;
};

export const postMessageModel = async (idToken, params) => {
  const options = {
    url: `/api/message/model/`,
    method: 'POST',
    data: params,
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const postReferenceModel = async (idToken, params) => {
  const options = {
    url: `/api/reference/model/`,
    method: 'POST',
    params,
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const updateReferenceModel = async (idToken, { referenceKey, params }) => {
  const options = {
    url: `/api/reference/model/${referenceKey}`,
    method: 'PUT',
    params,
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const referenceMethodClone = async (idToken, { params }) => {
  const options = {
    url: `/api/pragma/method/clone_ref/`,
    method: 'POST',
    params,
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const updateReferencePositions = async (idToken, params) => {
  const options = {
    url: `/api/reference/method/update_positions/?auth=${idToken}`,
    method: 'POST',
    params,
  };
  const response = await API(options);
  return response;
};

export const deleteReferenceModel = async (idToken, referenceKey, params) => {
  const options = {
    url: `/api/reference/model/${referenceKey}`,
    params,
    method: 'DELETE',
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const postMediaMethodAttach = async (
  idToken,
  { brand, media, preflight = false, style_number, shot, listingUniqueId, position }
) => {
  const options = {
    url: `/api/media/method/attach`,
    method: 'POST',
    params: {
      brand,
      media,
      preflight,
      style_number,
      shot,
      listingUniqueId,
      position,
    },
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const postMediaMethodDetach = async (
  idToken,
  { brand, media, preflight = false, name = null }
) => {
  const options = {
    url: `/api/media/method/detach`,
    method: 'POST',
    params: {
      brand,
      media,
      preflight,
      name,
    },
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const apiPostListingMethodBatch = async (
  idToken,
  {
    filename,
    formDataSpreadsheet,
    spreadsheet_columns = false,
    brand,
    preflight,
    addition,
  }
) => {
  const options = {
    url: `/api/listing/method/batch`,
    method: 'POST',
    params: {
      brand,
      preflight,
      file: true,
      spreadsheet_columns,
      addition,
      filename,
    },
    headers: {
      'Content-Type': `multipart/form-data; boundary=${formDataSpreadsheet._boundary}`,
    },
    data: formDataSpreadsheet,
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const apiPostListingMethodBatchString = async (
  idToken,
  { spreadsheet, spreadsheet_columns = false, brand, preflight }
) => {
  const options = {
    url: `/api/listing/method/batch`,
    method: 'POST',
    params: {
      brand,
      preflight,
      spreadsheet,
      spreadsheet_columns,
    },
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const updateListing = async (idToken, { listingKey, params }) => {
  const options = {
    url: `/api/listing/model/${listingKey}`,
    method: 'PUT',
    params: { ...params },
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const queryAccounts = async idToken => {
  const options = {
    url: `/api/account/query/all`,
    method: 'POST',
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const appMethodGetPermission = async (idToken, { model, model_id, grantee }) => {
  const options = {
    url: `/api/app/method/get_permission`,
    method: 'GET',
    params: {
      model,
      model_id,
      grantee,
    },
  };
  const response = await API(options);
  return response;
};

export const createUpdateLine = async (idToken, { name, description, brand }, action) => {
  const options = {
    url: `/api/line/model/`,
    method: action === 'update' ? 'PUT' : 'POST',
    params: {
      name,
      description,
      brand,
    },
  };
  const res = await APIWithAuthToken(options);
  return res;
};

export const deleteLine = async (idToken, lineKey) => {
  const options = {
    url: `/api/line/model/${lineKey}`,
    method: 'DELETE',
  };
  const res = await APIWithAuthToken(options);
  return res;
};

export const createUpdateCollection = async (
  idToken,
  { name, season, year, brand, line },
  action
) => {
  const options = {
    url: `/api/collection/model/`,
    method: action === 'update' ? 'PUT' : 'POST',
    params: {
      name,
      season,
      year,
      brand,
      line,
    },
  };
  const res = await APIWithAuthToken(options);
  return res;
};

export const deleteCollection = async (idToken, collectionKey) => {
  const options = {
    url: `/api/collection/model/${collectionKey}`,
    method: 'DELETE',
  };
  const res = await APIWithAuthToken(options);
  return res;
};

export const getLineQueryUser = async idToken => {
  const options = {
    url: `/api/line/query/user/`,
    method: 'GET',
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const getLineQueryBrand = async (idToken, brand) => {
  const options = {
    url: `/api/line/query/brand/`,
    params: {
      brand,
    },
    method: 'GET',
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const getAllLines = async idToken => {
  const options = {
    url: `/api/line/query/all`,
    method: 'GET',
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const getAllCollections = async idToken => {
  const options = {
    url: `/api/collection/query/all`,
    method: 'GET',
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const getCollectionQueryUser = async idToken => {
  const options = {
    url: `/api/collection/query/user/`,
    method: 'GET',
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const getCollectionQueryBrand = async (idToken, brand) => {
  const options = {
    url: `/api/collection/query/brand/`,
    method: 'GET',
    params: {
      brand,
    },
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const updateBrand = async (
  idToken,
  {
    brandKey,
    name = null,
    legal_name = null,
    year_established = null,
    address = null,
    contact = null,
    template = null,
  }
) => {
  const options = {
    url: `/api/brand/model/${brandKey}`,
    method: 'PUT',
    params: {
      name,
      legal_name,
      year_established,
      contact,
      address,
      template,
    },
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const updateAccount = async (
  idToken,
  {
    accountKey,
    title,
    country,
    employee_number,
    name,
    language,
    features,
    preferences,
    department,
    username,
    role,
    lines,
    contact_number,
    mobile_number,
    date_format,
    status,
    mfa,
  }
) => {
  const options = {
    url: `/api/account/model/${accountKey}`,
    method: 'PUT',
    params: {
      title,
      country,
      employee_number,
      name,
      language,
      features,
      preferences,
      department,
      username,
      role,
      lines,
      contact_number,
      mobile_number,
      date_format,
      status,
      mfa,
    },
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const getUserPermission = async idToken => {
  const options = {
    url: '/api/permission/query/grantee',
    method: 'GET',
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const getChatGroups = async (brand, team_type) => {
  const options = {
    url: '/api/team/method/get_account_teams',
    method: 'GET',
    params: {
      brand,
      type: team_type,
    },
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const apiDeleteInvitedTeamMember = async (idToken, invite_key, brand) => {
  const options = {
    url: `/api/invite/model/${invite_key}`,
    params: {
      brand,
    },
    method: 'DELETE',
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const deleteBrandMethodRemoveTeamMember = async (idToken, { brand, account }) => {
  const options = {
    url: `/api/brand/method/remove_team_member`,
    method: 'POST',
    params: {
      brand,
      account,
    },
  };

  const response = await APIWithAuthToken(options);
  return response;
};

export const deleteTeamMethodRemoveMember = async ({ team, account }) => {
  const options = {
    url: `/api/team/method/remove_member`,
    method: 'POST',
    params: {
      team,
      account,
    },
  };

  const response = await APIWithAuthToken(options);
  return response;
};

export const postTeamModel = async (idToken, params) => {
  const options = {
    url: `/api/team/model/`,
    method: 'POST',
    params,
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const RenameTeamModel = async (name, teamId) => {
  const options = {
    url: `/api/team/model/${teamId}`,
    method: 'PUT',
    params: {
      name: name,
    },
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const apiDeleteProduct = async (idToken, listing_key, params) => {
  const options = {
    url: `/api/listing/model/${listing_key}`,
    method: 'DELETE',
    params,
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const apiDeleteProducts = async (idToken, listing_keys) => {
  const options = {
    url: `/api/listing/method/remove`,
    method: 'POST',
    params: {
      keys: listing_keys.join(','),
    },
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const listingAPI = async (url, idToken, params, method = 'GET', body = {}) => {
  const options = {
    url,
    method,
    params,
    body,
  };

  const response = await APIWithAuthToken(options);
  if (response.data.error) throw response.data.error;
  return response.data;
};

export const getBrandCategories = async (idToken, brandKey) => {
  const options = {
    url: `api/category/query/brand`,
    method: 'GET',
    params: {
      brand: brandKey,
      cursor: 0,
    },
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const updateProductListing = async (idToken, listingModelId, params) => {
  const options = {
    url: `api/listing/model/${listingModelId}`,
    method: 'PUT',
    params,
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const filterProductListing = async (idToken, brand_model_id) => {
  const options = {
    url: `/api/listing/query/filter`,
    method: 'GET',
    params: {
      brand: brand_model_id,
    },
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const postBucketModel = async (idToken, brand, params) => {
  const options = {
    url: `/api/bucket/model/`,
    params: {
      brand,
      ...params,
    },
    method: 'POST',
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const getNotifications = async (idToken, account) => {
  const options = {
    url: `/api/notification/method/notifications_flat?account=${account}`,
    method: 'GET',
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const getNotificationsByScope = async (idToken, target) => {
  const options = {
    url: `/api/notification/method/notifications_by_scope?target=${target}`,
    method: 'GET',
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const postNotificationMethodMarkRead = async (idToken, params) => {
  const options = {
    url: `/api/notification/method/mark_read`,
    method: 'POST',
    params,
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const postNotificationMethodMarkReadAll = async (idToken, params) => {
  const options = {
    url: `/api/notification/method/mark_read_all`,
    method: 'POST',
    params,
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const postNotificationMethodMarkUnreadAll = async (idToken, params) => {
  const options = {
    url: `/api/notification/method/mark_unread_all`,
    method: 'POST',
    params,
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const getBucketQueryBrand = async (idToken, brand, params) => {
  const options = {
    url: `/api/bucket/query/brand`,
    params: {
      brand,
      ...params,
    },
    method: 'GET',
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const getBucketAssetFolder = async (idToken, bucket, url, params) => {
  const options = {
    url: `/api/bucket/method/${url}`,
    params: {
      bucket,
      ...params,
    },
    method: 'GET',
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const createBucketAssetFolder = async (idToken, bucket, url, params) => {
  const options = {
    url: `/api/bucket/method/${url}`,
    method: 'POST',
    params: {
      bucket,
      ...params,
    },
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const updateBucketAssetFolder = async (idToken, bucket, url, params) => {
  const options = {
    url: `/api/bucket/method/${url}`,
    method: 'POST',
    params: {
      bucket,
      ...params,
    },
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const updateBucketAssetFile = async (idToken, bucket, url, params) => {
  const options = {
    url: `/api/bucket/method/${url}`,
    method: 'POST',
    params: {
      bucket,
      ...params,
    },
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const deleteBucketAssetFolder = async (idToken, bucket, url, params) => {
  const options = {
    url: `/api/bucket/method/${url}`,
    method: 'POST',
    params: {
      bucket,
      ...params,
    },
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const deleteBucketAssetFile = async (idToken, bucket, url, params) => {
  const options = {
    url: `/api/bucket/method/${url}`,
    method: 'POST',
    params: {
      bucket,
      ...params,
    },
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const archiveBucketAssetFolder = async (idToken, bucket, url, params) => {
  const options = {
    url: `/api/bucket/method/${url}`,
    method: 'POST',
    params: {
      bucket,
      ...params,
    },
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const archiveBucketAssetFile = async (idToken, bucket, url, params) => {
  const options = {
    url: `/api/bucket/method/${url}`,
    method: 'POST',
    params: {
      bucket,
      ...params,
    },
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const unarchiveBucketAssetFolder = async (idToken, bucket, params) => {
  const options = {
    url: `/api/bucket/method/unarchive_folder`,
    method: 'POST',
    params: {
      bucket,
      ...params,
    },
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const unarchiveBucketAssetFile = async (idToken, bucket, params) => {
  const options = {
    url: `/api/bucket/method/unarchive_file`,
    method: 'POST',
    params: {
      bucket,
      ...params,
    },
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const postActivityMethod = async (idToken, modelName, params) => {
  const options = {
    url: `/api/activity/method/${modelName}`,
    method: 'POST',
    params,
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const postRecaptchaMethod = async (idToken, params) => {
  const options = {
    url: `/api/account/method/recaptcha`,
    method: 'POST',
    params,
  };
  const response = await API(options);
  return response;
};

export const checkPasswordIfPwned = async params => {
  const options = {
    url: `/api/account/method/checkpwned`,
    method: 'POST',
    data: params,
  };
  const response = await APIWithAuthToken(options);
  return response;
};

export const updateReferencePath = async (idToken, key, params) => {
  const options = {
    url: `/api/reference/method/update_path`,
    method: 'POST',
    params: {
      key,
      ...params,
    },
  };
  const response = await APIWithAuthToken(options);
  return response;
};
