import React, { useEffect, useState, useContext, useMemo } from 'react';
import { CSSTransition } from 'react-transition-group';

import 'components/UploadAssetLibrary/UploadAssetLibrary.scss';

import Modal from 'components/Common/Modal/Modal';
import Checkboxes from 'components/Common/Checkboxes/Checkboxes';
import Search from 'components/Common/Search/Search';
import Tile from 'components/Tile/Tile';
import Breadcrumbs, { IPages } from 'components/Common/Breadcrumbs/Breadcrumbs';
import { ASSETS_LIBRARY } from 'components/Dashboard/AssetLibrary/util';
import { UserContext } from 'providers/UserProvider';
import { UploadContext } from 'providers/UploadProvider';
import { getBucketAssetFolder } from 'services/Api';
import { ASSET_TYPES, Dictionary } from 'constants/assets';
import CaretDownIcon from 'assets/icons/caret-bottom.svg';
import FolderIcon from 'assets/icons/Icon-folder.svg';
import ArrowIcon from 'assets/icons/angle-right-gray.svg';
import CloseIcon from 'assets/icons/close.svg';

export interface IOptions {
  label: string;
}

export interface IFilterDropdowns {
  label: string;
  options: IOptions[];
}

export interface IUploadAssetLibrary {
  headingText: string;
  bucket: any;
  options?: IOptions[];
  filterDropdowns?: IFilterDropdowns[];
  open: boolean;
  setOpen?: (open: boolean) => void;
  onSelect?: (assets: any) => void;
  multiple?: boolean;
}

const asset_types: Dictionary = {
  Images: [
    ASSET_TYPES.image,
    ASSET_TYPES.svg,
    ASSET_TYPES.gif,
    ASSET_TYPES.ai,
    ASSET_TYPES.psd,
  ],
  Documents: [
    ASSET_TYPES.spreadsheet,
    ASSET_TYPES.pdf,
    ASSET_TYPES.document,
    ASSET_TYPES.presentation,
  ],
  Videos: [ASSET_TYPES.video],
  Techpacks: [],
  '3D': [ASSET_TYPES.obj, ASSET_TYPES.pattern, ASSET_TYPES.fbx],
  Other: [ASSET_TYPES.geometry, ASSET_TYPES.zip],
};

const UploadAssetLibrary: React.FC<IUploadAssetLibrary> = props => {
  const { headingText, bucket, open, setOpen, onSelect, multiple } = props;

  const {
    user: { idToken },
  } = useContext(UserContext);
  const [search, setSearch] = useState('');
  const [openDropdown, setOpenDropdown] = useState([]);

  const { firebaseStorageUrl } = useContext(UploadContext);
  const [folderPath, setFolderPath] = useState([]);
  const [assetType, setAssetType] = useState();
  const [activeFolders, setActiveFolders] = useState([]);
  const [folderFiles, setFolderFiles] = useState([]);
  const activeFiles = useMemo(() => folderFiles.filter(file => !!file.src), [
    folderFiles,
  ]);
  const [assetsSelected, setAssetsSelected] = useState([]);

  useEffect(() => {
    const fetchFolderHandler = async () => {
      if (!bucket?.key) {
        return;
      }
      setActiveFolders([]);
      setFolderFiles([]);
      setAssetsSelected([]);

      const folder_path = '/' + folderPath.map(p => p + '/').join('');
      const response = await getBucketAssetFolder(
        idToken,
        bucket.key,
        ASSETS_LIBRARY.assets_library.apis.get_folder,
        { path: folder_path }
      );
      const { data: blobs } = response.data;

      const folders = [];
      const files = [];
      await Promise.all(
        blobs.map(async blob => {
          const subPathName = blob.path.substring(folder_path.length);
          if (subPathName === '') {
            return;
          }
          const subPath = subPathName.split('/');

          // folder
          const [name] = subPath;
          if (blob.metadata === null || (blob.metadata !== null && subPath.length > 1)) {
            // check to add child folder if missing
            if (folders.findIndex(f => name.localeCompare(f.name) === 0) === -1) {
              folders.push({ name });
            }
          }

          // file
          if (blob.metadata !== null) {
            if (blob.metadata?.resizedImage === 'true') {
              // filter out resized images
              return;
            }
            blob.name = name;
            if (subPath.length === 1) {
              const blobUrl = `/${ASSETS_LIBRARY.assets_library.assets_storage_path}${bucket.key}${blob.path}`;
              blob.src = await firebaseStorageUrl(blobUrl);
            }
            files.push(blob);
          }
        })
      );
      setActiveFolders(folders);
      setFolderFiles(files);
    };
    fetchFolderHandler();
  }, [bucket?.key, folderPath]);

  const onFilterByFolder = folder => {
    setFolderPath([folder]);
  };

  const onFilterByType = asset_type => {
    setAssetType(asset_type);
  };

  const onRemoveAssetType = e => {
    e.preventDefault();
    setAssetType(undefined);
  };

  const filterDropdowns = useMemo(
    () => [
      {
        label: 'By Folder',
        options: ASSETS_LIBRARY.assets_library.folders.map(f => ({
          label: f,
          onSelect: onFilterByFolder,
        })),
      },
      {
        label: 'By Type',
        options: Object.keys(asset_types).map(type => ({
          label: type,
          onSelect: onFilterByType,
        })),
      },
    ],
    [onFilterByFolder, onFilterByType]
  );

  const onAdd = e => {
    e.preventDefault();
    const assets = assetsSelected
      .map(asset => activeFiles.find(file => file.path === asset))
      .filter(asset => !!asset);
    if (assets.length > 0) {
      onSelect?.(assets);
    }
    setOpen(false);
  };

  const onAddAll = async e => {
    e.preventDefault();
    const assets = (
      await Promise.all(
        folderFiles.map(async asset => {
          if (!asset.src) {
            const blobUrl = `/${ASSETS_LIBRARY.assets_library.assets_storage_path}${bucket.key}${asset.path}`;
            asset.src = await firebaseStorageUrl(blobUrl);
          }
          return asset;
        })
      )
    ).filter(asset => !!asset.src);
    if (assets.length > 0) {
      onSelect?.(assets);
    }
    setOpen(false);
  };

  const pages = useMemo(() => {
    const pagesArray = [];
    let link = '/';
    for (let i = 0; i < folderPath.length; i += 1) {
      const p = folderPath[i];
      link += `/${p}`;
      const page: IPages = {
        title: p,
      };
      if (i < folderPath.length - 1) {
        page.link = link;
        page.onClick = () => setFolderPath(folderPath.slice(0, i + 1));
      }
      pagesArray.push(page);
    }
    if (folderPath.length > 0) {
      pagesArray.push({
        title: '<',
        link: '/',
        onClick: () => setFolderPath(folderPath.slice(0, folderPath.length - 1)),
      });
    }
    return pagesArray;
  }, [folderPath]);

  const filteredFolders = activeFolders?.filter(f =>
    f.name.toLowerCase().includes(search.toLowerCase())
  );
  const filteredFiles = activeFiles?.filter(f => {
    const name = f.name.toLowerCase();
    if (!name.includes(search.toLowerCase())) {
      return false;
    }
    if (!assetType || !asset_types[assetType]) {
      return true;
    }
    const assetExtension = name.split('.').pop();
    for (const type of asset_types[assetType]) {
      const { extensions } = type;
      for (const ext of extensions) {
        if (assetExtension.localeCompare(ext.toLowerCase()) === 0) {
          return true;
        }
      }
    }
    return false;
  });

  const onSelectAsset = asset => {
    if (assetsSelected.includes(asset.path)) {
      setAssetsSelected(prev => prev.filter(p => p != asset.path));
    } else {
      if (!multiple) {
        setAssetsSelected([asset.path]);
      } else {
        setAssetsSelected(prev => [...prev, asset.path]);
      }
    }
  };

  return (
    <div className='upload-asset-library'>
      <Modal
        modalHeading={headingText}
        modalBtnClose='Cancel'
        modalBtnActionLabel='Add'
        modalBtnActionHandler={onAdd}
        secondaryBtnActionLabel={multiple ? 'Add all' : undefined}
        secondaryBtnAction={multiple ? onAddAll : undefined}
        edit={multiple}
        backgroundColor='#fff'
        modalOpen={open}
        setModalOpen={setOpen}
      >
        <div className='upload-asset-library__body'>
          <div className='upload-asset-library__search'>
            <Search changeHandler={setSearch} />
          </div>
          <div className='upload-asset-library__filters'>
            {filterDropdowns &&
              filterDropdowns.map(({ label, options }, idx) => (
                <div
                  className='upload-asset-library__dropdown'
                  key={idx}
                  onClick={
                    openDropdown.includes(idx)
                      ? () => setOpenDropdown(openDropdown.filter(e => e !== idx))
                      : () => setOpenDropdown([openDropdown.includes(idx), idx])
                  }
                >
                  {label}
                  <img src={CaretDownIcon} alt='Caret Down' />
                  <CSSTransition
                    classNames='lightbox'
                    timeout={200}
                    in={openDropdown.includes(idx)}
                    unmountOnExit
                  >
                    <div className='upload-asset-library__popup'>
                      {options.map(({ label, onSelect }, idx) => (
                        <div
                          className='upload-asset-library__option'
                          key={idx}
                          onClick={() => onSelect?.(label)}
                        >
                          {label}
                        </div>
                      ))}
                    </div>
                  </CSSTransition>
                </div>
              ))}
          </div>
          <Breadcrumbs pages={pages} />
          {assetType && (
            <div className='upload-asset-library__type mb-3'>
              <button
                className='button-badge color-approved-bg d-flex align-items-center'
                onClick={onRemoveAssetType}
              >
                {assetType}
                <img src={CloseIcon} alt='remove type' />
              </button>
            </div>
          )}
          <div className='upload-asset-library__folders'>
            {filteredFolders &&
              filteredFolders.map(({ name }, idx) => (
                <div key={name} className='upload-asset-library__folder'>
                  <span
                    className='upload-asset-library__folder__heading-flex'
                    onClick={() => setFolderPath([...folderPath, name])}
                  >
                    <img
                      src={FolderIcon}
                      className='upload-asset-library__folder__folder-icon'
                      alt={name}
                    />
                    {name}
                  </span>
                  <div className='upload-asset-library__folder__arrow-icon'>
                    <img src={ArrowIcon} alt='Open' />
                  </div>
                </div>
              ))}
          </div>
          <div className='upload-asset-library__assets'>
            {filteredFiles ? (
              filteredFiles.map((file, idx) => {
                const { name, path, src, metadata, content_type } = file;
                const contentType = content_type.split('/').pop();
                const checked = assetsSelected.includes(path);
                return (
                  <div
                    key={idx}
                    className={
                      checked
                        ? 'upload-asset-library__asset upload-asset-library__asset-active'
                        : 'upload-asset-library__asset'
                    }
                    onClick={() => onSelectAsset(file)}
                  >
                    <div className='upload-asset-library__selected'>
                      <Checkboxes
                        checked={checked}
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      />
                    </div>
                    <Tile
                      image={src}
                      ext={metadata?.ext || contentType}
                      aspectRatio='1-1'
                      vaGalleryView
                    />
                    <div className='upload-asset-library__asset-name'>{name}</div>
                  </div>
                );
              })
            ) : (
              <div className='upload-asset-library__none-selected'>Search the item</div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UploadAssetLibrary;
