import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import TimeAgo from 'react-time-ago';
import { TextInput } from 'components';
import { useForm } from 'react-hook-form';
import { Bytes } from './LiveChat';
import PopUp from '../PopUp/PopUp';
import { useFrameUI } from 'providers/FrameUIProvider';
import { ModalsContext } from 'providers/ModalsProvider';
import { NotificationsContext } from 'providers/NotificationsProvider';
import { UserContext } from 'providers/UserProvider';
import {
  deleteMessageModel,
  removeMemberFromGroupChat,
  editMessageModel,
} from 'services/Api';
import truncateText from 'utils/truncateText';
import DownloadIcon from 'assets/icons/download-gray.svg';
import EllipsisHIcon from 'assets/icons/ellipsis-h-grey.svg';
import { ASSET_TYPES } from 'constants/assets';

export type Message = {
  key: number;
  senderKey: number;
  profilePictureUrl?: string;
  firstName: string;
  lastName: string;
  lastMessage: string;
  timestamp?: number;
  assets?: any;
  assetMetadata?: any;
  assetUrl?: string;
  attachment_type?: string;
  attachment_name?: string;
};

export interface IChatMessages {
  messages?: Message[];
  mutateMessages?: () => void;
  memberJoined?: boolean;
  memberJoinedName?: string;
  groupId?: number | undefined;
}

const ChatMessages: React.FC<IChatMessages> = props => {
  const { state } = useFrameUI();
  const { messages, mutateMessages, memberJoined, memberJoinedName, groupId } = props;
  const [newMessages, setNewMessages] = useState([]);
  const [edit, setEdit] = useState(false);
  const [editMessage, setEditMessage] = useState('');

  const dateFormatOptions = useMemo(
    () =>
      ({
        hour: 'numeric',
        minute: 'numeric',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      } as Intl.DateTimeFormatOptions),
    []
  );

  const { setDisplayToast } = useContext(NotificationsContext);
  const { user } = useContext(UserContext);
  const { idToken } = user;

  useEffect(() => {
    setNewMessages(messages.reverse());
  }, [messages]);

  const deleteMessageActionHandler = useCallback(
    async messageKey => {
      setOpen(false);

      const response = await deleteMessageModel(idToken, messageKey);

      if (response?.data?.error) {
        setDisplayToast({
          type: 'error',
          message: `Error while deleting message`,
        });
      } else {
        setDisplayToast({
          type: 'success',
          message: `Message has been deleted successfully.`,
        });
        mutateMessages();
      }
    },
    [idToken]
  );

  const onSubmit = async e => {
    e.preventDefault();
    const response = await editMessageModel(idToken, selectedItem, editMessage);

    if (response?.data?.error) {
      setDisplayToast({
        type: 'error',
        message: `Error while Editing message`,
      });
    } else {
      setDisplayToast({
        type: 'success',
        message: `Message has been Edited successfully.`,
      });
      setSelectedItem(0);
      setEdit(false);
      mutateMessages();
    }
  };

  const removeMemberActionHandler = useCallback(
    async senderKey => {
      setOpen(false);
      try {
        const response = await removeMemberFromGroupChat(senderKey, groupId);

        if (response?.data) {
          setDisplayToast({
            type: 'success',
            message: `You Left the Group Chat`,
          });
        } else {
          setDisplayToast({
            type: 'error',
            message: `Error while Leaving Group Chat`,
          });
        }
      } catch (error) {
        setDisplayToast({
          type: 'error',
          message: error.message,
        });
      }
    },
    [idToken]
  );

  const { setOpenDialog, setDialogProps } = useContext(ModalsContext);
  const ownMessageOptions = useMemo(
    () => [
      {
        selectItem: 'Delete',
        onClick: messageKey => {
          setDialogProps({
            dialogBody: 'Are you sure you would like to delete this image?',
            btnActionHandler: () => {
              deleteMessageActionHandler(messageKey);
            },
          });
          setOpenDialog(true);
        },
      },
      {
        selectItem: 'Edit',
        onClick: messageKey => {
          setSelectedItem(messageKey);
          setEdit(true);
        },
      },
    ],
    [deleteMessageActionHandler, onSubmit]
  );

  const otherMessageOptions = useMemo(
    () => [
      {
        selectItem: 'Reply',
      },
      {
        selectItem: 'Remove',
        onClick: senderKey => {
          setDialogProps({
            dialogBody: 'Are you sure you want to remove this member?',
            btnActionHandler: () => {
              removeMemberActionHandler(senderKey);
            },
          });
          setOpenDialog(true);
        },
      },
    ],
    [deleteMessageActionHandler]
  );

  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0);
  const [selectedItemPopUp, setSelectedItemPopUp] = React.useState(0);

  return (
    <>
      <div className='livechat__heading'>
        {state.chatbar?.props?.model?.model === 'Account' ||
        state.chatbar?.props?.model?.teamId
          ? 'Messages'
          : 'Comments'}
      </div>
      {newMessages &&
        newMessages.map(
          ({
            key,
            senderKey,
            timestamp,
            assets,
            attachment_type,
            attachment_name,
            assetMetadata,
            assetUrl,
            firstName,
            lastName,
            lastMessage,
            profilePictureUrl,
          }) => (
            <div className='livechat__item-msg' key={timestamp}>
              <div className='livechat__item__metadata-row flex-inline'>
                <div className='livechat__item__metadata flex-inline'>
                  {!profilePictureUrl ? (
                    <div className='livechat__avatar'>
                      {firstName?.slice(0, 1)}
                      {lastName?.slice(0, 1)}
                    </div>
                  ) : (
                    <img
                      src={profilePictureUrl}
                      alt='Profile'
                      className='livechat__avatar'
                    />
                  )}

                  <div className='livechat__name'>
                    {truncateText(`${firstName || ''} ${lastName || ''}`, 20)}
                  </div>
                  <TimeAgo date={timestamp * 1000} />
                </div>
                {senderKey === user?.account?.key ? (
                  <div className='livechat__options'>
                    <img
                      src={EllipsisHIcon}
                      alt='Options'
                      onClick={() => {
                        setOpen(!open);
                        setSelectedItemPopUp(key);
                      }}
                    />
                    <PopUp open={open && selectedItemPopUp === key} messageModal>
                      {ownMessageOptions.map(({ selectItem, onClick }) => (
                        <li
                          className='pop-up__li'
                          onClick={() => {
                            setOpen(false);
                            if (onClick) {
                              onClick(key);
                            }
                          }}
                          key={selectItem}
                        >
                          {selectItem}
                        </li>
                      ))}
                    </PopUp>
                  </div>
                ) : (
                  <div className='livechat__options'>
                    <img
                      src={EllipsisHIcon}
                      alt='Options'
                      onClick={() => {
                        setOpen(!open);
                        setSelectedItemPopUp(key);
                      }}
                    />
                    <PopUp open={open && selectedItemPopUp === key} messageModal>
                      {otherMessageOptions.map(({ selectItem, onClick }) => (
                        <li
                          className='pop-up__li'
                          onClick={() => {
                            setOpen(false);
                            if (onClick) {
                              onClick(senderKey);
                            }
                          }}
                          key={selectItem}
                        >
                          {selectItem}
                        </li>
                      ))}
                    </PopUp>
                  </div>
                )}
              </div>
              <div className='livechat__content'>
                {selectedItem !== key ? (
                  <div className='livechat__msg livechat__msg-single-chat'>
                    {lastMessage}
                  </div>
                ) : (
                  edit && (
                    <form>
                      <input
                        autoFocus={true}
                        onFocus={() => setEditMessage(lastMessage)}
                        onChange={e => setEditMessage(e.target.value)}
                        name='edited_message'
                        required={true}
                        value={editMessage}
                        style={{
                          width: '80%',
                          border: '2px solid #E7E8E7',
                          padding: '10px 5px',
                          marginLeft: '20px',
                        }}
                      />
                      <div className='dialog__footer'>
                        <button
                          className='button-small button-transparent-black mr-3'
                          onClick={() => {
                            setEdit(false), setSelectedItem(0);
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className='button-small button-primary'
                          onClick={e => onSubmit(e)}
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  )
                )}
                {attachment_type && assets?.[attachment_type]?.progress > 0 && (
                  <div
                    className='livechat__asset'
                    onClick={() => {
                      state.chatbar?.props?.onClickMessageAsset?.(
                        assets[attachment_type]
                      );
                    }}
                  >
                    <div
                      className='livechat__asset__metadata'
                      onClick={() => {
                        setSelectedItem(key);
                      }}
                    >
                      <div className='livechat__asset__metadata-thumbnail'>
                        {attachment_type == ASSET_TYPES.image.type ? (
                          <img src={assetUrl} alt='thumbnail' />
                        ) : (
                          <span>{attachment_type}</span>
                        )}
                      </div>
                      <div className='livechat__asset__metadata-name'>
                        {attachment_name}
                      </div>
                      <div className='livechat__asset__metadata-size'>
                        <Bytes bytes={assetMetadata?.size} />
                      </div>
                    </div>
                    {assets?.[attachment_type]?.progress === 1 && assetUrl && (
                      <a
                        className='livechat__asset__download'
                        target='_blank'
                        rel='noreferrer'
                        href={assetUrl}
                        download={attachment_name || assetMetadata?.name}
                        title={attachment_name || assetMetadata?.name}
                      >
                        <img src={DownloadIcon} alt='Download' />
                      </a>
                    )}
                  </div>
                )}
              </div>
            </div>
          )
        )}

      {memberJoined && (
        <div className='w-100 d-flex justify-center livechat__heading'>
          {memberJoinedName} just joined this chat
        </div>
      )}
    </>
  );
};

export default ChatMessages;
