import React, { useState, useContext, useRef } from 'react';
import useSWR from 'swr';

import 'components/Dashboard/ProductUpload/ProductUpload.scss';
import PopUp from 'components/Common/PopUp/PopUp';
import DragAndDrop from 'components/Upload/DragAndDrop';
import UploadAssetLibrary from 'components/Dashboard/MaterialForm/UploadAssetLibrary';
import { AtelierGalleryImage } from '../Uploads/AtelierModelUploads/AtelierModelUploads';
import { UserContext } from 'providers/UserProvider';
import { mmAPI } from 'services/Api';
import { blobToFile } from 'utils/file';

export interface IAtelierFileUploader {
  dragTxt?: string;
  fileType?: string;
  emptyStateFileTypes?: string;
  buttonTxt?: string;
  multiple?: boolean;
  disabled?: boolean;
  onChangeHandler?: (files: File[]) => void;
  images?: AtelierGalleryImage[];
  brand_model_id?: string;
}

const AtelierFileUploader: React.FC<IAtelierFileUploader> = props => {
  const {
    dragTxt,
    emptyStateFileTypes,
    buttonTxt,
    multiple,
    disabled,
    onChangeHandler,
    images,
    brand_model_id,
  } = props;

  const { user } = useContext(UserContext);
  const { idToken } = user;

  const [open, setOpen] = useState(false);
  const [uploadModal, setUploadModal] = useState<boolean>(false);

  const uploadRef = useRef();

  const { data: bucket, error } = useSWR(
    ['/api/bucket/query/brand', idToken, brand_model_id],
    (url, idToken, brand) => {
      return mmAPI(url, idToken, { brand });
    },
    {
      suspense: false,
    }
  );

  const noUploadedImagesText = 'No product asset has been uploaded';

  const noUploadedImagesLightText = 'Use upload button or drag and drop media into area';

  const onChange = event => {
    setOpen(false);
    const localFiles: File[] = Array.from(event.currentTarget.files);
    onChangeHandler(localFiles);
  };

  const onDropFile = fileList => {
    onChangeHandler(fileList);
  };

  const onLibrarySelection = assets => {
    const assetFiles = [];
    for (const asset of assets) {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = event => {
        const blob = xhr.response;
        const file = blobToFile(blob, asset.name);
        assetFiles.push(file);
        if (assetFiles.length >= assets.length) {
          onChangeHandler(assetFiles);
        }
      };
      xhr.open('GET', asset.src);
      xhr.send();
    }
  };

  return (
    <>
      <DragAndDrop onDropHandler={onDropFile}>
        <div className='product-upload'>
          <div className='product-upload__drag-area'>
            <div className='product-upload__txt'>
              <p className='product-upload__instructions'>
                {images?.length === 0 ? noUploadedImagesText : dragTxt}
              </p>
              <p className='txt-gray-light'>
                {images?.length === 0 ? noUploadedImagesLightText : emptyStateFileTypes}
              </p>
            </div>
            <div className='product-upload__button mt-4'>
              <label
                className='button-small color-white-bd-bg'
                onClick={() => setOpen(!open)}
              >
                {buttonTxt}
              </label>
              <PopUp open={open} setOpen={setOpen}>
                <li
                  className='pop-up__li upload-atelier-assets__upload-buttons'
                  onClick={() => {
                    setOpen(false);
                    setUploadModal(true);
                  }}
                >
                  <label
                    htmlFor='upload_option_search'
                    onClick={() => {
                      setOpen(false);
                      setUploadModal(true);
                    }}
                  >
                    Search Library
                  </label>
                </li>
                <li
                  className='pop-up__li upload-atelier-assets__upload-buttons'
                  onClick={() => uploadRef.current && uploadRef.current.click()}
                >
                  <label>
                    Upload Assets
                  </label>
                </li>
              </PopUp>
              <input
                ref={uploadRef}
                type='file'
                id='upload'
                name='upload'
                className='d-none'
                onChange={onChange}
                multiple={multiple}
                disabled={disabled}
              />
            </div>
          </div>
        </div>
      </DragAndDrop>
      {bucket && uploadModal && (
        <UploadAssetLibrary
          headingText='Asset Library'
          bucket={bucket}
          open={uploadModal}
          setOpen={setUploadModal}
          onSelect={onLibrarySelection}
          multiple
        />
      )}
    </>
  );
};

AtelierFileUploader.defaultProps = {
  dragTxt: 'Drag and drop or select files',
  fileType: 'xxxx',
  buttonTxt: 'Upload',
  multiple: false,
};

export default AtelierFileUploader;
