import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import './LiveChat.scss';
import MentionTextField from './MentionTextField';
import { Tween } from 'react-gsap';
import CloseIcon from 'assets/icons/close.svg';
import CloseGray from 'assets/icons/close-gray.png';
import PaperclipIcon from 'assets/icons/paperclip-grey.svg';
import { useForm } from 'react-hook-form';
import { FrameUIActionsTypes, useFrameUI } from 'providers/FrameUIProvider';
import DragAndDrop from 'components/Upload/DragAndDrop';
import useMessages from 'hooks/useMessages';
import { UserContext } from 'providers/UserProvider';
import EllipsisHIcon from 'assets/icons/ellipsis-h-grey.svg';
import {
  deleteModel,
  setTeam,
  addMemberToGroupChat,
  removeMemberFromGroupChat,
  RenameTeamModel,
  mmAPI,
} from 'services/Api';
import GroupChatModal from '../GroupChatModal/GroupChatModal';
import { ChatContacts } from './ChatContacts';
import { ModalsContext } from 'providers/ModalsProvider';
import { ChatGroups } from './ChatGroups';
import ChatMessages from './ChatMessages';
import { NotificationsContext } from 'providers/NotificationsProvider';
import LogRocket from 'logrocket';
import PopUp from '../PopUp/PopUp';
import useSWR from 'swr';
import { TEAM_TYPES } from 'constants/teams';

export interface IContacts {
  key: number;
  profilePictureUrl?: string;
  firstName: string;
  lastName: string;
  lastMessage: string;
}

export interface IGroupChats {
  groupChatName: string;
  groupChatMembers: string;
}

export type LiveChat = {
  brand_model_id?: number;
  contacts: IContacts[];
  memberJoined: boolean;
  setMemberJoined: any;
  onClose?: () => void;
  accounts: any;
};

const LiveChat: React.FC<LiveChat> = props => {
  const { state, dispatch } = useFrameUI();
  const { user } = useContext(UserContext);
  const { idToken } = user;
  const { setDisplayToast } = useContext(NotificationsContext);
  const [open, setOpen] = useState(false);
  const [addPeople, setAddPeople] = useState(false);
  const [renameGroupChat, setRenameGroupChat] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState(0);
  const [remainingGroupMembers, setRemainingGroupMembers] = useState([]);
  const [showRemainingMembers, setShowRemainingMembers] = useState(false);
  const [participantName, setParticipantName] = useState('');
  const [memberToBeAdded, setMemberToBeAdded] = useState({});
  const [targetedMember, setTargetedMember] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [memberJoinedName, setMemberJoinedName] = useState('');
  const [comment, setComment] = useState('');
  const [mentionedPersons, setMentionedPersons] = useState([]);
  const {
    setAppModal,
    setOpenDialog,
    setDialogProps,
    setAppModalProps,
    setOpenAppModal,
    openAppModal,
  } = useContext(ModalsContext);
  const { setMessageModelAssoc, postMessage, mutateMessages } = useMessages(idToken, {
    model: null,
    open: false,
    messageFetchInterval: 300,
  });
  const {
    brand_model_id,
    contacts,
    onClose,
    memberJoined,
    setMemberJoined,
    accounts,
  } = props;
  const node = useRef();
  const messageBox = useRef(null);

  const [groupContacts, setGroupContacts] = useState([]);
  const [mentionContacts, setMentionContacts] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [fetchingContacts, setFetchingContacts] = useState(false);

  const { data: groupChats, mutate: mutateGroupChats } = useSWR(
    brand_model_id
      ? ['/api/team/method/get_account_teams', brand_model_id, TEAM_TYPES.group_chat]
      : null,
    (url, brand, type) => {
      return mmAPI(url, idToken, { brand, type });
    },
    {
      suspense: false,
    }
  );

  useEffect(() => {
    console.log('groupChats', groupChats);
    console.log('selectedGroup', selectedGroup);
    if (selectedGroup) {
      getSelectedGroupMembers(selectedGroup?.teamId);
    }
  }, [groupChats, selectedGroup]);

  useEffect(() => {
    const mapped = accounts.map(item => {
      return {
        name: `${item.first} ${item.last}`,
        profilePicture: item.assets.profile_picture.urls.download,
        key: item.key,
        checked: false,
      };
    });
    setGroupContacts(mapped);
  }, [accounts]);

  useEffect(() => {
    const getGroupChatContacts = () => {
      let mentionableFromGroupMembers = selectedGroup?.members.map(item => {
        return {
          display: item.name,
          id: item.accountId,
        };
      });
      mentionableFromGroupMembers = mentionableFromGroupMembers.filter(
        item => item.id !== user.account.key
      );

      console.log('setMentionContacts', mentionableFromGroupMembers);
      setMentionContacts(mentionableFromGroupMembers);
    };
    selectedGroup && getGroupChatContacts();
  }, [
    selectedGroup,
    selectedGroup?.members,
    memberJoined,
    user?.account?.key,
    groupChats,
  ]);

  useEffect(() => {
    setAppModalProps(prev => ({
      ...prev,
      modalOpen: openAppModal,
      modalHeading: 'Create Group',
      modalBtnClose: 'Cancel',
      modalBtnActionLabel: 'Create',
      backgroundColor: '#fff',
      contacts: groupContacts,
      setModalOpen: setOpenAppModal,
      selectionChanged: ({ key, checked }) => {
        if (checked) {
          const newContacts = [];
          const newSelected = [];
          for (let i = 0; i < groupContacts.length; i++) {
            const contact = groupContacts[i];
            if (contact.key === key) {
              contact.checked = checked;
              newContacts.push(contact);
              newSelected.push(contact);
            } else {
              newContacts.push(contact);
            }
          }
          const selected = [...selectedContacts, ...newSelected];
          const ordered = [];
          groupContacts.forEach(element => {
            if (selected.some(x => x.key === element.key)) {
              ordered.push(element);
            }
          });
          setGroupContacts(newContacts);
          setSelectedContacts(ordered);
        } else {
          const newContacts = [];
          for (let i = 0; i < groupContacts.length; i++) {
            const contact = groupContacts[i];
            if (contact.key === key) {
              contact.checked = checked;
              newContacts.push(contact);
            } else {
              newContacts.push(contact);
            }
          }
          const filtered = selectedContacts.filter(contact => contact.key !== key);
          const ordered = [];
          groupContacts.forEach(element => {
            if (filtered.some(x => x.key === element.key)) {
              ordered.push(element);
            }
          });
          setGroupContacts(newContacts);
          setSelectedContacts(ordered);
        }
      },
      fetchContacts: () => {
        if (groupContacts.length < 1 && !fetchingContacts) {
          setFetchingContacts(true);
        }
      },
      selectedContacts: selectedContacts,
      modalBtnActionHandler: async groupName => {
        const accounts =
          `${user.account.key},` + selectedContacts.map(x => x.key).join(',');
        const { data } = await setTeam(user.idToken, user.brand.key, groupName, accounts);
        setSelectedContacts([]);
        const resetContacts = groupContacts.map(contact => {
          const ctt = {
            checked: false,
            name: contact.name,
            key: contact.key,
            profilePicture: contact.profilePicture,
          };

          return ctt;
        });
        setGroupContacts(resetContacts);
      },
    }));
  }, [openAppModal, groupContacts, selectedContacts]);

  const modal = (
    <GroupChatModal
      modalOpen={openAppModal}
      modalHeading={'Create Group'}
      modalBtnClose={'Cancel'}
      modalBtnActionLabel={'Create'}
      backgroundColor={'#fff'}
      setModalOpen={setOpenAppModal}
      contacts={groupContacts}
      selectionChanged={() => {
        return;
      }}
      selectedContacts={selectedContacts}
      modalBtnActionHandler={() => {
        return;
      }}
      fetchContacts={() => {
        return;
      }}
    />
  );

  useEffect(() => {
    messageBox.current.scrollTop = messageBox.current.scrollHeight;
  });
  const { reset, errors } = useForm({});

  const deleteMessageActionHandler = useCallback(
    async teamKey => {
      setOpen(false);
      const response = await deleteModel(idToken, 'team', teamKey);

      if (response?.data) {
        setDisplayToast({
          type: 'success',
          message: `Thread Deleted Succesfully`,
        });
        mutateMessages();
        dispatch({
          type: FrameUIActionsTypes.CHATBAR_OPEN,
          payload: false,
        });
      } else {
        setDisplayToast({
          type: 'error',
          message: `Error while deleting message`,
        });
      }
    },
    [idToken]
  );

  const leaveGroupChat = useCallback(
    async teamKey => {
      try {
        const response = await removeMemberFromGroupChat(user.account.key, teamKey);

        if (response?.data) {
          setMessageModelAssoc(null);
          setDisplayToast({
            type: 'success',
            message: `You Left the Group Chat`,
          });
          mutateGroupChats([...groupChats.filter(g => g.teamId === teamKey)]);
        } else {
          setDisplayToast({
            type: 'error',
            message: `Error while Leaving Group Chat`,
          });
        }
      } catch (error) {
        setDisplayToast({
          type: 'error',
          message: error.message,
        });
      }
    },
    [groupChats]
  );

  const triggerGroupChatModal = useCallback(() => {
    setAppModalProps(prev => ({
      ...prev,
    }));
    setAppModal(modal);
    setOpenAppModal(true);
  }, []);

  const getSelectedGroupMembers = teamKey => {
    const selectedGroupChat = groupChats?.find(group => group.teamId === teamKey);
    if (!selectedGroupChat) return;
    setSelectedGroup(selectedGroupChat);
    const selectedGroupMembers = selectedGroupChat?.members;
    getRemainingGroupMembers(selectedGroupMembers);
  };

  const onSubmitPeople = async () => {
    try {
      const res = await addMemberToGroupChat(
        // @ts-ignore
        memberToBeAdded?.key,
        selectedGroup?.teamId
      );
      if (res?.data) {
        setDisplayToast({
          type: 'success',
          message: `${res.data.data.name} has been added as member of ${selectedGroup.name}`,
        });
        setAddPeople(false);
        setShowRemainingMembers(false);
        setRemainingGroupMembers([]);
        setParticipantName('');
        setMemberJoinedName(res.data.data.name);
        setMemberJoined(true);
        mutateGroupChats();
      }
    } catch (error) {
      setDisplayToast({
        type: 'error',
        message: error.message,
      });
    }
  };

  const onSubmitRename = async () => {
    try {
      const res = await RenameTeamModel(participantName, selectedGroupId);
      if (res?.data) {
        setDisplayToast({
          type: 'success',
          message: `Chat name has been updated`,
        });
        setRenameGroupChat(false);
        setMessageModelAssoc(null);
      }
    } catch (error) {
      setDisplayToast({
        type: 'error',
        message: error.message,
      });
    }
  };

  const onChange = event => {
    setParticipantName(event.target.value);

    const target = remainingGroupMembers.filter(member =>
      member.name.toLowerCase().includes(participantName.toLowerCase())
    );

    if (target.length > 0) {
      setTargetedMember(target);
    } else {
      setTargetedMember([{ name: 'No Member Found With this name' }]);
    }
  };

  const getRemainingGroupMembers = selectedGroupMembers => {
    let remainingMembers = [];
    remainingMembers = groupContacts.filter(el => {
      return !selectedGroupMembers.find(element => {
        return element.accountId === el.key;
      });
    });

    setRemainingGroupMembers(remainingMembers);
  };

  const popUpOptions = [
    {
      selectItem: 'Delete',
      onClick: teamKey => {
        setDialogProps({
          dialogBody: 'Are you sure you would like to delete this Thread?',
          btnActionHandler: () => {
            deleteMessageActionHandler(teamKey);
          },
        });
        setOpenDialog(true);
      },
    },
  ];

  const popUpOptionsForGroupChat = [
    {
      selectItem: 'Add People',
      onClick: teamKey => {
        getSelectedGroupMembers(teamKey);
        setAddPeople(true);
      },
    },
    {
      selectItem: 'Leave Conversation',
      onClick: teamKey => {
        setDialogProps({
          dialogBody: 'Are you sure you would like to Leave this group?',
          btnActionHandler: () => {
            leaveGroupChat(teamKey);
          },
        });
        setOpenDialog(true);
      },
    },
    // {
    //   selectItem: 'Archive',
    // },
    // {
    //   selectItem: 'Delete',
    //   onClick: teamKey => {
    //     setDialogProps({
    //       dialogBody: 'Are you sure you would like to delete this Thread?',
    //       btnActionHandler: () => {
    //         deleteMessageActionHandler(teamKey);
    //       },
    //     });
    //     setOpenDialog(true);
    //   },
    // },
    {
      selectItem: 'Rename',
      onClick: teamKey => {
        setRenameGroupChat(true);
        setSelectedGroupId(teamKey);
      },
    },
  ];

  const popUpGroupChatOptions = [
    {
      selectItem: 'Create New Group',
      onClick: () => triggerGroupChatModal(),
    },
    {
      selectItem: 'Manage Setting',
      onClick: () => console.log('clicked'),
    },
  ];

  const onPostMessage = async (event, text, mentionedPersons) => {
    event.preventDefault();
    const values: any = {};
    values.file = messageAttachment?.file;
    values.fileMetadata = messageAttachment?.fileMetadata;
    values.message = text;
    values.mentionedPersons = undefined;
    values.mention = 'false';
    if (mentionedPersons.length) {
      const textWithoutOpeningSquare = text.replaceAll('[', '');
      const textWithoutClosingSquare = textWithoutOpeningSquare.replaceAll(']', '');
      const textWithoutClosingSmall = textWithoutClosingSquare.replaceAll(')', '');
      let textWithoutOpeningSmall = textWithoutClosingSmall.replaceAll('(', '');

      // filter list in case user was removed from the textarea
      mentionedPersons = mentionedPersons.filter(item => {
        let found = false;
        if (textWithoutOpeningSmall.includes(item)) {
          found = true;
          textWithoutOpeningSmall = textWithoutOpeningSmall.replace(item, '');
        }
        return found;
      });
      values.message = textWithoutOpeningSmall;
      values.mentionedPersons = mentionedPersons.toString();
      values.mention = 'true';
    }
    try {
      const response = await postMessage(values);
      if (response.data?.error) {
        const message =
          response.data.error.type === 'AuthInvalidSession'
            ? 'Unable to deliver the message. Please refresh your session.'
            : 'Unable to deliver the message.';
        setDisplayToast({
          type: 'error',
          persist: false,
          message,
        });
        LogRocket.captureMessage(`Messaging error: postMessage`, {
          tags: {
            // additional data to be grouped as "tags"
            journey: 'LiveChat',
            step: 'onPostMessage',
          },
          extra: {
            // additional arbitrary data associated with the event
            values,
            response: response?.data,
          },
        });
      } else {
        setMessageAttachment(null);
        setComment('');
        setMentionedPersons([]);
        mutateMessages?.();
        reset();
      }
    } catch (error) {
      console.log('onPostMessage error', error);
      setDisplayToast({
        type: 'error',
        persist: false,
        message: 'Something went wrong',
      });
      LogRocket.captureMessage(`Messaging error: postMessage`, {
        tags: {
          // additional data to be grouped as "tags"
          journey: 'LiveChat',
          step: 'onPostMessage',
        },
        extra: {
          // additional arbitrary data associated with the event
          values,
          error,
        },
      });
    }
  };

  const [messageAttachment, setMessageAttachment] = useState(null);
  const onAttachmentChangeHandler = files => {
    const [file] = files;
    setMessageAttachment({
      file,
    });
  };

  return (
    <div className={state.chatbar?.open ? 'livechat--visible' : 'livechat-hidden'}>
      <Tween
        from={{ x: '50px', autoAlpha: 0 }}
        to={{ x: '0px', autoAlpha: 1 }}
        duration={0.5}
      >
        <div className='livechat' ref={node}>
          <div className='livechat__header'>
            <div className='livechat__header-top'>
              {addPeople ? (
                <>
                  <div className='livechat__header-top-right'>
                    <div className='style-sample-review-header__wrap'></div>
                    <span className='uppercase'>Add People</span>
                  </div>
                  <div className='livechat__flexRow'>
                    <button
                      onClick={() => {
                        setAddPeople(false),
                          setShowRemainingMembers(false),
                          setTargetedMember([]),
                          setParticipantName('');
                      }}
                    >
                      <img className='livechat__close' src={CloseIcon} alt='Close' />
                    </button>
                  </div>
                </>
              ) : renameGroupChat ? (
                <>
                  <div className='livechat__header-top-right'>
                    <div className='style-sample-review-header__wrap'></div>
                    <span className='uppercase'>Rename</span>
                  </div>
                  <div className='livechat__flexRow'>
                    <button
                      onClick={() => {
                        setRenameGroupChat(false);
                      }}
                    >
                      <img className='livechat__close' src={CloseIcon} alt='Close' />
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className='livechat__header-top-right'>
                    {(state.chatbar.props?.model?.key ||
                      state.chatbar.props?.model?.teamId) && (
                      <div className='style-sample-review-header__wrap'>
                        {!['Reference', 'Style'].includes(
                          state.chatbar.props?.model?.KIND
                        ) && (
                          <div
                            className='style-sample-review-header__left-aligned'
                            onClick={e => {
                              setMessageModelAssoc(null), setMemberJoined(false);
                            }}
                          >
                            <div className='livechat__back-icon'>
                              <svg
                                width='16'
                                height='14'
                                viewBox='0 0 16 14'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M2.72525 7.84536H15.1998C15.4121 7.84536 15.6156 7.76105 15.7656 7.61099C15.9157 7.46093 16 7.25741 16 7.04519C16 6.83297 15.9157 6.62945 15.7656 6.47939C15.6156 6.32933 15.4121 6.24502 15.1998 6.24502H2.72525L6.96613 2.00415C7.04934 1.93181 7.1167 1.84306 7.16399 1.74345C7.21128 1.64385 7.23748 1.53555 7.24093 1.42535C7.24439 1.31514 7.22503 1.20541 7.18408 1.10304C7.14312 1.00067 7.08145 0.907872 7.00294 0.830462C6.92442 0.753053 6.83076 0.692705 6.72782 0.653206C6.62488 0.613707 6.51488 0.595908 6.40474 0.600927C6.29459 0.605946 6.18668 0.633674 6.08775 0.682372C5.98883 0.731069 5.90104 0.799685 5.82989 0.883915L0.228733 6.48507C0.0821209 6.63465 0 6.83574 0 7.04519C0 7.25464 0.0821209 7.45573 0.228733 7.60531L5.82989 13.2065C5.98202 13.3387 6.17846 13.4087 6.37994 13.4024C6.58142 13.396 6.77309 13.3139 6.91664 13.1724C7.06018 13.0309 7.14502 12.8404 7.1542 12.639C7.16337 12.4376 7.09621 12.2402 6.96613 12.0862L2.72525 7.84536Z'
                                  fill='#999A9A'
                                />
                              </svg>
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    <span className='uppercase'>
                      {state.chatbar.props?.model?.key ||
                      state.chatbar.props?.model?.teamId
                        ? `${state.chatbar.props?.title || 'Direct Chat'}`
                        : `Chat`}
                    </span>
                  </div>
                  <div className='livechat__flexRow'>
                    <img
                      className={`livechat__mr20`}
                      src={EllipsisHIcon}
                      alt={'Options'}
                      onClick={() => {
                        setOpen(!open);
                      }}
                    />
                    {(state.chatbar.props?.model?.key ||
                      state.chatbar.props?.model?.teamId) &&
                    !state.chatbar.loading ? (
                      <>
                        <PopUp open={open}>
                          {state.chatbar.props?.model?.key
                            ? popUpOptions.map(({ selectItem, onClick }) => (
                                <li
                                  className='pop-up__li'
                                  onClick={() => {
                                    setOpen(false);
                                    if (onClick) {
                                      onClick(state?.chatbar?.props?.model?.key);
                                    }
                                  }}
                                  key={selectItem}
                                >
                                  {selectItem}
                                </li>
                              ))
                            : popUpOptionsForGroupChat.map(({ selectItem, onClick }) => (
                                <li
                                  className='pop-up__li'
                                  onClick={() => {
                                    setOpen(false);
                                    if (onClick) {
                                      onClick(state?.chatbar?.props?.model?.teamId);
                                    }
                                  }}
                                  key={selectItem}
                                >
                                  {selectItem}
                                </li>
                              ))}
                        </PopUp>
                      </>
                    ) : (
                      <>
                        <PopUp open={open}>
                          {popUpGroupChatOptions.map(({ selectItem, onClick }) => (
                            <li
                              className='pop-up__li'
                              onClick={() => {
                                setOpen(false);
                                if (onClick) {
                                  onClick();
                                }
                              }}
                              key={selectItem}
                            >
                              {selectItem}
                            </li>
                          ))}
                        </PopUp>
                      </>
                    )}
                    <button
                      onClick={() => {
                        onClose?.(), setMemberJoined(false);
                      }}
                    >
                      <img className='livechat__close' src={CloseIcon} alt='Close' />
                    </button>
                  </div>{' '}
                </>
              )}
            </div>
            {(addPeople || renameGroupChat) && (
              <>
                <input
                  className='livechat__placeholder-color control mt-4 control--full-width control--text'
                  placeholder='Type the participant names'
                  type='text'
                  value={participantName}
                  onFocus={() => setShowRemainingMembers(true)}
                  onChange={e => onChange(e)}
                />
                <br />
                {!renameGroupChat && (
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {showRemainingMembers && (
                      <div style={{ width: '70%', height: '50px', overflow: 'scroll' }}>
                        {targetedMember.map(members => {
                          return (
                            <li
                              className='pop-up__li'
                              key={members.key}
                              onClick={() => {
                                setShowRemainingMembers(false),
                                  setTargetedMember([]),
                                  setMemberToBeAdded(members);
                                setParticipantName(members.name);
                              }}
                            >
                              {members.name}
                            </li>
                          );
                        })}
                      </div>
                    )}
                    <div>
                      <button className='button-primary' onClick={() => onSubmitPeople()}>
                        Submit
                      </button>
                    </div>
                  </div>
                )}
                {renameGroupChat && (
                  <button className='button-primary' onClick={() => onSubmitRename()}>
                    Submit
                  </button>
                )}
              </>
            )}
          </div>
          <div
            className='livechat__wrap'
            style={addPeople ? { height: '80%' } : { height: '99%' }}
          >
            <div
              className='livechat__body'
              style={
                state.chatbar.props?.model?.key || state.chatbar.props?.model?.teamId
                  ? { height: 'calc(100% - 225px)' }
                  : { height: '100%' }
              }
              ref={messageBox}
            >
              {(state.chatbar.props?.model?.key || state.chatbar.props?.model?.teamId) &&
                !state.chatbar.loading && (
                  <div className='livechat__group'>
                    <ChatMessages
                      messages={state.chatbar?.messages}
                      mutateMessages={mutateMessages}
                      memberJoined={memberJoined}
                      memberJoinedName={memberJoinedName}
                      groupId={
                        state.chatbar.props?.model?.teamId
                          ? state.chatbar.props?.model?.teamId
                          : undefined
                      }
                    />
                  </div>
                )}
              <div className='livechat__group'>
                {!state.chatbar.props?.model?.key &&
                  !state.chatbar.props?.model?.teamId && (
                    <ChatContacts contacts={contacts} />
                  )}
              </div>
              <div className='livechat__group'>
                {!state.chatbar.props?.model?.key &&
                  !state.chatbar.props?.model?.teamId &&
                  groupChats?.length > 0 && (
                    <ChatGroups
                      groupChats={groupChats}
                      getSelectedGroupMembers={getSelectedGroupMembers}
                    />
                  )}
              </div>
            </div>
            <div
              className='livechat__footer'
              style={
                state.chatbar.props?.model?.key || state.chatbar.props?.model?.teamId
                  ? { height: '225px' }
                  : { height: '100px' }
              }
            >
              {(state.chatbar.props?.model?.key ||
                state.chatbar.props?.model?.teamId) && (
                <>
                  <form
                    className={`livechat__msg__compose`}
                    name='LiveChat-message'
                    onSubmit={e => onPostMessage(e, comment, mentionedPersons)}
                  >
                    <MentionTextField
                      data={mentionContacts}
                      setComment={setComment}
                      comment={comment}
                      setMentionedPersons={setMentionedPersons}
                      mentionedPersons={mentionedPersons}
                      placeholder={`Write a message in the ${
                        state.chatbar.props?.model?.name || ''
                      } thread`}
                    />
                    {errors?.message && (
                      <div className='error-text w-100'>{errors?.message?.message}</div>
                    )}
                    <div className={`livechat__send-btn flex-inline flex-justify-end`}>
                      <div className={`livechat__asset__controls`}>
                        {messageAttachment && (
                          <>
                            <div className='livechat__asset-remove'>
                              <img
                                src={CloseGray}
                                alt='Remove Attachment'
                                onClick={() => setMessageAttachment(null)}
                              />
                            </div>
                            <div className='livechat__asset-name'>
                              {messageAttachment?.file?.name}
                            </div>
                          </>
                        )}
                      </div>
                      <div className='livechat-attachment__drag-container'>
                        <DragAndDrop
                          onDropHandler={files => {
                            onAttachmentChangeHandler(files);
                          }}
                        >
                          <div className='livechat-attachment__drag-area'>
                            <label htmlFor={'messageAssetUpload'} className=''>
                              <img
                                className='livechat__attach'
                                src={PaperclipIcon}
                                alt='Add an Attachment'
                              />
                            </label>
                            <div className='image-upload'>
                              <input
                                id={'messageAssetUpload'}
                                type='file'
                                onChange={e => {
                                  const { files } = e.currentTarget;
                                  onAttachmentChangeHandler(files);
                                }}
                                name={'messageAssetUpload'}
                              />
                            </div>
                          </div>
                        </DragAndDrop>
                      </div>

                      <button className='button-regular color-white-bd-bg'>Send</button>
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </Tween>
    </div>
  );
};

export default LiveChat;

const sufixes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
const getBytes = bytes => {
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return (
    (!bytes && '0 Bytes') || (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sufixes[i]
  );
};

export const Bytes = ({ bytes }) => <span>{getBytes(bytes)}</span>;
