import React, { useState, useContext, useEffect } from 'react';
import { Link, useLocation, navigate } from '@reach/router';
import queryString from 'query-string';
import './FilterBar.scss';
import Checkbox from 'components/Common/Checkboxes/Checkboxes';
import PopUp from 'components/Common/PopUp/PopUp';
import Search from 'components/Common/Search/Search';
import { IPopUpOptions } from 'models/Dashboard/IPopUpOptions';
import { ViewModeContext } from 'providers/contexts';
import { VIEWMODE } from 'providers/ViewModeProvider';
import { useFrameUI, FrameUIActionsTypes } from 'providers/FrameUIProvider';
import { useLocationPageName } from 'hooks/useLocation';

import PlusIcon from 'assets/icons/plus-btn.svg';
import CaretDownIcon from 'assets/icons/caret-bottom.svg';
import InfoIcon from 'assets/icons/info-circle-full.svg';
import InfoGridIcon from 'assets/icons/Icon-grid-grey.svg';

export interface IOptions {
  key?: string;
  label: string;
  isChecked?: boolean;
}

export type DropdownList = {
  key?: string;
  label: string;
  options?: IOptions[];
};

export interface IFilterBar {
  addedItemsModalView?: boolean;
  popUpOptions: IPopUpOptions[];
  handleApply?: () => void;
  infoGridVisible?: boolean;
}

const FilterBar: React.FC<IFilterBar> = props => {
  const { infoGridVisible = false, handleApply } = props;

  const { state, dispatch } = useFrameUI();
  const { filterBar } = state;
  const {
    searchVisible,
    dropdownList,
    dropdownListVisible,
    infoIconVisible,
    infoIconHandler,
    viewModeIconVisible,
  } = filterBar;
  const popUpOptions = props.popUpOptions || filterBar.options;

  const [open, setOpen] = useState(false);
  const [ openInfoBar, setOpenInfoBar ] = useState(false);
  const [openViewMode, setOpenViewMode] = useState(false);
  const {
    viewMode,
    setViewMode,
    viewsList,
    showLabel,
    viewSettings,
    setViewSettings,
  } = useContext(ViewModeContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [ActiveModalComponent, setActiveModalComponent] = useState(null);
  const [openDropdown, setOpenDropdown] = useState([]);
  const [dropdownListInputs, setDropdownListInputs] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);
  const [shouldUpdateFilter, setShouldUpdateFilter] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  useEffect(() => {
    return(
      dispatch({
        type: FrameUIActionsTypes.INFO_BAR_VISIBLE,
        payload: false
      })
    )
  }, [])
  
  useEffect(() => {
    const dropdownListEffect = dropdownListVal => {
      const query = new URLSearchParams(location.search);
      if (dropdownListVal) {
        const inputs = dropdownListVal.map(dropdown => {
          const queryItems = query.get(dropdown.key);
          const itemsList = (queryItems ? queryItems.split(',') : []).map(item =>
            parseInt(item)
          );
          return {
            ...dropdown,
            options: dropdown.options.map(option => ({
              ...option,
              isChecked: itemsList.includes(parseInt(option.key)),
            })),
          };
        });
        setDropdownListInputs(inputs);
      } else {
        setDropdownListInputs([]);
      }
    };
    dropdownListEffect(dropdownList);
  }, [location.search, dropdownList]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const querySearch = query.get('search') || '';
    if (querySearch != searchValue) {
      setSearchValue(querySearch);
    }
  }, [location.search]);

  useEffect(() => {
    const dropdownListInputsEffect = dropdownListInputsVal => {
      if (dropdownListInputsVal.length > 0) {
        setSelectedCount(
          dropdownListInputsVal.reduce(
            (count, dropdown) =>
              count + dropdown.options.reduce((c, option) => c + option.isChecked, 0),
            0
          )
        );
      }
    };
    dropdownListInputsEffect(dropdownListInputs);
  }, [dropdownListInputs]);

  useEffect(() => {
    if (shouldUpdateFilter) {
      setShouldUpdateFilter(false);
      handleUpdateFilters();
    }
  }, [shouldUpdateFilter]);

  const handleUpdateFilters = () => {
    const queryUpdated = queryParams;
    dropdownListInputs.map(dropdown => {
      const checkedItems = [];
      dropdown.options.map(option => {
        if (option.isChecked) {
          checkedItems.push(option.key);
        }
      });
      if (checkedItems.length > 0) {
        queryUpdated[dropdown.key] = checkedItems.join(',');
      } else {
        delete queryUpdated[dropdown.key];
      }
    });
    const queryParamsUpdated = Object.entries(queryUpdated)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    navigate(location.pathname + '?' + queryParamsUpdated);
  };

  const handleDeselectAll = () => {
    const inputsUncheckedAll = dropdownListInputs.map(dropdown => ({
      ...dropdown,
      options: dropdown.options.map(option => ({
        ...option,
        isChecked: false,
      })),
    }));
    setDropdownListInputs([...inputsUncheckedAll]);
    setShouldUpdateFilter(true);
  };

  const handleDeselectOneAll = filter_key => {
    searchChangeHandler('');
    const inputsUncheckedOneAll = dropdownListInputs.map(dropdown => {
      if (dropdown.key != filter_key) {
        return dropdown;
      }
      return {
        ...dropdown,
        options: dropdown.options.map(option => ({
          ...option,
          isChecked: false,
        })),
      };
    });
    setDropdownListInputs([...inputsUncheckedOneAll]);
    setShouldUpdateFilter(true);
  };

  const handleOnChange = event => {
    const key = parseInt(event.currentTarget.value);
    const inputsCheckedUpdated = dropdownListInputs.map(dropdown => ({
      ...dropdown,
      options: dropdown.options.map(option => ({
        ...option,
        isChecked: option.key === key ? !option.isChecked : option.isChecked,
      })),
    }));
    setDropdownListInputs(inputsCheckedUpdated);
  };

  const onApplyFilter = () => {
    setShouldUpdateFilter(true);
  };

  const handlePopUpModal = ModalComponent => {
    setActiveModalComponent(ModalComponent);
    setModalOpen(true);
  };

  const pageName = useLocationPageName();
  useEffect(() => {
    if (!viewSettings['atelier'] && !viewSettings['products']) {
      setViewSettings(prev => ({
        ...prev,
        atelier: VIEWMODE.TABLE.key,
        products: VIEWMODE.CARD.key,
      }));
    }
  }, []);

  const activeViewMode = viewsList.find(
    view => view.key === (viewMode ?? VIEWMODE.TABLE.key)
  );

  const searchChangeHandler = value => {
    if (value !== searchValue) {
      setSearchValue(value);

      const queryUpdated = queryParams;
      if (value && value !== '') {
        queryUpdated['search'] = value;
      } else {
        delete queryUpdated['search'];
      }
      const queryParamsUpdated = Object.entries(queryUpdated)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

      navigate(location.pathname + '?' + queryParamsUpdated);
    }
  };

  return (
    <div className='filter-bar'>
      {dropdownListVisible ? (
        <div className='filter-bar__dropdowns'>
          {dropdownListInputs &&
            dropdownListInputs.map(({ key: filter_key, label, options }, idx) => (
              <div
                className={
                  options.filter(inputsChecked => inputsChecked.isChecked).length > 0
                    ? 'filter-bar__dropdown filter-bar__dropdown-selected'
                    : 'filter-bar__dropdown'
                }
                key={idx}
                onClick={
                  openDropdown.includes(idx)
                    ? () => setOpenDropdown(openDropdown.filter(e => e !== idx))
                    : () => setOpenDropdown([openDropdown.includes(idx), idx])
                }
              >
                {label}
                <img src={CaretDownIcon} alt='Caret down' />
                <div
                  className={
                    openDropdown.includes(idx) ? 'visible-popup' : 'hidden-popup'
                  }
                >
                  <div className='filter-bar__popup'>
                    <div className='filter-bar__subtitle'>Select {label}</div>
                    {options.map(({ key: option_key, label, isChecked }) => (
                      <div className='filter-bar__checkbox' key={option_key}>
                        <Checkbox
                          label={label}
                          checked={isChecked ? true : false}
                          value={option_key}
                          onChange={handleOnChange}
                        />
                      </div>
                    ))}
                    <div className='filter-bar__btns'>
                      <button
                        className='button-secondary'
                        onClick={() => handleDeselectOneAll(filter_key)}
                      >
                        Clear
                      </button>
                      <button className='button-primary' onClick={onApplyFilter}>
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      ) : (
        <FilterRefine />
      )}

      {searchVisible && (
        <div className='filter-bar__search'>
          <Search searchValue={searchValue} changeHandler={searchChangeHandler} />
        </div>
      )}
      {selectedCount && selectedCount > 0 ? (
        <>
          <div className='filter-bar__right'>
            <div className='filter-bar__clear' onClick={handleDeselectAll}>
              Clear <span className='filter-bar__selected-number'>{selectedCount}</span>
            </div>
            <div className='filter-bar__list'>
              {infoIconVisible && (
                <img
                  src={InfoIcon}
                  alt='info icon'
                  className='filter-bar__info-icon'
                  onClick={() => {
                    infoIconHandler;
                    setOpenInfoBar(!openInfoBar);
                    dispatch({
                      type: FrameUIActionsTypes.INFO_BAR_VISIBLE,
                      payload: !openInfoBar,
                    });
                  }}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <div className='filter-bar__right'>
          <div className='filter-bar__list'>
            {infoIconVisible && (
              <img
                src={InfoIcon}
                alt='info icon'
                className='filter-bar__info-icon'
                onClick={() => {
                  infoIconHandler;
                  setOpenInfoBar(!openInfoBar);
                  dispatch({
                    type: FrameUIActionsTypes.INFO_BAR_VISIBLE,
                    payload: !openInfoBar,
                  });
                }}
              />
            )}
            {infoGridVisible && (
              <img
                src={InfoGridIcon}
                alt='info grid icon'
                className='filter-bar__info-icon'
              />
            )}
            {viewModeIconVisible && (
              <img
                alt='viewmode icon'
                src={activeViewMode?.icon?.active}
                onClick={() => setOpenViewMode(true)}
              />
            )}
            <PopUp open={openViewMode} setOpen={setOpenViewMode}>
              {viewsList &&
                viewsList.map(({ key, label, icon }) => (
                  <li
                    className='pop-up__li'
                    onClick={() => {
                      setOpenViewMode(false);
                      setViewMode(key);
                      setViewSettings(prev => ({
                        ...prev,
                        [pageName]: key,
                      }));
                    }}
                    key={key}
                  >
                    <img
                      src={key === activeViewMode?.key ? icon.active : icon.inactive}
                    />
                    {showLabel && <span className='ml-3'>{label}</span>}
                  </li>
                ))}
            </PopUp>
          </div>

          {popUpOptions.length > 0 && (
            <>
              <div className='filter-bar__list'>
                <img
                  className='filter-bar__plus'
                  src={PlusIcon}
                  alt='Plus'
                  onClick={() => setOpen(!open)}
                />
                <PopUp open={open} setOpen={setOpen}>
                  {popUpOptions &&
                    popUpOptions.map(
                      ({ selectItem, to, onClick, ModalComponent, disabled }) => (
                        <li
                          style={
                            disabled ? { pointerEvents: 'none', opacity: '0.5' } : {}
                          }
                          className='pop-up__li'
                          onClick={() => {
                            setOpen(false);
                            if (ModalComponent) {
                              handlePopUpModal(ModalComponent);
                            } else if (onClick) {
                              onClick();
                            }
                          }}
                          key={selectItem}
                        >
                          {to ? <Link to={to}>{selectItem}</Link> : selectItem}
                        </li>
                      )
                    )}
                </PopUp>
              </div>
            </>
          )}
        </div>
      )}
      {ActiveModalComponent && modalOpen
        ? React.cloneElement(ActiveModalComponent, {
            open: modalOpen,
            setOpen: setModalOpen,
          })
        : null}
    </div>
  );
};

export default FilterBar;

const FilterRefine = () => (
  <div className='filter-bar__filter-by '>
    <div className='control-group'>
      {/* <Checkbox /> */}
      {/* Refine */}
      {/* <img className='filter-bar__filter' src={FilterIcon} alt='Filter' /> */}
    </div>
  </div>
);
