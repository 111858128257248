import React, { useEffect, useState, useContext, useMemo } from 'react';
import useSWR from 'swr';
import { mmAPI } from 'services/Api';
import { UserContext } from 'providers/contexts';
import SummarizeTeam from 'components/Dashboard/ManageTeam/SummarizeTeam';
import ManageTeam from 'components/Dashboard/ManageTeam/ManageTeam';
import { useParams } from '@reach/router';

export const ModelTeamContainer = ({
  model,
  viewMode = 'SUMMARIZE',
  triggerModelAccessModal,
}) => {
  const { user } = useContext(UserContext);
  const { idToken } = user;

  const { brand_model_id } = useParams();

  const { data: accounts } = useSWR(
    model ? [`/api/account/query/model/`, idToken, model.KIND, model.key] : null,
    (url, idToken, model, model_id) => {
      return mmAPI(url, idToken, { model, model_id, cursor: 0 });
    },
    {
      suspense: true,
    }
  );

  const todo = true;
  const { data: invites } = useSWR(
    [
      `/api/invite/query/${!todo ? 'model' : 'brand'}/`,
      idToken,
      !todo ? model.key : brand_model_id,
    ],
    (url, idToken, queryModel) => {
      return mmAPI(url, idToken, { [!todo ? 'model' : 'brand']: queryModel, cursor: 0 });
    },
    {
      suspense: true,
    }
  );

  const [membersFromAccounts, setMembersFromAccounts] = useState([]);
  const [membersFromInvites, setMembersFromInvites] = useState([]);
  const [membersTableItems, setMembersTableItems] = useState([]);

  useEffect(() => {
    const accountsEffect = () => {
      if (!accounts) return;
      const accountsMapped = accounts
        .sort((a, b) => b.created - a.created)
        .map(account => {
          const {
            name,
            first,
            last,
            email,
            job_type: accountType,
            department,
            status,
            key,
            cache,
          } = account;
          const activeAccount = 'active';
          return {
            key,
            name,
            first,
            last,
            email,
            accountType,
            activeAccount,
            status,
            department,
            cache,
          };
        });
      console.log('accountsMapped', model?.name, model?.key, accountsMapped);
      setMembersFromAccounts([...accountsMapped]);
    };
    accountsEffect();
  }, [accounts]);

  useEffect(() => {
    const invitesEffect = () => {
      if (!invites) return;
      const invitesMapped = invites
        .filter(i => ['pending', 'invited'].includes(i.status))
        .map(invite => {
          const {
            token,
            first_name,
            last_name,
            email,
            job_type: accountType,
            department,
            status,
            key,
          } = invite;
          return {
            key,
            name: `${first_name || ''} ${last_name || ''}`,
            email,
            accountType,
            activeAccount: 'invited',
            status,
            department,
            inviteURL: `${process.env.REACT_APP_PUBLIC_URL}/invite/?token=${token}`,
          };
        });
      // console.log('invitesMapped', invitesMapped);
      setMembersFromInvites([...invitesMapped]);
    };
    invitesEffect();
  }, [invites]);

  useEffect(() => {
    const membersEffect = () => {
      console.log(model.name, 'membersFromInvites', membersFromInvites);
      console.log(model.name, 'membersFromAccounts', membersFromAccounts);
      setMembersTableItems(membersFromAccounts);
    };
    membersEffect();
  }, [membersFromAccounts, membersFromInvites]);

  const manageModelTeamContextOptions = useMemo(
    () => [
      {
        selectItem: 'Admin Access',
        onClick: () => {
          console.log('teamModel', model);
          triggerModelAccessModal(model, 'admin');
        },
      },
      {
        selectItem: 'Editor Access',
        onClick: () => {
          console.log('teamModel', model);
          triggerModelAccessModal(model, 'editor');
        },
      },
      {
        selectItem: 'Guest Access',
        onClick: () => {
          console.log('teamModel', model);
          triggerModelAccessModal(model, 'guest');
        },
      },
    ],
    [triggerModelAccessModal, model]
  );

  return (
    <div key={model.key}>
      {viewMode === 'MANAGE' && <ManageTeam members={membersTableItems} />}
      {viewMode === 'SUMMARIZE' && (
        <SummarizeTeam
          members={membersTableItems}
          popUpOptions={manageModelTeamContextOptions}
          team={{ ...model }}
          model={model}
        />
      )}
    </div>
  );
};
