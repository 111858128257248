import React, { useState, useContext, useMemo } from 'react';

import 'components/Dashboard/ProductUpload/ProductUpload.scss';
import './AssetLibraryFilesUpload.scss';
import Modal from 'components/Common/Modal/Modal';
import DragAndDrop from 'components/Upload/DragAndDrop';
import { UploadContext } from 'providers/contexts';
import { ASSETS_LIBRARY } from '../util';
// import CloseIcon from 'assets/icons/close.svg';

const STEP_UPLOAD = 'upload';
const STEP_PREVIEW = 'preview';

interface IAssetLibraryFilesUpload {
  path?: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onUploadHandler?: () => void;
  onClose?: () => void;
}

export const AssetLibraryFilesUpload = (props: IAssetLibraryFilesUpload) => {
  const {
    path = ASSETS_LIBRARY.assets_library.assets_storage_path,
    open,
    setOpen,
    onClose,
    onUploadHandler,
  } = props;
  const { handleFireBaseUploadFile, imageUploadProgress } = useContext(UploadContext);

  const [files, setFiles] = useState([]);
  const [step, setStep] = useState(STEP_UPLOAD);

  const addNewFiles = (newFiles: File[]) => {
    const newFilesArray = Array.from(newFiles);
    setFiles([
      ...newFilesArray.map((file: File) => {
        const url = `${path}/${file.name}`;
        const ext = file.name.split('.').pop();
        const metadata = {
          ext: ext,
        };
        handleFireBaseUploadFile(url, file, onUploadHandler, false, metadata);
        return {
          name: file.name,
          type: file.name.split('.').pop(),
          url,
        };
      }),
      ...files,
    ]);
    setStep(STEP_PREVIEW);
  };

  const onChange = event => {
    const localFiles: File[] = Array.from(event.currentTarget.files);
    addNewFiles(localFiles);
  };

  const onDropFile = fileList => {
    addNewFiles(fileList);
  };

  const btnActionHandler = () => {
    setOpen(!open);
    onClose?.();
  };

  const totalProgress = useMemo(() => {
    let progress = 0;
    files.map(file => {
      progress += imageUploadProgress[file.url] || 0;
    });
    return progress / Math.max(files.length, 1);
  }, [files, imageUploadProgress]);

  return (
    <Modal
      className='asset-library-file-upload'
      modalHeading={step === STEP_UPLOAD ? 'Upload Asset' : 'Upload Detail'}
      modalBtnActionLabel={step === STEP_PREVIEW && 'Done'}
      modalBtnActionHandler={btnActionHandler}
      edit={step === STEP_PREVIEW}
      secondaryBtnActionLabel={step === STEP_PREVIEW && 'Add more files'}
      secondaryBtnAction={() => setStep(STEP_UPLOAD)}
      backgroundColor='#fff'
      modalOpen={open}
      setModalOpen={setOpen}
      formHandlerId='assetLibraryFilesUpload'
      closeOnClickAway={false}
      footer={
        <>
          {files.length ? (
            <div className='asset-files__upload-progress'>
              {files.length} item loading : {+totalProgress.toFixed(2)}%
            </div>
          ) : (
            ''
          )}
        </>
      }
    >
      {step === STEP_UPLOAD ? (
        <DragAndDrop onDropHandler={onDropFile}>
          <div className='product-upload'>
            <div className='product-upload__drag-area'>
              <div className='product-upload__txt'>
                <p className='product-upload__instructions'>
                  Drag and drop or select files
                </p>
                <p className='txt-gray-light'>Files .excel .pdf .jpg .docx etc</p>
              </div>
              <div className='image-upload'>
                <label htmlFor='upload' className='button-small color-white-bd-bg mt-4'>
                  Select
                </label>
                <input
                  type='file'
                  id='upload'
                  name='upload'
                  multiple
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
        </DragAndDrop>
      ) : (
        <div className='asset-files'>
          {files.map(file => {
            return (
              <div key={file.url} className='asset-file'>
                <div className='asset-file__type'>{file.type}</div>
                <div className='asset-file__url'>
                  {file.url} : {imageUploadProgress[file.url] || 0}%
                </div>
                {/* <img className='asset-file__delete' src={CloseIcon} alt='Close' /> */}
              </div>
            );
          })}
        </div>
      )}
    </Modal>
  );
};

export default AssetLibraryFilesUpload;
