import React, { useState, useContext, useCallback, useEffect } from 'react';
import { Link } from '@reach/router';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import 'components/Dashboard/ProductUpload/ProductUpload.scss';
import Tooltip from 'components/Common/Tooltip/Tooltip';
import DashboardNav from 'components/Dashboard/DashboardNav/DashboardNav';
import { CreateModelModal } from 'components/Common/Modal';
import AtelierFileUploader from '../AtelierFileUploader';
import AtelierTraditionalPattern from './AtelierTraditionalPattern';
import './AtelierPattern.scss';
import PopUp from 'components/Common/PopUp/PopUp';
import { IPopUpOptions } from 'models/Dashboard/IPopUpOptions';
import Breadcrumbs, { IPages } from 'components/Common/Breadcrumbs/Breadcrumbs';
import { AssetProgress } from '../../StyleSummary/StyleSampleReview';
import useSWR from 'swr';
import {
  mmAPI,
  postModel,
  putModel,
  deleteModel,
  postActivityMethod,
  getNotificationsByScope,
} from 'services/Api';
import { UserContext } from 'providers/UserProvider';
import AtelierModelUploadsListView from '../AtelierModelUploads/AtelierModelUploadsListView';
import AtelierAdditionsPreview from '../AtelierModelUploads/AtelierModelUploadsPreview';
import { UploadContext } from 'providers/UploadProvider';
import { ISteps } from 'models/Dashboard/ISteps';
import { useForm } from 'react-hook-form';
import { ASSET_TYPES, getAssetTypeFromFileType } from 'constants/assets';
import { NotificationsContext } from 'providers/NotificationsProvider';
import { ModalsContext } from 'providers/ModalsProvider';
import { AssetMetadataProps } from 'components/Asset';
import Switch from 'components/Common/Switch/Switch';
import HistoryIcon from 'assets/icons/history.svg';
import ListIconBlack from 'assets/icons/Icon-list-black.svg';
import SlideIcon from 'assets/icons/Icon-slide.svg';
import PlusIcon from 'assets/icons/plus-btn.svg';
import LogRocket from 'logrocket';
import moment from 'moment';
import PrevIcon from 'assets/icons/arrow-prev.svg';
import CloseIcon from 'assets/icons/close.svg';
import { getImageDimensions } from 'utils/getImageDimensions';
import { HistoryModal } from 'components/Common/Modal/HistoryModal';

// const patternNavItems = ['3D CAD Pattern', 'Traditional Pattern']; // Uncomment to enable traditional pattern.
const patternNavItems = ['3D CAD Pattern'];

type AtelierUploadConfig = {
  endpoint: string;
  heading: string;
  model: 'Techpack' | 'Patterns' | 'Graphics' | 'References' | 'Final';
};

const STEP_UPLOAD = 'upload';
const STEP_PREVIEW = 'preview';

const VIEWMODE = {
  SLIDE: 'SLIDE',
  LIST: 'LIST',
};

export interface AtelierPattern {
  key: string;
  name: string;
  type: string;
  original: string;
  thumbnail?: string;
  localFile?: File;
  localUrl?: string;
  save?: boolean;
  // content?: string;
  progress?: number;
  inProgress?: boolean;
  asset?: [string, AssetMetadataProps];
  created?: number;
  // url?: string;
  imageAsFileSeed?: any;
  path?: number[];
  pathname?: string;
  ext?: string;
  deleteModelActionHandler?: (item: AtelierPattern) => void;
  renameModelActionHandler?: (item: AtelierPattern) => void;
  onArchiveHandler?: (item: AtelierPattern) => void;
  onUploadHandler?: () => void;
  onClickHandler?: (item: AtelierPattern) => void;
}

export interface IAtelierPatterns {
  brand_model_id: string;
  style_model_id: string;
  atelierUploadConfig: AtelierUploadConfig;
  setSteps?: (arg) => void;
  onSetStepComplete?: (arg) => void;
  onClickContinue?: () => void;
  currentStep: ISteps;
}

const AtelierPattern: React.FC<IAtelierPatterns> = props => {
  const {
    style_model_id,
    brand_model_id,
    atelierUploadConfig,
    onClickContinue,
    setSteps,
    currentStep,
    onSetStepComplete,
  } = props;
  const { user } = useContext(UserContext);
  const { idToken } = user;

  const { setDisplayToast } = useContext(NotificationsContext);

  const { endpoint, heading } = atelierUploadConfig;

  const [view, setView] = useState(STEP_UPLOAD);

  const [viewMode, setViewMode] = useState(VIEWMODE.LIST);

  const [notApplicableToogle, setNotApplicableToggle] = useState(false);

  const [lightboxOpen, setLightboxOpen] = useState(false);
  const lightboxRef = React.useRef<HTMLDivElement>();
  const [expandedImage, setExpandedImage] = useState();

  const [openHistory, setOpenHistory] = useState(false);
  const [history, setHistory] = useState([]);

  const setOriginal = item => {
    setExpandedImage(item);
    setSlideIndex(slides.findIndex(slide => slide.key === item.key));
    setLightboxOpen(!lightboxOpen);
  };

  const { data: stylePattern } = useSWR(
    [endpoint, idToken, style_model_id],
    (url, idToken, style) => {
      return mmAPI(url, idToken, { style });
    },
    {
      suspense: true,
    }
  );

  const [styleUploadModel, setStyleUploadModel] = useState(null);
  useEffect(() => {
    const styleModelEffect = async (stylePatternVal, brandModelId, styleModelId) => {
      if (stylePatternVal?.length >= 1) {
        setStyleUploadModel(stylePatternVal[0]);
      } else {
        const name = `Pattern - ${user?.style?.name} - ${user?.brand?.name}`;
        const response = await postModel(idToken, 'pattern', {
          brand: brandModelId,
          style: styleModelId,
          name,
          notes: name,
          asset_type: 'asset-uploaded',
          location: window.location.href,
        });
        const { data: patternModel } = response.data;
        setStyleUploadModel(patternModel);
      }
    };
    styleModelEffect(stylePattern, brand_model_id, style_model_id);
  }, [stylePattern, user?.brand, user?.style, brand_model_id, style_model_id, idToken]);

  const { data: uploadModelReferences, mutate: mutateUploadModelReferences } = useSWR(
    styleUploadModel
      ? [`/api/reference/query/model`, idToken, 'Pattern', styleUploadModel.key]
      : null,
    (url, idToken, model, model_id) => {
      return mmAPI(url, idToken, { model, model_id });
    },
    {
      suspense: true,
    }
  );

  const [sectionLevelHistory, setSectionLevelHistory] = useState([]);
  const [assetLevelHistory, setAssetLevelHistory] = useState([]);

  const getSectionScopeHistory = async () => {
    const { data: sectionLevelHistory } = await getNotificationsByScope(
      idToken,
      styleUploadModel?.key
    );
    setHistory(sectionLevelHistory.data);
  };

  useEffect(() => {
    if (styleUploadModel) getSectionScopeHistory();
  }, [sectionLevelHistory, styleUploadModel]);

  const getSectionLevelHistory = () => {
    getSectionScopeHistory();
    setOpenHistory(true);
  };

  const getAssetScopeHistory = async item => {
    const { data: assetLevelHistory } = await getNotificationsByScope(idToken, item?.key);
    setHistory(assetLevelHistory.data);
  };

  const getAssetLevelHistory = item => {
    getAssetScopeHistory(item);
    setOpenHistory(true);
  };

  const [onSave, setOnSave] = useState(null);
  useEffect(() => {
    const styleUploadModelEffect = styleUploadModelVal => {
      if (!styleUploadModelVal) return;
      setOnSave(() => async values => {
        console.log('values', values, styleUploadModelVal);
        const digitization = values.traditionalPattern === 'in-house-digitization';
        const tags = values.traditionalPattern;
        const { data: patternUpdate } = await putModel(
          idToken,
          'pattern',
          styleUploadModel?.key,
          {
            tags,
            digitization,
            location: window.location.href,
          }
        );
        console.log(patternUpdate);
      });
    };
    styleUploadModelEffect(styleUploadModel);
  }, [styleUploadModel]);

  useEffect(() => {
    const styleUploadModelEffect = () => {
      setSteps(prevSteps => {
        prevSteps.forEach(
          s =>
            s.url.includes('pattern') &&
            (s.buttonPrimaryHandler = event => {
              console.log('sidenav button handler', event);
              onSave && handleSubmit(onSave)();
            })
        );
        return [...prevSteps];
      });
    };
    styleUploadModelEffect();
  }, [styleUploadModel]);

  const onClickActivityHandler = async props => {
    try {
      await postActivityMethod(idToken, 'reference', {
        reference: props.key,
        activity: 'reference-pattern-downloaded',
        asset_type: atelierUploadConfig?.model,
        style_id: style_model_id,
        brand_id: brand_model_id,
        location: window.location.href,
      });
      LogRocket.track('reference-pattern-downloaded', {
        reference: props.key,
        user: JSON.stringify(user),
      });
    } catch (error) {
      LogRocket.captureException(error, {
        tags: {
          // additional data to be grouped as "tags"
          label: 'AtelierPattern: error on activity POST',
          journey: 'reference-pattern-downloaded',
          step: 'onClickActivityHandler',
        },
        extra: {
          reference: props.key,
          user: JSON.stringify(user),
        },
      });
    }
  };

  const { firebaseStorageUrl } = useContext(UploadContext);
  const getFirebaseStorageUrl = async asset => {
    return asset && asset.path ? await firebaseStorageUrl(asset.path) : null;
  };

  const [referenceModels, setReferenceModels] = useState<AtelierPattern[]>([]);
  const [patternsFromFiles, setPatternsFromFiles] = useState<AtelierPattern[]>([]);
  const patterns = React.useMemo(() => [...patternsFromFiles, ...referenceModels], [
    patternsFromFiles,
    referenceModels,
  ]);

  const [imagesFromFilesUploading, setImagesFromFilesUploading] = useState<
    AtelierPattern[]
  >([]);
  const [openModal, setOpenModal] = useState(false);
  const [refId, setRefId] = useState('');
  const [currentPath, setCurrentPath] = useState([]);
  const [open, setOpen] = useState(false);
  const [, setModalOpen] = useState(false);
  const [, setActiveModalComponent] = useState(null);

  useEffect(() => {
    const uploadModelReferencesEffect = async () => {
      if (uploadModelReferences) {
        const imagesFromReferences = (
          await Promise.all(
            uploadModelReferences.map(t => referenceModelGalleryTransform(t))
          )
        ).filter(r => !!r) as AtelierPattern[];
        imagesFromReferences.sort((a, b) => b.created - a.created);
        setReferenceModels([
          ...imagesFromReferences,
          ...uploadModelReferences.filter(p => p.type === 'folder'),
        ]);
        setPatternsFromFiles(prev =>
          prev.filter(c => !imagesFromReferences.some(r => r.key === c.key))
        );
        setImagesFromFilesUploading([]);
        setView(STEP_PREVIEW);
      }
    };
    uploadModelReferencesEffect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadModelReferences]);

  const referenceModelGalleryTransform = useCallback(
    async referenceModelVal => {
      try {
        const imgSrc = await getFirebaseStorageUrl(
          referenceModelVal.assets[referenceModelVal.type]
        );
        return Promise.resolve({
          original: imgSrc,
          thumbnail: imgSrc,
          key: referenceModelVal.key,
          name: referenceModelVal.name,
          type: referenceModelVal.type,
          progress: referenceModelVal.assets?.[referenceModelVal.type]?.progress,
          created: referenceModelVal.created,
          path: referenceModelVal.path,
          deleteModelActionHandler,
          renameModelActionHandler,
          onArchiveHandler,
          onClickHandler: onClickActivityHandler,
          ext: referenceModelVal.assets?.[referenceModelVal.type]?.ext,
        } as AtelierPattern);
      } catch (e) {
        return null;
      }
    },
    [currentPath]
  );

  const fileGalleryTransform = useCallback(
    async file => {
      const { type } = getAssetTypeFromFileType(file) || {};
      if (!type) {
        setDisplayToast({
          type: 'error',
          persist: false,
          message: `${file.name} is not a supported file type.`,
        });
        return null;
      }

      const response = await postModel(idToken, 'reference', {
        type,
        brand: brand_model_id,
        model: 'Pattern',
        model_id: styleUploadModel.key,
        name: file.name,
        notes: file.type,
        asset_type: 'pattern',
        style: style_model_id,
        tags: atelierUploadConfig.model === 'Final' ? 'final' : null,
        path: currentPath.length > 0 ? currentPath.join(',') : null,
        location: window.location.href,
      });
      const patternReferenceData = response?.data?.data;
      console.log('patternReferenceData', patternReferenceData);
      const imgSrc = URL.createObjectURL(file);
      return Promise.resolve({
        key: patternReferenceData.key,
        created: moment().unix(),
        name: file.name,
        original: imgSrc,
        thumbnail: imgSrc,
        localFile: file,
        localUrl: imgSrc,
        type,
        save: true,
        asset: [type, patternReferenceData?.assets[type]],
        imageAsFileSeed: file,
        path: currentPath,
        deleteModelActionHandler,
        renameModelActionHandler,
        onArchiveHandler,
        onUploadHandler: () => {
          console.log('onUploadHandler', file.name);
          onSetStepComplete({
            [`status_patterns`]: true,
          });
          mutateUploadModelReferences();
        },
        onClickHandler: onClickActivityHandler,
      } as AtelierPattern);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      idToken,
      brand_model_id,
      atelierUploadConfig.model,
      styleUploadModel?.key,
      currentPath,
    ]
  );

  const onAddNewFiles = async newFiles => {
    const filesArray = Array.from(newFiles);
    const patternsFromFilesResolved = (
      await Promise.all(filesArray.map((file: File) => fileGalleryTransform(file)))
    ).filter(f => !!f);
    if (patternsFromFilesResolved.length > 0) {
      setPatternsFromFiles(prev => [...patternsFromFilesResolved, ...prev]);
      setImagesFromFilesUploading(patternsFromFilesResolved);
      setView(STEP_PREVIEW);
    }
  };

  const [tab, setTab] = useState(patternNavItems[0]);

  const uploadPanelConfig = {
    fileType: Object.values(ASSET_TYPES)
      .reduce((extensions, types) => {
        return extensions.concat(types.extensions);
      }, [])
      .join(', '),
    multiple: true,
    emptyStateFileTypes: 'File types:  DXF AAMA/ASTM and RUL .jpg .pdf .png etc',
    disabled: notApplicableToogle,
    onChangeHandler: onAddNewFiles,
  };

  const onSelectPattern = p => {
    currentStep?.buttonPrimaryHandler(() => {
      console.log('selected pattern', p);
    });
  };

  const deleteModelActionHandler = data => {
    onDeleteHandler(data);
  };

  const renameModelActionHandler = data => {
    setOpenModal(true);
    setRefId(data.key);
  };

  const createModelActionHandler = async values => {
    const { name } = values;
    const { data: referenceUpdate } = await putModel(idToken, 'reference', refId, {
      name,
      brand: brand_model_id,
      style: style_model_id,
      rename: true,
      location: window.location.href,
    });
    if (referenceUpdate.data?.error) {
      setDisplayToast({ type: 'error', message: referenceUpdate.data?.error?.message });
    } else {
      setDisplayToast({ type: 'success', message: `Reference name updated` });
      setReferenceModels(prev => [...prev.filter(r => r.key !== refId)]);
      mutateUploadModelReferences();
    }
  };

  const { setOpenDialog, setDialogProps } = useContext(ModalsContext);
  const onDeleteHandler = useCallback(
    item => {
      setDialogProps({
        dialogBody: `Deleting pattern uploads can’t be undone. Are you sure you want to delete the pattern upload?`,
        btnActionHandler: async () => {
          const { data: referenceDeleted } = await deleteModel(
            idToken,
            'reference',
            item.key,
            {
              style: style_model_id,
              brand: brand_model_id,
              location: window.location.href,
            }
          );
          if (referenceDeleted.error) {
            setDisplayToast({ type: 'error', message: referenceDeleted.error.message });
          } else {
            setReferenceModels(prev => prev.filter(c => c.key !== item.key));
            setPatternsFromFiles(prev => prev.filter(c => c.key !== item.key));
            setDisplayToast({
              type: 'success',
              persist: false,
              message: `Pattern reference has been deleted successfully.`,
            });
          }
        },
      });
      setOpenDialog(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [idToken]
  );

  const onArchiveHandler = useCallback(
    item => {
      setDialogProps({
        dialogBody: `Are you sure you want to continue to archive ${item.name}?`,
        btnActionHandler: async () => {
          try {
            const response = await deleteModel(idToken, 'reference', item.key, {
              archive: true,
              style: style_model_id,
              brand: brand_model_id,
              location: window.location.href,
            });
            setReferenceModels(prev => prev.filter(c => c.key !== item.key));
            setPatternsFromFiles(prev => prev.filter(c => c.key !== item.key));
            setDisplayToast({
              type: 'success',
              persist: false,
              message: `Asset has been archived successfully.`,
            });
            mutateUploadModelReferences();
          } catch (error) {
            setDisplayToast({
              type: 'success',
              persist: false,
              message: `Error occured while archiving asset.${error}`,
            });
          }
        },
      });
      setOpenDialog(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { handleSubmit, register } = useForm();

  const tooltipText = `Please upload pattern file DXF AAMA/ASTM and RUL file. 
    Important: when uploading .jpg .pdf .png 
    include SCALE of the image to its original patterns. 
    The grading information needs to be included on the images. Can upload several 
    images of difference sizes, or put all sizes in one image per product. 
    Supported file types: ${uploadPanelConfig.fileType}`;

  const editTileOptions = [
    {
      selectItem: 'Download',
      download: true,
      onClick: onClickActivityHandler,
    },
    {
      selectItem: 'Delete',
      onClick: deleteModelActionHandler,
    },
    {
      selectItem: 'Rename',
      onClick: renameModelActionHandler,
    },
    {
      selectItem: 'Archive',
      onClick: onArchiveHandler,
    },
    {
      selectItem: 'History',
      onClick: getAssetLevelHistory,
    },
    {
      selectItem: 'Copy Link',
      disabled: false,
      onClick: () => {
        navigator.clipboard.writeText(process.env.REACT_APP_PUBLIC_URL + location.pathname);
        setDisplayToast({
          persist: false,
          type: 'success',
          message: `Copied to clipboard.`,
        });
      },
    },
  ];

  const onCreateFolder = async () => {
    const response = await postModel(idToken, 'reference', {
      type: 'folder',
      brand: brand_model_id,
      model: 'Pattern',
      model_id: styleUploadModel.key,
      style: style_model_id,
      name: 'untitled folder',
      tags: atelierUploadConfig.model === 'Final' ? 'final' : null,
      path: currentPath.length > 0 ? currentPath.join(',') : null,
      pathname: '/' + currentPathname.join('/'),
      location: window.location.href,
    });
    mutateUploadModelReferences();
  };

  const onClickFolder = reference_key => {
    setCurrentPath(prev => [...prev, reference_key]);
  };

  const pages = React.useMemo(() => {
    const pagesArray = [];
    let link = '/';
    for (let i = 0; i < currentPath.length; i += 1) {
      const key = currentPath[i];
      const reference = patterns.find(r => +r.key === +key);
      link += `/${reference.name}`;
      const page: IPages = {
        title: reference.name,
      };
      if (i < currentPath.length - 1) {
        page.link = link;
        page.onClick = () => setCurrentPath(currentPath.slice(0, i + 1));
      }
      pagesArray.push(page);
    }
    if (currentPath.length > 0) {
      pagesArray.push({
        title: '<',
        link: '/',
        onClick: () => setCurrentPath(currentPath.slice(0, currentPath.length - 1)),
      });
    }
    return pagesArray;
  }, [currentPath, patterns]);

  const currentPathname = currentPath.map(
    key => patterns.find(r => +r.key === +key).name
  );

  const filteredImages = patterns.filter(r => {
    const rPath = r.path ?? [];
    const len = Math.max(rPath.length, currentPath.length);
    for (let i = 0; i < len; i++) {
      if (rPath[i] != currentPath[i]) {
        return false;
      }
    }
    return true;
  });

  const popUpOptions = [
    {
      selectItem: 'Add new',
      onClick: () => {
        setView(STEP_UPLOAD);
      },
    },
    {
      selectItem: 'Create folder',
      onClick: () => {
        onCreateFolder();
      },
    },
  ] as IPopUpOptions[];

  const handlePopUpModal = ModalComponent => {
    setActiveModalComponent(ModalComponent);
    setModalOpen(true);
  };

  const [slideIndex, setSlideIndex] = useState<any | null>(0);

  const [slides, setSlides] = useState([]);

  useEffect(() => {
    if (filteredImages.length === 0) return;
    setSlides(filteredImages.filter(s => s.type !== 'folder'));
    setSlides(filteredImages.filter(s => s.type !== 'folder'));
  }, [patterns]);

  const [imageUrlZoomed, setImageUrlZoomed] = useState(null);

  const srcToFileObjectUrl = (name, src) => {
    if (!src) return null;
    return new Promise(function (resolve, reject) {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = event => {
        const blob = xhr.response;
        const file = blobToFile(blob, name);
        const fileObjectUrl = URL.createObjectURL(file);
        resolve(fileObjectUrl);
      };
      xhr.open('GET', src);
      xhr.send();
    });
  };

  const blobToFile = (theBlob: Blob, fileName: string): File => {
    const b: any = theBlob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    //Cast to a File() type
    return theBlob as File;
  };

  useEffect(() => {
    const imageZoomedUrlEffect = async slide => {
      if (slide) {
        const imageUrlFromAsset = slides.find(
          item => parseInt(item.key) === parseInt(slide.key)
        )?.original;

        const { height, width, aspectRatio } = await getImageDimensions(
          imageUrlFromAsset
        );

        const fileObjectUrlZoomed = await srcToFileObjectUrl(
          slide.name,
          imageUrlFromAsset
        );

        setImageUrlZoomed({
          slideKey: slide.key,
          src: fileObjectUrlZoomed,
          width,
          height,
        });
        setExpandedImage(slides[slideIndex]);
      }
    };
    imageZoomedUrlEffect(slides[slideIndex]);
  }, [slideIndex, slides]);

  return (
    <div className='pattern'>
      <div className='pattern__heading'>
        <div className='pattern__heading-wrapper'>
          {heading}
          <div className='pattern__heading-icons'>
            <img
              className='pattern__history'
              src={HistoryIcon}
              alt='history'
              onClick={() => getSectionLevelHistory()}
            />
            <Tooltip tooltipText={tooltipText} />
            {viewMode === VIEWMODE.SLIDE ? (
              <img
                className='pattern__viewmode'
                style={{ cursor: view === STEP_PREVIEW ? 'pointer' : 'not-allowed' }}
                src={SlideIcon}
                alt='Rail'
                onClick={() => setViewMode(VIEWMODE.LIST)}
              />
            ) : (
              <img
                className='pattern__viewmode'
                style={{ cursor: view === STEP_PREVIEW ? 'pointer' : 'not-allowed' }}
                src={ListIconBlack}
                alt='List'
                onClick={() => setViewMode(VIEWMODE.SLIDE)}
              />
            )}
            <img
              src={PlusIcon}
              alt='Plus'
              className='pattern__add'
              style={{ cursor: view === STEP_PREVIEW ? 'pointer' : 'not-allowed' }}
              onClick={() => setOpen(!open)}
            />
            <PopUp open={open} setOpen={setOpen}>
              {popUpOptions &&
                popUpOptions.map(({ selectItem, to, onClick, ModalComponent }) => (
                  <li
                    className='pop-up__li'
                    onClick={() => {
                      setOpen(false);
                      if (ModalComponent) {
                        handlePopUpModal(ModalComponent);
                      } else if (onClick) {
                        onClick();
                      }
                    }}
                    key={selectItem}
                  >
                    {to ? <Link to={to}>{selectItem}</Link> : selectItem}
                  </li>
                ))}
            </PopUp>
          </div>
        </div>
      </div>
      <DashboardNav
        dashboardNavItems={patternNavItems.map(item => ({
          title: item,
          activeBtn: item === tab,
        }))}
        darkMode={false}
        changeTab={setTab}
      />
      <div className='additional-reference__folder-path'>
        <Breadcrumbs pages={pages} />
      </div>
      {view === STEP_UPLOAD ? (
        <AtelierFileUploader
          brand_model_id={brand_model_id}
          images={filteredImages}
          {...uploadPanelConfig}
        />
      ) : (
        <div className='pattern__body'>
          {viewMode === VIEWMODE.LIST ? (
            <>
              {imagesFromFilesUploading?.map(upload => (
                <AssetProgress key={upload.key} {...upload} />
              ))}
              <AtelierModelUploadsListView
                media={filteredImages}
                editTileOptions={editTileOptions}
                onClickRow={onClickFolder}
              />
            </>
          ) : (
            <AtelierAdditionsPreview
              images={filteredImages.filter(s => s.type !== 'folder')}
              setOriginal={setOriginal}
              getAssetLevelHistory={getAssetLevelHistory}
            />
          )}
          <CreateModelModal
            formId='Rename'
            modalHeading='Rename'
            modelName=''
            btnCloseLabel='CANCEL'
            btnActionLabel='RENAME'
            open={openModal}
            setOpen={setOpenModal}
            modelInputLabel='NEW NAME'
            btnActionHandler={createModelActionHandler}
            closeOnSubmit={true}
          />
        </div>
      )}

      {/* uncomment below to enable traditional pattern */}

      {/* {tab === patternNavItems[0] ? (
        patterns.length === 0 || view === STEP_UPLOAD ? (
          <AtelierFileUploader {...uploadPanelConfig} />
        ) : (
          <div className='pattern__body'>
            {viewMode === VIEWMODE.LIST ? (
              <AtelierModelUploadsListView
                media={patterns}
                editTileOptions={editTileOptions}
              />
            ) : (
              <AtelierAdditionsPreview images={patterns} setOriginal={setOriginal} />
            )}
          </div>
        )
      ) : (
        <div className='pattern__body'>
          <form id={`patternTraditional`} onSubmit={handleSubmit(onSave)}>
            <AtelierTraditionalPattern
              onSelectPattern={onSelectPattern}
              register={register}
              defaultValue={styleUploadModel?.tags?.[0]}
            />
          </form>
        </div>
      )} */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '32px 0 24px 40px',
        }}
      >
        <Switch label={'not applicable'} onSwitchChange={setNotApplicableToggle} />
        {view === STEP_UPLOAD && (
          <div className='pattern__actions'>
            {patterns.length > 0 && (
              <button
                className='button-transparent-black mr-2'
                onClick={() => setView(STEP_PREVIEW)}
              >
                View Uploads
              </button>
            )}
          </div>
        )}
      </div>
      <HistoryModal
        history={history}
        openHistory={openHistory}
        setOpenHistory={setOpenHistory}
      />
      <CSSTransition classNames='lightbox' timeout={200} in={lightboxOpen} unmountOnExit>
        <div className='product-listing-dashboard__light-box'>
          <div className='product-listing-dashboard__lightbox-header'>
            {`${expandedImage && expandedImage?.name}`}
            <div className='product-listing-dashboard__lightbox-buttons-wrap'>
              <div className='product-listing-dashboard__lightbox-arrows'>
                <img
                  className='product-listing-dashboard__main-prev'
                  src={PrevIcon}
                  alt='Previous'
                  onClick={() =>
                    setSlideIndex(slideIndex === 0 ? slides.length - 1 : slideIndex - 1)
                  }
                />
                <img
                  className='product-listing-dashboard__main-next'
                  src={PrevIcon}
                  alt='Next'
                  onClick={() =>
                    setSlideIndex(slideIndex === slides.length - 1 ? 0 : slideIndex + 1)
                  }
                />
              </div>
              <img
                className='cursor-pointer'
                src={CloseIcon}
                alt='Close'
                onClick={() => {
                  setLightboxOpen(!lightboxOpen);
                }}
              />
            </div>
          </div>
          <div className='product-listing-dashboard__lightbox-inner' ref={lightboxRef}>
            <TransitionGroup component={null}>
              <CSSTransition
                classNames='fade-in'
                timeout={200}
                key={slides?.[slideIndex]?.name}
              >
                <TransformWrapper
                  initialScale={0.9}
                  centerOnInit={true}
                  minScale={0.2}
                  limitToBounds={false}
                  wheel={{
                    step: 0.1,
                  }}
                  doubleClick={{
                    mode: 'reset',
                  }}
                  initialPositionX={
                    lightboxRef?.current?.offsetWidth / 2 - imageUrlZoomed?.width / 2
                  }
                  initialPositionY={0}
                >
                  <TransformComponent>
                    <img
                      src={slides?.[slideIndex]?.original || imageUrlZoomed?.src}
                      alt={slides?.[slideIndex]?.name}
                    />
                  </TransformComponent>
                </TransformWrapper>
              </CSSTransition>
            </TransitionGroup>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default AtelierPattern;
